import axios from "axios";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Global from "../global";
import { useLocation, useHistory } from "react-router";
import "../assets/css/userview.css";
import { FiArrowLeft } from "react-icons/fi";
import IconButton from "@material-ui/core/IconButton";
import { IconContext } from "react-icons";
import { Button } from "@material-ui/core";
import empty_member from "../assets/images/empty_member.png";
import { IoMdAddCircle } from "react-icons/io";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import Loader from "react-loader-spinner";
import { HiOutlineDocumentText } from "react-icons/hi";
import emptyReport from "../assets/images/empty_report.png";

const UserView = () => {
  const history = useHistory();
  const location = useLocation();
  const [allmembers, setallMembers] = useState([]);
  const [members, SetMembers] = useState([]);
  const [allreports, setallReports] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [memberpage, setmemberPage] = useState(0);

  const [memberrowsPerPage, setmemberRowsPerPage] = useState(5);
  const [reportpage, setreportPage] = useState(0);
  const [reportrowsPerPage, setreportRowsPerPage] = useState(5);

  const [selectedTab, setSelectedTab] = useState(0);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const rowdataAlign = { verticalAlign: "middle" };

  const memberhandleChangePage = (event, newPage) => {
    setmemberPage(newPage);

    const total = allmembers.length;
    let startIndex = (newPage + 1) * memberrowsPerPage - memberrowsPerPage;

    const endIndex = startIndex + memberrowsPerPage;
    const data = allmembers.slice(startIndex, endIndex);

    SetMembers([...data]);
  };

  const memberhandleChangeRowsPerPage = async (event) => {
    setmemberRowsPerPage(parseInt(event.target.value, 10));
    setmemberPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = allmembers.slice(startIndex, endIndex);

    SetMembers([...data]);
  };

  const reporthandleChangePage = (event, newPage) => {
    setreportPage(newPage);

    const total = allreports.length;
    let startIndex = (newPage + 1) * reportrowsPerPage - reportrowsPerPage;

    const endIndex = startIndex + reportrowsPerPage;
    const data = allreports.slice(startIndex, endIndex);

    setReports([...data]);
  };

  const reporthandleChangeRowsPerPage = async (event) => {
    setreportRowsPerPage(parseInt(event.target.value, 10));
    setreportPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = allreports.slice(startIndex, endIndex);

    setReports([...data]);
  };

  useEffect(() => {
    const getMembersofUser = async () => {
      try {
        const members = await axios.post(
          Global.base_url + "/users/members",
          { user_id: location.state.userData.user_id },
          config
        );

        // console.log(response);

        if (members.status === 200) {
          let datatest = members.data.data;
          const total = datatest.length;
          let startIndex =
            (memberpage + 1) * memberrowsPerPage - memberrowsPerPage;

          const endIndex = startIndex + memberrowsPerPage;
          const data = datatest.slice(startIndex, endIndex);
          console.log("products members: ", members);
          setallMembers(members.data.data);
          SetMembers(data);
          // setLoading(false);

          console.log("user members", members);
        }
      } catch (error) {
        if (error.members) {
          setLoading(false);
          if (error.members.status === 401) {
            return toast.error("unable to get user members");
          }
          if (error.members.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      try {
        const reports = await axios.post(
          Global.base_url + "/users/reports",
          { user_id: location.state.userData.user_id },
          config
        );

        // console.log(response);

        if (reports.status === 200) {
          // setLoading(false);
          let reportdatatest = reports.data.data;

          let startIndex =
            (reportpage + 1) * reportrowsPerPage - reportrowsPerPage;

          const endIndex = startIndex + reportrowsPerPage;
          const data = reportdatatest.slice(startIndex, endIndex);
          // console.log("products reports: ", response);
          setallReports(reports.data.data);
          setReports(data);

          console.log("user reports", reports);
        }
      } catch (error) {
        if (error.reports) {
          setLoading(false);
          if (error.reports.status === 401) {
            return toast.error("unable to get user reports");
          }
          if (error.reports.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      return setLoading(false);
    };

    getMembersofUser();
  }, []);

  const UserMembers = () => {
    return (
      <div className="report__container">
        <section
          className="admin-product-filter-section table-responsive fixHeader
"
        >
          <table
            className="table  text-center  admin-product_usertable m-0"
            style={{
              backgroundColor: "white",
            }}
            id="bill-table"
          >
            <thead>
              <tr
                id="admin-product-row"
                style={{ border: "none", backgroundColor: "#E0E0E0" }}
              >
                <th scope="col" className="admin-product-row_table-header">
                  Member Id
                </th>
                <th scope="col" className="admin-product-row-table-header">
                  First Name
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header admin-product-row-price-col"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header  admin-product-row-price-col"
                >
                  DOB
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header  admin-product-row-status-col"
                >
                  Relationship
                </th>
              </tr>
            </thead>
            <tbody>
              {members.length > 0 ? (
                members.map((data, i) => {
                  if (i > memberrowsPerPage - 1) {
                    return;
                  }
                  const color = data.status === 1 ? "#03A33B" : "red";

                  //   const borderLeft = `5px solid ${color}`;
                  const borderBottom = `1px solid #B3CDFF`;
                  const font = "mediumFont";

                  return (
                    <>
                      <tr
                        key={data.member_id + Math.random()}
                        style={{
                          //   borderLeft,
                          borderBottom,
                          backgroundColor: "white",
                        }}
                        className="admin-product-row-table-row-hover "
                      >
                        <td
                          scope="row"
                          id="admin-product-rd"
                          className="admin-product-row-table-row-data mediumFont"
                          style={rowdataAlign}
                        >
                          <p
                            data-toggle="collapse"
                            data-target={`#accordion` + i}
                            className="clickable"
                            id="button-open"
                            // style={{ display: "none" }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#4059E2",
                                size: "25px",
                                style: { marginBottom: "3px" },
                              }}
                            >
                              <IoMdAddCircle className="toggle-row-icon" />
                            </IconContext.Provider>
                          </p>{" "}
                          MID{data.member_id}
                        </td>
                        <td
                          className="admin-product-row-table-row-data mediumFont"
                          style={rowdataAlign}
                        >
                          {" "}
                          {data.firstname}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                          style={rowdataAlign}
                        >
                          {data.lastname}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                          style={rowdataAlign}
                        >
                          {moment(data.dob).format("LL")}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                          style={{ ...rowdataAlign }}
                        >
                          {data.relationship}
                        </td>
                      </tr>
                      <tr
                        id={"accordion" + i}
                        className="collapse  admin-product-hided-row"
                        key={data.member_id + Math.random()}
                      >
                        <td colSpan="6" style={rowdataAlign}>
                          <ul className="admin-product-row-table-hide-list text-left ">
                            <li
                              key={data.member_id + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                              className="admin-product-row-hided-col-price"
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">
                                  Last Name :
                                </div>
                                <div className="col-6  text-left">
                                  {data.lastname}
                                </div>
                              </div>
                            </li>
                            <li
                              key={i + data.member_id + i + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                              className="admin-product-row-hided-col-price"
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">DOB :</div>
                                <div className="col-6  text-left">
                                  {moment(data.dob).format("YYYY MM DD")}
                                </div>
                              </div>
                            </li>
                            <li
                              key={data.member_id + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">
                                  Relationship :
                                </div>
                                <div
                                  className="col-6  text-left"
                                  style={{ color }}
                                >
                                  {data.relationship}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <div
                  className="center__items p-5"
                  style={{ flexDirection: "column" }}
                >
                  <img src={empty_member} alt="Empty Member" />
                  <p
                    style={{
                      color: "#D2D2D2",
                      padding: "20px",
                      fontSize: "20px",
                      fontFamily: "mediumFont",
                    }}
                  >
                    Members not found
                  </p>
                </div>
              )}
            </tbody>
          </table>
        </section>
        {allmembers.length > 5 || memberpage > 0 ? (
          <div className="d-flex flex-row align-items-center justify-content-end mb-4">
            <TablePagination
              component="div"
              count={allmembers.length}
              page={memberpage}
              onChangePage={memberhandleChangePage}
              rowsPerPage={memberrowsPerPage}
              onChangeRowsPerPage={memberhandleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 50]}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const UserReports = () => {
    return (
      <div className="report__container">
        <section
          className="admin-product-filter-section table-responsive fixHeader
"
        >
          <table
            className="table  text-center  admin-product_usertable m-0"
            style={{
              backgroundColor: "white",
            }}
            id="bill-table"
          >
            <thead>
              <tr
                id="admin-product-row"
                style={{ border: "none", backgroundColor: "#E0E0E0" }}
              >
                <th scope="col" className="admin-product-row_table-header">
                  Report ID
                </th>
                <th scope="col" className="admin-product-row-table-header">
                  Name
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header admin-product-row-price-col"
                >
                  Package / Test
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header  admin-product-row-price-col"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header  admin-product-row-status-col"
                >
                  Lab
                </th>
                <th
                  scope="col"
                  className="admin-product-row-table-header  admin-product-row-status-col"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="admin-product-row-action-col admin-product-row-table-header"
                ></th>
              </tr>
            </thead>
            <tbody>
              {reports.length > 0 ? (
                reports.map((data, i) => {
                  if (i > reportrowsPerPage - 1) {
                    return;
                  }
                  const color =
                    data.status === "Completed"
                      ? "#03A33B"
                      : data.status === "Awaiting Confirmation"
                      ? "red"
                      : data.status === "Scheduled" ||
                        data.status === "Rescheduled"
                      ? "#4059E2"
                      : data.status === "Cancelled"
                      ? "#818181"
                      : "#EFA500";

                  //   const borderLeft = `5px solid ${color}`;
                  const borderBottom = `1px solid #B3CDFF`;
                  // const font = "mediumFont";

                  return (
                    <>
                      <tr
                        key={data.report_id + Math.random()}
                        style={{
                          //   borderLeft,
                          borderBottom,
                          backgroundColor: "white",
                        }}
                        className="admin-product-row-table-row-hover "
                      >
                        <td
                          scope="row"
                          id="admin-product-rd"
                          className="admin-product-row-table-row-data mediumFont"
                          style={rowdataAlign}
                        >
                          <p
                            data-toggle="collapse"
                            data-target={`#accordion` + i}
                            className="clickable"
                            id="button-open"
                            // style={{ display: "none" }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#4059E2",
                                size: "25px",
                                style: { marginBottom: "3px" },
                              }}
                            >
                              <IoMdAddCircle className="toggle-row-icon" />
                            </IconContext.Provider>
                          </p>{" "}
                          RID{data.report_id}
                        </td>
                        <td
                          className="admin-product-row-table-row-data mediumFont"
                          style={rowdataAlign}
                        >
                          {" "}
                          {data.member_name}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                          style={rowdataAlign}
                        >
                          {data.package_name
                            ? data.package_name
                            : data.test_name}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                          style={rowdataAlign}
                        >
                          {data.type}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                          style={{ ...rowdataAlign }}
                        >
                          {data.lab_name}
                        </td>
                        <td
                          className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                          style={{ ...rowdataAlign, color: color }}
                        >
                          {data.status}
                        </td>
                        <td
                          className="admin-product-row-action-col admin-product-row-table-row-data "
                          // colSpan={1}
                          style={rowdataAlign}
                        >
                          <Button
                            key={"view" + i}
                            variant="contained"
                            color="default"
                            className={"admin-product-Filter__button mr-2"}
                            // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                            style={{ padding: "10px 20px" }}
                            onClick={() => {
                              // toast.success("Hello");

                              history.push({
                                pathname: "/admin/user-reportView",
                                state: {
                                  reportData: data,
                                  userData: location.state.userData,
                                  // location: location.pathname,
                                },
                              });
                            }}
                            startIcon={<HiOutlineDocumentText />}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                      <tr
                        id={"accordion" + i}
                        className="collapse  admin-product-hided-row"
                        key={data.report_id + Math.random()}
                      >
                        <td colSpan="6" style={rowdataAlign}>
                          <ul className="admin-product-row-table-hide-list text-left ">
                            <li
                              key={data.report_id + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                              className="admin-product-row-hided-col-price"
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">
                                  Package / Test :
                                </div>
                                <div className="col-6  text-left">
                                  {data.package_name || data.test_name}
                                </div>
                              </div>
                            </li>
                            <li
                              key={i + data.report_id + i + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                              className="admin-product-row-hided-col-price"
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">Type :</div>
                                <div className="col-6  text-left">
                                  {data.type}
                                </div>
                              </div>
                            </li>
                            <li
                              key={data.report_id + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">Lab :</div>
                                <div
                                  className="col-6  text-left"
                                  // style={{ color }}
                                >
                                  {data.lab_name}
                                </div>
                              </div>
                            </li>
                            <li
                              key={data.report_id + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                            >
                              <div className="row justify-content-start ml-3">
                                <div className="col-6  text-center">
                                  Status :
                                </div>
                                <div
                                  className="col-6  text-left"
                                  style={{ color }}
                                >
                                  {data.status}
                                </div>
                              </div>
                            </li>

                            <li
                              key={data.report_id + Math.random()}
                              style={{
                                // borderBottom: "1px solid #B3CDFF",
                                padding: "10px",
                              }}
                            >
                              <div className="row justify-content-center mt-2 ml-3">
                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                  <Button
                                    key={i}
                                    variant="contained"
                                    color="default"
                                    className={
                                      "admin-product-Filter__button mr-2"
                                    }
                                    // startIcon={
                                    //   <AiOutlineCloudDownload color="#2C3E50" />
                                    // }
                                    style={{ padding: "10px 33px" }}
                                    onClick={() => {
                                      history.push({
                                        pathname: "/admin/user-reportView",
                                        state: {
                                          reportData: data,

                                          userData: location.state.userData,
                                        },
                                      });
                                    }}
                                    startIcon={<HiOutlineDocumentText />}
                                    // onClick={() => {
                                    //   setBillView({ isView: true, data });
                                    // }}
                                  >
                                    View
                                  </Button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                // <tr>
                //   <td colSpan={6}>
                //     {" "}
                <div
                  className="center__items p-5"
                  style={{ flexDirection: "column" }}
                >
                  <img src={emptyReport} alt="Empty Reports" />
                  <p
                    style={{
                      color: "#D2D2D2",
                      padding: "20px",
                      fontSize: "20px",
                      fontFamily: "mediumFont",
                    }}
                  >
                    Reports not found
                  </p>
                </div>
                //   </td>
                // </tr>
              )}
            </tbody>
          </table>
        </section>
        {allreports.length > 5 ? (
          <div className="d-flex flex-row align-items-center justify-content-end mb-4">
            <TablePagination
              component="div"
              count={allreports.length}
              page={reportpage}
              onChangePage={reporthandleChangePage}
              rowsPerPage={reportrowsPerPage}
              onChangeRowsPerPage={reporthandleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 50]}
            />
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <section>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div className="userView__container">
          <div
            className="col-12 bg-white align-items-center pt-3 pb-3"
            style={{
              borderBottom: "1px solid #B3CDFF",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                history.push("/admin/users");
              }}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <FiArrowLeft />
              </IconContext.Provider>
            </IconButton>
            <p
              style={{
                cursor: "pointer",
                fontSize: "25px",
                marginLeft: "5px",
              }}
              className="semiFont"
            >
              Back
            </p>
          </div>
          <section className="tabs__wrapper mt-2">
            <div className="tabs__section bg-white">
              <section className="tab__container  d-flex w-50">
                <div
                  className={selectedTab === 0 ? "tabItem--active" : "tabItem"}
                  onClick={() => setSelectedTab(0)}
                >
                  <p>Members</p>
                </div>
                <div
                  className={selectedTab === 1 ? "tabItem--active" : "tabItem"}
                  onClick={() => setSelectedTab(1)}
                >
                  <p>Reports</p>
                </div>
              </section>
            </div>
            <div className="tabItems__container">
              {selectedTab === 0 ? <UserMembers /> : null}
              {selectedTab === 1 ? <UserReports /> : null}
            </div>
          </section>
        </div>
      )}
    </section>
  );
};

export default UserView;
