import { useState, useEffect } from "react";
import axios from "axios";
import Global from "../global";
import { IoMdAddCircle } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { IconContext } from "react-icons";
import { Button } from "@material-ui/core";
import Loader from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const BlueSwitch = withStyles({
  switchBase: {
    color: "#0F61FD",
    "&$checked": {
      color: "#0F61FD",
    },
    "&$checked + $track": {
      backgroundColor: "#0F61FD",
    },
  },
  checked: {},
  track: {},
})(Switch);

const SystemFeature = ({ refresh }) => {
  const classes = useStyles();
  const [features, setFeatures] = useState({});
  const [loading, setLoading] = useState(true);
  const rowdataAlign = { verticalAlign: "middle" };
  const [submitLoading, setSubmitLoading] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    const getFeature = async () => {
      try {
        const getResponse = await axios.post(
          Global.base_url + "/system",
          null,
          config
        );
        // console.log(response);

        if (getResponse.status === 200) {
          //   console.log("system feature: ", getResponse);
          const getPrivileges = getResponse.data.privileges.map((list) => {
            return {
              ...list,
              isSwitched: list.enabled ? true : false,
            };
          });
          let filterFeatures = getResponse.data.features.map((item) => {
            return {
              ...item,
              isSwitched: item.enabled ? true : false,
              privileges: getPrivileges.filter(
                (list) => list.feature_name === item.feature_name
              ),
            };
          });
          //   console.log("filterFeatures", filterFeatures);
          setFeatures(filterFeatures);
          return setLoading(false);
        }
      } catch (error) {
        if (error.getResponse) {
          if (error.getResponse.status === 401) {
            return toast.error("Unable to get roles");
          }
          if (error.getResponse.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    };

    getFeature();
  }, []);

  // feature switch buttons
  const ParentSwictch = ({
    data,
    id,
    handleParentChange,
    index,
    parentIndex,
  }) => {
    // console.log("switch data", key);
    const switchData = data && data.isSwitched;

    return (
      <>
        <FormControlLabel // Needed by MaterialUI to create labels, like Santa, Grinch
          control={
            <BlueSwitch
              checked={switchData} // boolean true/false
              onChange={() => {
                handleParentChange(id, !switchData, parentIndex);
              }} // "!" <-- lets the state flip, otherwise state flips once
            />
          }
        />
      </>
    );
  };

  //handle change for features
  const handleParentChange = (id, switchState, parentIndex) => {
    // let arr = [];
    const updatedData = features[parentIndex];

    console.log("updatedData", updatedData);
    updatedData.isSwitched = switchState;

    const newData = [...features];
    newData[parentIndex] = updatedData;
    // arr.push(updatedData);

    // console.log("newAttitude", arr);
    setFeatures(newData);
  };

  //privileges switch button
  const SwitchButton = ({ data, id, handleChange, index, parentIndex }) => {
    // console.log("switch data", key);
    const switchData = data && data.isSwitched;

    return (
      <>
        <FormControlLabel // Needed by MaterialUI to create labels, like Santa, Grinch
          control={
            <BlueSwitch
              checked={switchData} // boolean true/false
              onChange={() => {
                handleChange(id, !switchData, index, parentIndex);
              }} // "!" <-- lets the state flip, otherwise state flips once
            />
          }
          //   label={student.behaviour.toString()}
        />
      </>
    );
  };

  // handle change for privileges
  const handleChange = (id, switchState, index, parentIndex) => {
    // let arr = [];
    const updatedData = features[parentIndex].privileges[index];

    // console.log("updatedData", index);
    updatedData.isSwitched = switchState;

    const newData = [...features];
    newData[parentIndex].privileges[index] = updatedData;
    // arr.push(updatedData);

    // console.log("newAttitude", arr);
    setFeatures(newData);
  };

  const submitFeatures = async () => {
    setSubmitLoading(true);
    console.log("features", features);

    let getPrivileges = [];

    let getFeature = features.map((item) => {
      return {
        feature_id: item.feature_id,
        feature_name: item.feature_name,
        enabled: item.isSwitched ? 1 : 0,
      };
    });

    features.map((item) => {
      item.privileges.map((list) => {
        getPrivileges.push({
          privilege_id: list.privilege_id,
          enabled: list.isSwitched ? 1 : 0,
        });
      });
    });

    console.log("getPrivileges", getPrivileges);

    // return;
    try {
      const response = await axios.post(
        Global.base_url + "/system/submit",
        {
          features: getFeature,
          privileges: getPrivileges,
        },
        config
      );
      // console.log(response);
      if (response.status === 200) {
        setSubmitLoading(false);
        toast.success("Features Updated Successfully");
        refresh(Date.now() + 1);
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error.response && error.response.status === 401) {
        toast.error("Unable to update Features");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <section className="feature__container">
          <div className={classes.root}>
            <p className="boldFont my-2 pb-2 section__title">System Features</p>
            {features.length &&
              features.map((item, parentIndex) => {
                return (
                  <Accordion key={parentIndex}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon htmlColor="#0F61FD" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="acc__row d-flex align-items-center justify-content-between flex-grow-1">
                        <p
                          className="acc__title"
                          // onClick={(event) => event.stopPropagation()}
                        >
                          {item.feature_name
                            .toLowerCase()
                            .replace(/./, (name) => name.toUpperCase())}
                        </p>

                        <div onClick={(event) => event.stopPropagation()}>
                          <ParentSwictch
                            data={item}
                            id={item.feature_id}
                            handleParentChange={handleParentChange}
                            parentIndex={parentIndex}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <section
                        className="admin-product-filter-section table-responsive fixHeader
"
                      >
                        <table
                          className="table  text-center  admin-product_usertable mb-0"
                          style={{
                            backgroundColor: "white",
                          }}
                          id="bill-table"
                        >
                          <thead>
                            <tr
                              id="admin-product-row"
                              style={{
                                border: "none",
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <th
                                scope="col"
                                className="admin-product-row_table-header"
                              >
                                Privilege ID
                              </th>
                              <th
                                scope="col"
                                className="admin-product-row-table-header"
                              >
                                Privilege Name
                              </th>
                              <th
                                scope="col"
                                className="admin-product-row-table-header admin-product-row-status-col"
                              >
                                ON / OFF
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.privileges.length > 0 ? (
                              item.privileges.map((data, i) => {
                                const borderBottom = `1px solid #B3CDFF`;
                                return (
                                  <>
                                    <tr
                                      key={data.privilege_id + Math.random()}
                                      style={{
                                        //   borderLeft,
                                        borderBottom,
                                        backgroundColor: "white",
                                      }}
                                      className="admin-product-row-table-row-hover "
                                    >
                                      <td
                                        scope="row"
                                        id="admin-product-rd"
                                        className="admin-product-row-table-row-data mediumFont"
                                        style={rowdataAlign}
                                      >
                                        <p
                                          data-toggle="collapse"
                                          data-target={`#accordion` + i}
                                          className="clickable"
                                          id="button-open"
                                          // style={{ display: "none" }}
                                        >
                                          <IconContext.Provider
                                            value={{
                                              color: "#4059E2",
                                              size: "25px",
                                              style: {
                                                marginBottom: "3px",
                                              },
                                            }}
                                          >
                                            <IoMdAddCircle className="toggle-row-icon" />
                                          </IconContext.Provider>
                                        </p>{" "}
                                        PID{data.privilege_id}
                                      </td>
                                      <td
                                        className="admin-product-row-table-row-data mediumFont"
                                        style={rowdataAlign}
                                      >
                                        {" "}
                                        {data.privilege_name}
                                      </td>
                                      <td
                                        className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                        style={rowdataAlign}
                                      >
                                        <SwitchButton
                                          index={i}
                                          data={data}
                                          id={data.privilege_id}
                                          handleChange={handleChange}
                                          parentIndex={parentIndex}
                                        />
                                      </td>
                                    </tr>
                                    <tr
                                      id={"accordion" + i}
                                      className="collapse  admin-product-hided-row"
                                      key={data.privilege_id + Math.random()}
                                    >
                                      <td colSpan="6" style={rowdataAlign}>
                                        <ul
                                          className="admin-product-row-table-hide-list text-left mediumFont"
                                          style={{ color: "#525252" }}
                                        >
                                          <li
                                            key={
                                              data.privilege_id + Math.random()
                                            }
                                            style={{
                                              padding: "10px",
                                            }}
                                          >
                                            <div className="row justify-content-start align-items-center ml-3 ">
                                              <div className="col-6  text-center">
                                                ON / OFF
                                              </div>
                                              <div className="col-6  text-left">
                                                <SwitchButton
                                                  index={i}
                                                  data={data}
                                                  id={data.privilege_id}
                                                  handleChange={handleChange}
                                                  parentIndex={parentIndex}
                                                />
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>
                                  {" "}
                                  <div
                                    style={{
                                      textAlign: "center",
                                      padding: "20px",
                                    }}
                                  >
                                    {/* <IconContext.Provider
                          value={{ color: "#D2D2D2", size: "100px" }}
                        >
                          <MdLocalHospital />
                        </IconContext.Provider> */}
                                    <p
                                      style={{
                                        color: "#D2D2D2",
                                        padding: "20px",
                                        fontSize: "20px",
                                        fontFamily: "mediumFont",
                                      }}
                                    >
                                      No Privileges found...
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </section>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
          <div className="button__container mt-3 d-flex align-items-center justify-content-end">
            <Button
              variant="contained"
              className="primary__btn"
              id="admin-reportview-download-btn"
              style={{
                padding: "10px 25px",
                width: "150px",
                color: "#fff",
              }}
              disabled={submitLoading}
              onClick={() => submitFeatures()}
            >
              {submitLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
              {/* Submit */}
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};

export default SystemFeature;
