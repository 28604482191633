import { Button, IconButton, Tooltip } from "@material-ui/core";
import { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Popover from "@material-ui/core/Popover";

import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
import { IoMdAddCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { CgClose } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import { BsSliders } from "react-icons/bs";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge";
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";

import _ from "lodash";
import emptyPackage from "../assets/images/empty-package.png";
import { FilterDrama } from "@material-ui/icons";


const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});



const Packages = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  // console.log("AdminProduct", props);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState("");
  const [status, setStatus] = useState();
  const [packageAccess, setPackageAccess] = useState({});
  const [productData, setProducts] = useState([]);
  const [countries, setCountries] = useState([{ name: "Choose Country" }]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [packageList, setPackages] = useState([]);
  const [editPackage, setPackage] = useState({
    data: {},
    index: "",
    selectedPackage: null,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  console.log("selected package", editPackage);
  const [showEditModal, setShowEditModal] = useState(false);
  const [choosePackageModal, setChoosePackageModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [filterData, setFilterData] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

   //filter states
   const [anchorEl, setAnchorEl] = useState(null);
   const [search, setSearch] = useState("");
 
   const [selectedStatus, setSelectedStatus] = useState([]);
   const [selectedType, setSelectedType] = useState([]);
   const [selectedPackageType, setSelectedPackageType] = useState([]);
   const [paginationData, setPaginationData] = useState({});

   const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterClose = () => {
    setAnchorEl(null);
  };

  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangePage = (event, newPage) => {
    getPackage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    getPackage(page, event.target.value);
  };

  useEffect(() => {
    const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Active" ? 1 : 0;
      });
      const submitpackage = selectedPackageType && selectedPackageType.map(item => {
        return item === "linked" ? 1 : 0
      })
    async function fetchPackages() {
      try {
        const checkPackage = await axios.post(
          Global.base_url + "/package/check/privileges",
          null,
          config
        );
        // console.log(memberResponse);

        if (checkPackage.status === 200) {
          // setLoading(false);
          console.log("check package", checkPackage);
          setPackageAccess(checkPackage.data);
          if (
            _.some(checkPackage.data.privileges, [
              "privilege_name",
              "VIEW PACKAGE",
            ])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/package",
                {
                  currentPage: page + 1,
                  pageSize: rowsPerPage,
                  search: search,
                  test_type: selectedType.length ? selectedType : [],
                  package_type: submitpackage.length ? submitpackage : [],
                  status: submitStatus.length ? submitStatus : [],
                },
                config
              );

              if (response.status === 200) {
                
                setPackages(response.data.data);
                let sortedStatus = Object.keys(response.data.filter.status).map(
                  (item) => {
                    return {
                      name: item,
                      // checked: false,
                    };
                  }
                );
                let sortedType = response.data.filter.test_type.map((item) => {
                  return {
                    name: item,
                    // checked: false,
                  };
                });

                let sortedPackageType = Object.keys(response.data.filter.package_type).map((item) => {
                  return {
                    name: item
                  }
                });
                setFilterData({
                  status: sortedStatus,
                  type: sortedType,
                  packageType:sortedPackageType
                });
                setPaginationData(response.data.pagination);
                setPage(response.data.pagination.currentPage - 1);
                setRowsPerPage(response.data.pagination.pageSize);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  // console.log("admin product error", error);
                  setLoading(false);
                  return toast.error("Unable to get Packages");
                }
                if (error.response.status === 500) {
                  // console.log("admin product error", error);
                  setLoading(false);
                  return toast.error("Internal server error");
                }
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setLoading(true);
            return toast.error("Unable to get package access");
          }
          if (error.response.status === 500) {
            setLoading(true);
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchPackages();
  }, [refresh]);


  const getPackage = async (pageData, rowsPerPageData) => {
    const submitStatus = selectedStatus.map((item) => {
      return item === "Active" ? 1 : 0;
    });
    const submitpackage = selectedPackageType.map(item => {
      return item === "linked" ? 1 : 0
    })
    try {
      const response = await axios.post(
        Global.base_url + "/package",
        {
          currentPage: pageData + 1,
          pageSize: rowsPerPageData,
          search: search,
          test_type: selectedType.length ? selectedType : [],
          package_type: submitpackage.length ? submitpackage : [],
          status: submitStatus.length ? submitStatus : [],
        },
        config
      );
      // console.log(response);

      if (response.status === 200) {
        console.log("get test data", response.data);

        setPackages(response.data.data);
        setPaginationData(response.data.pagination);
        setPage(response.data.pagination.currentPage - 1);
        setRowsPerPage(response.data.pagination.pageSize);
        setAnchorEl(null);

        let sortedStatus = Object.keys(response.data.filter.status).map(
          (item) => {
            return {
              name: item,
              // checked: false,
            };
          }
        );

        let sortedType = response.data.filter.test_type.map((item) => {
          return {
            name: item,
            // checked: false,
          };
        });

        let sortedPackageType = Object.keys(response.data.filter.package_type).map((item) => {
          return {
            name: item
          }
        });
        setFilterData({
          status: sortedStatus,
          type: sortedType,
          packageType:sortedPackageType
        });

        setPaginationData(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get packages");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };




 
  // <div className="col-12 col-xl-6 col-lg-6 ">

  const PackageModal = () => {
    return (
      <Modal
        show={choosePackageModal}
        onHide={() => {
          setChoosePackageModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter" className="boldFont">
            Choose Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="carbon__buttoncontainer d-flex px-2 py-4 mb-4">
            <Button
              variant="contained"
              className="carbon__secondarybtn carbon__btn"
              style={{
                //   padding: "25px ",
                color: "#fff",
                //   border: "1px solid #1565d8",
                //   marginRight: "15px",
              }}
              onClick={() => {
                history.push({
                  pathname: "/admin/package-view",
                  state: {
                    selectedPackage: 0,
                    editable: false,
                    data: null,
                  },
                });
                setChoosePackageModal(false);
              }}
            >
              <p className="carbon__text"> Unlinked Package</p>
            </Button>

            <Button
              variant="contained"
              className="carbon__primarybtn carbon__btn"
              style={{
                color: "#fff",
              }}
              onClick={() => {
                history.push({
                  pathname: "/admin/package-view",
                  state: {
                    selectedPackage: 1,
                    editable: false,
                    data: null,
                  },
                });
                setChoosePackageModal(false);
              }}
            >
              <div className="carbon__text">Linked Package</div>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <PackageModal
            onHide={() => setChoosePackageModal(false)}
            show={choosePackageModal}
          />
          <DeleteModal
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            selectedData={editPackage.data.package_id}
            refresh={setRefresh}
            apiUrl={"/package/delete"}
            name={"Package"}
            // id={"category_id"}
          />

          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Packages
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
               <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search ID, Name, Price"
                  onKeyDown={(e) => {
                    // detects Enter key or numberpad enter key
                    if (e.code === "Enter") {
                      getPackage(page, rowsPerPage);
                    }
                  }}
                />
              </div>
              <div
                className="filter__container"
                style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
              >
                <div
                  className="filter__icon p-3"
                  onClick={filterClick}
                  style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
                >
                  {selectedType.length + selectedStatus.length + selectedPackageType.length !== 0 ? (
                    <Badge
                      badgeContent={selectedType.length + selectedStatus.length + selectedPackageType.length}
                      color="primary"
                    >
                      <IconContext.Provider
                        value={{
                          color: "#333",
                          size: "25px",
                        }}
                      >
                        <BsSliders />
                      </IconContext.Provider>
                    </Badge>
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: "#333",
                        size: "25px",
                      }}
                    >
                      <BsSliders />
                    </IconContext.Provider>
                  )}
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      // width: "45%",
                      backgroundColor: "#f3f3f3",
                      // display: "flex",
                      // justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="pop__container">
                    {selectedType.length + selectedStatus.length  + selectedPackageType.length!== 0 ? (
                      <div className="selected__items px-4 pt-4 d-flex align-items-center semiFont">
                        <div className="batch__item d-flex align-items-center">
                          <p className="mr-2 mt-1">
                            {selectedType.length + selectedStatus.length + selectedPackageType.length}
                          </p>
                          <div
                            className="icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedType([]);
                              setSelectedStatus([]);
                              setSelectedPackageType([])
                            }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#fff",
                                size: "20px",
                              }}
                            >
                              <CgClose />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <p className="ml-2">Filteres are selected</p>
                      </div>
                    ) : null}

                    <div className="row px-4 pb-4 pt-4 justify-content-lg-between">
                      <div className="col">
                        <p className="semiFont pop__header">Test Type</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.type &&
                            filterData.type.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedType.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedType([
                                            ...selectedType,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedType(
                                            selectedType &&
                                              selectedType.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div className="col">
                        <p className="semiFont pop__header">Package Type</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.packageType &&
                            filterData.packageType.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedPackageType.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedPackageType([
                                            ...selectedPackageType,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedPackageType(
                                            selectedPackageType &&
                                              selectedPackageType.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div className="col">
                        <p className="semiFont pop__header">Status</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.status &&
                            filterData.status.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedStatus.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedStatus([
                                            ...selectedStatus,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedStatus(
                                            selectedStatus &&
                                              selectedStatus.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="carbon__buttoncontainer">
                      {/* {location.state.editable ? ( */}
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => {
                          setSelectedType([]);
                          setSelectedStatus([]);
                          setSelectedPackageType([])
                        }}
                      >
                        <p>Reset Filter</p>
                      </Button>
                      {/* ) : null} */}

                      <Button
                        variant="contained"
                        className="carbon__primarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "10px 25px",

                          color: "#fff",
                        }}
                        onClick={() => getPackage(page, rowsPerPage)}
                      >
                        <p className="carbon__text">Apply Filter</p>
                        {/* Yes */}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* </> */}
              </div>
              {packageAccess &&
              _.some(packageAccess.privileges, [
                "privilege_name",
                "ADD PACKAGE",
              ]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button"
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    if (
                      packageAccess &&
                      _.some(packageAccess.privileges, [
                        "privilege_name",
                        "LINKED PACKAGE",
                      ])
                    ) {
                      setChoosePackageModal(true);
                    } else {
                      history.push({
                        pathname: "/admin/package-view",
                        state: {
                          selectedPackage: 0,
                          editable: false,
                          data: null,
                        },
                      });
                    }
                  }}
                >
                  Add Package
                </Button>
              ) : null}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Package Type
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Test Type
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-price-col"
                  >
                    Price
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  {_.some(packageAccess.privileges, [
                    "privilege_name",
                    "EDIT PACKAGE",
                  ]) ||
                  _.some(packageAccess.privileges, [
                    "privilege_name",
                    "DELETE PACKAGE",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {packageList.length ? (
                  packageList.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;
                    // const font = "mediumFont";

                    return (
                      <>
                        <tr
                          key={data.lab_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            PID{data.package_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {" "}
                            {data.package_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.pack_linked ? "Linked" : "Unlinked"}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.package_type}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            &#8377; {data.pack_offer_price}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.status ? "Active" : "Inactive"}
                          </td>
                          {packageAccess &&
                          (_.some(packageAccess.privileges, [
                            "privilege_name",
                            "EDIT PACKAGE",
                          ]) ||
                            _.some(packageAccess.privileges, [
                              "privilege_name",
                              "DELETE PACKAGE",
                            ])) ? (
                            <td
                              className="admin-product-row-action-col py-3 "
                              // colSpan={2}
                              style={rowdataAlign}
                            >
                              <div className="iconButtons__container">

                              {_.some(packageAccess.privileges, [
                                "privilege_name",
                                "EDIT PACKAGE",
                              ]) ? (
                                <Tooltip title={"Edit"} placement="top-center">
                                  <IconButton
                                     className="edit__IconButton"
                                    onClick={() => {
                                      history.push({
                                        pathname: "/admin/package-view",
                                        state: {
                                          editable: true,
                                          data: data,
                                          selectedPackage: data.pack_linked,
                                        },
                                      });
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#1565d8",
                                        size: "30px",
                                      }}
                                    >
                                      <BiEditAlt />
                                    </IconContext.Provider>
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              {_.some(packageAccess.privileges, [
                                "privilege_name",
                                "DELETE PACKAGE",
                              ]) ? (
                                <Tooltip
                                  title={"Remove"}
                                  placement="top-center"
                                >
                                  <IconButton
                                    className="delete__IconButton"
                                    onClick={() => {
                                      setPackage({
                                        data: data,
                                      });
                                      setDeleteModal(true);
                                      // deleteReport(
                                      //   uploadedReports.combined[0]
                                      // );
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#cc0000",
                                        size: "30px",
                                      }}
                                    >
                                      <AiOutlineDelete />
                                    </IconContext.Provider>
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.package_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul className="admin-product-row-table-hide-list text-left mediumFont mb-0">
                              <li
                                key={i + data.package_id + i + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Package Type:
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.pack_linked ? "Linked" : "Unlinked"}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={i + data.package_id + i + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Test Type:
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.package_type}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={i + data.package_id + i + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Price:
                                  </div>
                                  <div className="col-6  text-left">
                                    &#8377; {data.pack_offer_price}
                                  </div>
                                </div>
                              </li>

                              <li
                                key={data.package_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.status ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </li>
                              {packageAccess &&
                          (_.some(packageAccess.privileges, [
                            "privilege_name",
                            "EDIT PACKAGE",
                          ]) ||
                            _.some(packageAccess.privileges, [
                              "privilege_name",
                              "DELETE PACKAGE",
                            ])) ? (
                                <li
                                  key={data.package_id + Math.random()}
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center iconButtons__container">
                                      {_.some(packageAccess.privileges, [
                                        "privilege_name",
                                        "EDIT PACKAGE",
                                      ]) ? (
                                        <Tooltip
                                          title={"Edit"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                           className="edit__IconButton"
                                            onClick={() => {
                                              history.push({
                                                pathname: "/admin/package-view",
                                                state: {
                                                  editable: true,
                                                  data: data,
                                                  selectedPackage:
                                                    data.pack_linked,
                                                },
                                              });
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "30px",
                                              }}
                                            >
                                              <BiEditAlt />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                      {_.some(packageAccess.privileges, [
                                        "privilege_name",
                                        "DELETE PACKAGE",
                                      ]) ? (
                                        <Tooltip
                                          title={"Remove"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="delete__IconButton"
                                            onClick={() => {
                                              setPackage({
                                                data: data,
                                              });
                                              setDeleteModal(true);
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#cc0000",
                                                size: "30px",
                                              }}
                                            >
                                              <AiOutlineDelete />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyPackage} alt="empty-package" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {packageAccess &&
                      _.some(packageAccess.privileges, [
                        "privilege_name",
                        "VIEW PACKAGE",
                      ])
                        ? "Packages not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {paginationData && paginationData.totalData > 5 ?  (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={paginationData ? paginationData.totalData : 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Packages;
