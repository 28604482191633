import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { FiArrowLeft } from "react-icons/fi";
import { IconContext } from "react-icons";
import { IconButton, Button, Tooltip } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import Global from "../global";
import Select from "react-select";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import csc from "country-state-city";
import emptyTest from "../assets/images/empty-test.png";
import emptyPackage from "../assets/images/empty-package.png";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toast, ToastContainer } from "react-toastify";
import { IoMdAddCircle } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";

const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

//add Labs
function AddHospital({ editable, lab_id, data }) {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentOptions, setDepartmentsOptions] = useState([]);
  const DepartmentData = [
    { value: 1, label: "Department 1" },
    { value: 2, label: "Department 2" },
    { value: 3, label: "Department 3" },
    { value: 4, label: "Department 4" },
    { value: 5, label: "Department 5" },
  ];

  const [countries, setCountries] = useState([]);
  const required = <span style={{ color: "red" }}>*</span>;

  const history = useHistory();

  const getAllStates = (e) => {
    // const noOption = { label: "Choose State", value: "" };
    const country = countries.find((c) => c.name === e);
    setStates([...csc.getStatesOfCountry(country.isoCode)]);
  };
  const getAllCities = (e) => {
    // const noOption = { label: "Choose City", value: "" };
    const state = states.find((s) => s.name === e);

    setCities([...csc.getCitiesOfState(state.countryCode, state.isoCode)]);
  };
  const [selectedDepartments, setselectedDepartments] = useState([]);
  const [loadingDepartments, setLoadingDepartments] = useState(true); // Loading state

  useEffect(() => {
    const fetchdepartments = async () => {
      try {
        const response = await axios.get(
          Global.base_url + "/departments",
          config
        );
        if (response.status === 200) {
          const modifiedList = response.data?.data?.map((item) => {
            return {
              label: item?.name,
              value: item?.department_id,
            };
          });
          setDepartments(modifiedList);
          setDepartmentsOptions(modifiedList);
          console.log("moddifed_list", modifiedList);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDepartments(false);
      }
    };
    fetchdepartments();
  }, []);

  useEffect(() => {
    if (!loadingDepartments && lab_id) {
      const departmentData = data?.departments?.split(",")?.map(function (num) {
        return parseInt(num.trim());
      });
      const filteredDepartments = departments.filter((item) => {
        return departmentData?.includes(item.value);
      });

      console.log("filteredDepartments", filteredDepartments);
      setselectedDepartments(filteredDepartments);
    }
  }, [lab_id, loadingDepartments]);

  const initialValues = editable
    ? {
        hospital_name: data.lab_name,

        state: { label: data.state, value: data.state },
        city: { label: data.city, value: data.city },
        country: { label: data.country, value: data.country },
        status: {
          label: data.status ? "Active" : "InActive",
          value: data.status,
        },
        // package_id: [],
        // test_id: [],
        department: selectedDepartments,
        lab_address: data.lab_address,
      }
    : {
        hospital_name: "",

        state: null,
        city: null,
        country: null,
        status: null,
        department: [],
        // package_id: [],
        // test_id: [],
        lab_address: "",
      };

  useEffect(() => {
    const country = csc.getAllCountries();

    setCountries([...countries, ...country]);
  }, []);

  // Log options whenever they change
  useEffect(() => {
    console.log("Department options:", departments);
  }, [departments]);

  return (
    <>
      <div
        className="bg-white pt-3 px-2 px-xl-5 px-lg-3"
        style={{ paddingBottom: "30px" }}
      >
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            hospital_name: yup.string().required("Name Cannot be empty"),
            state: yup.object().required("Please select State").nullable(),
            country: yup.object().required("Please select Country").nullable(),
            city: yup.object().required("Please select city").nullable(),
            status: yup.object().required("Please select status").nullable(),
            department: yup
              .array()
              .min(1, "Please select atleast one department"),
            lab_address: yup.string().required("Address Cannot be empty"),
          })}
          onSubmit={async (values, formik) => {
            const url = editable
              ? Global.base_url + "/lab/update/info"
              : Global.base_url + "/lab/add";
            const selectedDepartmentIds = values.department.map(
              (dept) => dept.value
            );
            var data = {
              lab_name: values.hospital_name,
              country: values.country.value,
              state: values.state.value,
              departments: selectedDepartmentIds.toString(),
              city: values.city.value,
              status: values.status.value,
              lab_address: values.lab_address,
            };
            if (editable) {
              data.lab_id = lab_id;
            }

            try {
              setSubmitLoading(true);
              const response = await axios.post(url, data, config2);
              // console.log(response);
              if (response.status === 200) {
                editable
                  ? toast.success("Hospital Updated Successfully")
                  : toast.success("Hospital Added Successfully");
                setSubmitLoading(false);
                history.replace("/admin/labs");
              }
            } catch (error) {
              if (error.response && error.response.status === 401) {
                editable
                  ? toast.error("Hospital Update failed!!!")
                  : toast.error("Hospital creation failed!!!");
                setSubmitLoading(false);
                return console.log(error);
              }
              if (error.response && error.response.status === 500) {
                toast.error("Internal server error");
                setSubmitLoading(false);
                return console.log(error);
              }
            }
          }}
        >
          {(formik) => {
            // console.log("formik errors", formik.errors);
            return (
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="hospital_name"
                          className="semiFont login__label"
                        >
                          Hospital Name {required}
                        </label>
                        <input
                          type="text"
                          className="form-control addproduct--input"
                          id="hospital_name"
                          value={formik.values.hospital_name}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "hospital_name",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9 ]/g,
                                ""
                              )
                            )
                          }
                          style={{
                            border: formik.errors.hospital_name
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          placeholder="Enter hospital name"
                        />
                        {formik.errors.hospital_name && (
                          <div className="err">
                            <p>{formik.errors.hospital_name}</p>
                          </div>
                        )}
                      </div>

                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                        id="a123"
                      >
                        <label
                          htmlFor="status"
                          className="semiFont login__label"
                        >
                          Status {required}
                        </label>
                        <Select
                          value={formik.values.status}
                          onChange={(p) => {
                            formik.setFieldValue("status", p);
                          }}
                          isClearable={true}
                          id="inputDropdown"
                          options={statusOptions}
                          placeholder={"Select Status"}
                          // className="admim__Addproduct--dropdown "
                          styles={{
                            control: (base, baseState) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "10px 10px",
                              border: formik.errors.status
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                        />

                        {formik.errors.status && (
                          <div className="err">
                            <p>{formik.errors.status}</p>
                          </div>
                        )}
                      </div>

                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                        id="a123"
                      >
                        <label
                          htmlFor="department"
                          className="semiFont login__label"
                        >
                          Department {required}
                        </label>

                        <Select
                          value={formik.values.department}
                          onChange={(p) => {
                            formik.setFieldValue("department", p);
                          }}
                          isMulti
                          isClearable={true}
                          id="inputDropdown"
                          options={departments}
                          placeholder={"Select Department"}
                          // className="admim__Addproduct--dropdown "
                          styles={{
                            control: (base, baseState) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "10px 10px",
                              border: formik.errors.department
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                        />
                      </div>

                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                        id="a123"
                      >
                        <label
                          htmlFor="country"
                          className="semiFont login__label"
                        >
                          Country {required}
                        </label>
                        <Select
                          value={formik.values.country}
                          onChange={(p) => {
                            if (p) {
                              getAllStates(p?.value);
                              formik.setFieldValue("country", p);
                              formik.setFieldValue("state", null);
                              formik.setFieldValue("city", null);
                            } else {
                              formik.setFieldValue("country", null);
                              formik.setFieldValue("state", null);
                              formik.setFieldValue("city", null);
                            }
                          }}
                          isClearable={true}
                          // defaultValue=""
                          options={countries.map((c) => {
                            return { value: c.name, label: c.name };
                          })}
                          id="inputDropdown"
                          name="country"
                          className="admim__Addproduct--dropdown "
                          placeholder="Select Country"
                          // id="state"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "10px 10px",
                              border: formik.errors.country
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                        />

                        {formik.errors.country && (
                          <div className="err">
                            <p>{formik.errors.country}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                        id="a123"
                      >
                        <label
                          htmlFor="state"
                          className="semiFont login__label"
                        >
                          State {required}
                        </label>
                        <Select
                          value={formik.values.state}
                          onChange={(p) => {
                            if (p) {
                              getAllCities(p.value);
                              formik.setFieldValue("state", p);
                              formik.setFieldValue("city", null);
                            } else {
                              formik.setFieldValue("state", null);
                              formik.setFieldValue("city", null);
                            }
                          }}
                          options={states.map((s) => {
                            return { value: s.name, label: s.name };
                          })}
                          isClearable={true}
                          isDisabled={formik.values.country === null}
                          // defaultValue=""
                          id="inputDropdown"
                          className="admim__Addproduct--dropdown "
                          placeholder="Select State"
                          styles={{
                            control: (base, baseState) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "10px 10px",
                              backgroundColor: "white",
                              border: formik.errors.state
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                        />

                        {formik.errors.state && (
                          <div className="err">
                            <p>{formik.errors.state}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                        id="a123"
                      >
                        <label
                          htmlFor="product-status"
                          className="semiFont login__label"
                        >
                          City {required}
                        </label>
                        <Select
                          value={formik.values.city}
                          onChange={(p) => {
                            formik.setFieldValue("city", p);
                          }}
                          isDisabled={formik.values.state === null}
                          options={cities.map((c) => {
                            return { value: c.name, label: c.name };
                          })}
                          isClearable={true}
                          // defaultValue=""
                          className="admim__Addproduct--dropdown "
                          placeholder="Select City"
                          id="inputDropdown"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "10px 10px",
                              backgroundColor: "white",
                              border: formik.errors.city
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                        />

                        {formik.errors.city && (
                          <div className="err">
                            <p>{formik.errors.city}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="product-description"
                          className="semiFont login__label"
                        >
                          Address{required}
                        </label>
                        <textarea
                          id="product_description"
                          rows="9"
                          style={{
                            border: formik.errors.lab_address
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                            resize: "none",
                            borderRadius: "8px",
                            padding: "10px",
                            color: "#183b56",
                            fontSize: "17px",
                            fontFamily: "mediumFont",
                          }}
                          value={formik.values.lab_address}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "lab_address",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9-_+.,/' ]/g,
                                ""
                              )
                            );
                          }}
                        ></textarea>
                        {formik.errors.lab_address && (
                          <div className="err">
                            <p>{formik.errors.lab_address}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "mt-3 d-flex flex-column flex-sm-row justify-content-end"
                    }
                  >
                    <div className="button__container mt-4 d-flex align-items-center justify-content-end">
                      <Button
                        variant="contained"
                        className="primary__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          padding: "10px 25px",
                          width: "170px",
                          color: "#fff",
                        }}
                        disabled={submitLoading}
                        onClick={formik.handleSubmit}
                      >
                        {submitLoading ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : editable ? (
                          "Save Info"
                        ) : (
                          "Add Hospital"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

function AddTests({ lab_id }) {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const [labData, setLabData] = useState([]);
  const [alltestData, setTestData] = useState([]);
  const [allpackagesData, setPackageData] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const [allTest, setallTest] = useState(false);
  const [allTests, setallTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const required = <span style={{ color: "red" }}>*</span>;
  const rowdataAlign = { verticalAlign: "middle" };

  const searchTest = (event) => {
    let filteredTests = allTests.filter((list) => {
      return event !== null && event !== undefined
        ? list.test_name
            .toString()
            .toLowerCase()
            .includes(event.toLowerCase()) ||
            list.test_id
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            list.test_type
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase())
        : allTests;
    });
    setTestData(filteredTests);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const labData = axios.post(
          Global.base_url + "/lab/get/id",
          { lab_id },
          config
        );
        const products = axios.post(
          Global.base_url + "/lab/get/products",
          { lab_id },
          config
        );
        const [res2, res3] = await axios.all([labData, products]);
        // console.log(response);

        if (res2.status === 200) {
          setallTest(res2.data.data[0].all_test ? true : false);

          setLabData(res2.data.data[0]);
          setSelectedTest(res2.data.data[0].tests);
        }
        if (res3.status === 200) {
          setallTests(res3.data.tests);
          setTestData(res3.data.tests);
          setPackageData(res3.data.packages);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.response);

        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get test access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      // provilege check api
    };

    getData();
  }, []);
  return (
    <>
      <div className="bg-white p-3 ">
        <Formik
          initialValues={{ lab_id: lab_id }}
          onSubmit={async (values) => {
            let data = selectedTest.filter((tid) => {
              if (_.some(alltestData, ["test_id", tid])) {
                return tid;
              }
            });

            if (data.length) {
              try {
                const addTest = await axios.post(
                  Global.base_url + "/lab/update/test",
                  { lab_id, tests: selectedTest, all_test: allTest },
                  config
                );
                if (addTest.status === 200) {
                  toast.success("Test added to Lab");
                }
              } catch (error) {
                if (error.response && error.reponse.status === 401) {
                  return toast.error("Unable to add test");
                }
                if (error.response && error.reponse.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            } else {
              toast.error("Select atleast one test");
            }
          }}
        >
          {(formik) => {
            return (
              <>
                <section
                  className="mt-2 admin-product-filter-section table-responsive  
"
                >
                  <section className=" d-flex flex-column flex-sm-row justify-content-end justify-content-sm-between align-items-start align-items-sm-center p-2 ">
                    <div className="mb-2 mb-sm-0">
                      <p className="login__label semiFont">
                        Select Labs {required}
                      </p>
                    </div>
                    <div
                      className="filter__container"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className="filter__input"
                        placeholder="Search Tests"
                        name="search test"
                        onKeyUp={(e) => {
                          searchTest(e.target.value);
                        }}
                      />
                      <IconContext.Provider
                        value={{
                          size: "25px",
                          color: "#333",
                        }}
                      >
                        <IoSearchOutline
                          className="filter-search-icon"
                          color="#818181"
                          style={{ cursor: "pointer" }}
                        />
                      </IconContext.Provider>
                    </div>
                  </section>
                  <table
                    className="table  text-center  admin-product_usertable mb-0"
                    style={{
                      backgroundColor: "white",
                    }}
                    id="bill-table"
                  >
                    <thead>
                      <tr
                        id="admin-product-row"
                        style={{
                          border: "none",
                          backgroundColor: "#E0E0E0",
                        }}
                      >
                        <th
                          scope="col"
                          className="admin-product-row_table-header"
                        >
                          <div
                            style={{ position: "relative" }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <input
                              // className="form-check-input"
                              type="checkbox"
                              name="all_test"
                              style={{
                                height: "20px",
                                width: "20px",
                                // marginRight: "10px",
                              }}
                              disabled={!alltestData.length}
                              //   value={formik.values.all_test}
                              onChange={(e) => {
                                const { checked, value } = e.target;
                                if (checked) {
                                  setallTest(true);
                                  let tests = alltestData.map((t) => t.test_id);

                                  setSelectedTest(tests);
                                } else {
                                  setallTest(false);
                                  setSelectedTest([]);
                                }
                              }}
                              checked={allTest}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="admin-product-row_table-header"
                        >
                          Test Id
                        </th>
                        <th
                          scope="col"
                          className="admin-product-row-table-header admin-product-row-price-col"
                        >
                          Test Name
                        </th>

                        <th
                          scope="col"
                          className="admin-product-row-table-header   admin-product-row-status-col"
                        >
                          Test Type
                        </th>
                        {/* <th
                          scope="col"
                          className="admin-product-row-table-header  admin-product-row-status-col"
                        >
                          Location
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="fixedBody">
                      {alltestData.length > 0 ? (
                        alltestData.map((data, i) => {
                          const borderBottom = `1px solid #B3CDFF`;
                          return (
                            <>
                              <tr
                                key={data.test_id + Math.random()}
                                style={{
                                  //   borderLeft,
                                  borderBottom,
                                  backgroundColor: "white",
                                }}
                                className="admin-product-row-table-row-hover modal__table"
                              >
                                {" "}
                                <td
                                  className="admin-product-row-table-row-data mediumFont"
                                  style={rowdataAlign}
                                >
                                  <div style={{ position: "relative" }}>
                                    <input
                                      // className="form-check-input"
                                      type="checkbox"
                                      name="tests"
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                      value={Number(data.test_id)}
                                      onChange={(e) => {
                                        const { checked, value } = e.target;

                                        if (checked) {
                                          setallTest(false);
                                          setSelectedTest([
                                            ...selectedTest,
                                            Number(value),
                                          ]);
                                        } else {
                                          setSelectedTest(
                                            selectedTest &&
                                              selectedTest.filter(
                                                (v) =>
                                                  Number(v) !== Number(value)
                                              )
                                          );
                                          setallTest(false);
                                        }
                                      }}
                                      checked={selectedTest.includes(
                                        Number(data.test_id)
                                      )}
                                    />
                                  </div>
                                </td>
                                <td
                                  scope="row"
                                  id="admin-product-rd"
                                  className="admin-product-row-table-row-data mediumFont"
                                  style={rowdataAlign}
                                >
                                  <p
                                    data-toggle="collapse"
                                    data-target={`#accordion` + i}
                                    className="clickable"
                                    id="button-open"
                                    // style={{ display: "none" }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#4059E2",
                                        size: "25px",
                                        style: {
                                          marginBottom: "3px",
                                        },
                                      }}
                                    >
                                      <IoMdAddCircle className="toggle-row-icon" />
                                    </IconContext.Provider>
                                  </p>{" "}
                                  TID{data.test_id}
                                </td>
                                <td
                                  className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                                  style={rowdataAlign}
                                >
                                  {data.test_name}
                                </td>
                                <td
                                  className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                  style={rowdataAlign}
                                >
                                  {data.test_type}
                                </td>
                              </tr>
                              <tr
                                id={"accordion" + i}
                                className="collapse  admin-product-hided-row"
                                key={data.lab_id + Math.random()}
                              >
                                <td colSpan="6" style={rowdataAlign}>
                                  <ul
                                    className="admin-product-row-table-hide-list text-left mediumFont"
                                    style={{ color: "#525252" }}
                                  >
                                    <li
                                      key={i + data.test_id + i + Math.random()}
                                      style={{
                                        // borderBottom: "1px solid #B3CDFF",
                                        padding: "10px",
                                      }}
                                      className="admin-product-row-hided-col-price"
                                    >
                                      <div className="row justify-content-start ml-3">
                                        <div className="col-6  text-center">
                                          Test Name:
                                        </div>
                                        <div className="col-6  text-left">
                                          {data.test_name}
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      key={i + data.test_id + i + Math.random()}
                                      style={{
                                        // borderBottom: "1px solid #B3CDFF",
                                        padding: "10px",
                                      }}
                                      // className="admin-product-row-hided-col-price"
                                    >
                                      <div className="row justify-content-start ml-3">
                                        <div className="col-6  text-center">
                                          Test Type:
                                        </div>
                                        <div className="col-6  text-left">
                                          {data.test_type}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                              <img src={emptyTest} alt="empty-labs" />
                              <p
                                style={{
                                  color: "#D2D2D2",
                                  padding: "20px",
                                  fontSize: "20px",
                                  fontFamily: "mediumFont",
                                }}
                              >
                                Test not found
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    className={
                      alltestData.length
                        ? "p-3 d-flex flex-column flex-sm-row justify-content-end"
                        : "p-3 d-none flex-column flex-sm-row justify-content-end"
                    }
                  >
                    <div className="button__container d-flex align-items-center justify-content-end">
                      <Button
                        variant="contained"
                        className="primary__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          padding: "10px 25px",
                          width: "150px",
                          color: "#fff",
                        }}
                        onClick={formik.handleSubmit}
                      >
                        {false ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Add Test"
                        )}
                      </Button>
                    </div>
                  </div>
                </section>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

function AddPackages({ lab_id }) {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const [labData, setLabData] = useState([]);

  const [allpackagesData, setPackageData] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [allPackage, setallPackage] = useState(false);
  const [allPackages, setallPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const required = <span style={{ color: "red" }}>*</span>;
  const rowdataAlign = { verticalAlign: "middle" };

  const searchTest = (event) => {
    let filteredTests = allPackages.filter((list) => {
      return event !== null && event !== undefined
        ? list.package_name
            .toString()
            .toLowerCase()
            .includes(event.toLowerCase()) ||
            list.package_id
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            list.package_type
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase())
        : allPackages;
    });
    setPackageData(filteredTests);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const labData = axios.post(
          Global.base_url + "/lab/get/id",
          { lab_id },
          config
        );
        const products = axios.post(
          Global.base_url + "/lab/get/products",
          { lab_id },
          config
        );
        const [res2, res3] = await axios.all([labData, products]);
        // console.log(response);

        if (res2.status === 200) {
          // setallPackage(res2.data.data[0].all_package ? true : false);

          setLabData(res2.data.data[0]);
          setSelectedPackage(res2.data.data[0].packages);
        }
        if (res3.status === 200) {
          setallPackages(res3.data.packages);

          setPackageData(res3.data.packages);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.response);

        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to add Packages");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      // provilege check api
    };

    getData();
  }, []);
  return (
    <>
      <div className="bg-white p-3 ">
        <Formik
          initialValues={{ lab_id: lab_id }}
          onSubmit={async (values) => {
            let data = selectedPackage.filter((pid) => {
              if (_.some(allpackagesData, ["package_id", pid])) {
                return pid;
              }
            });

            if (data.length) {
              try {
                const addPackage = await axios.post(
                  Global.base_url + "/lab/update/package",
                  {
                    lab_id,
                    packages: selectedPackage,
                    all_package: allPackage,
                  },
                  config
                );
                if (addPackage.status === 200) {
                  toast.success("Package added to Lab");
                }
              } catch (error) {
                if (error.response && error.reponse.status === 401) {
                  return toast.error("Unable to add package");
                }
                if (error.response && error.reponse.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            } else {
              toast.error("Select atleast one package");
            }
          }}
        >
          {(formik) => {
            return (
              <>
                <section
                  className="mt-2 admin-product-filter-section table-responsive  
  "
                >
                  <section className=" d-flex flex-column flex-sm-row justify-content-end justify-content-sm-between align-items-start align-items-sm-center p-2 ">
                    <div className="mb-2 mb-sm-0">
                      <p className="login__label semiFont">
                        Select Packages {required}
                      </p>
                    </div>
                    <div
                      className="filter__container"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className="filter__input"
                        placeholder="Search Packages"
                        name="search "
                        onKeyUp={(e) => {
                          searchTest(e.target.value);
                        }}
                      />
                      <IconContext.Provider
                        value={{
                          size: "25px",
                          color: "#333",
                        }}
                      >
                        <IoSearchOutline
                          className="filter-search-icon"
                          color="#818181"
                          style={{ cursor: "pointer" }}
                        />
                      </IconContext.Provider>
                    </div>
                  </section>
                  <table
                    className="table  text-center  admin-product_usertable mb-0"
                    style={{
                      backgroundColor: "white",
                    }}
                    id="bill-table"
                  >
                    <thead>
                      <tr
                        id="admin-product-row"
                        style={{
                          border: "none",
                          backgroundColor: "#E0E0E0",
                        }}
                      >
                        <th
                          scope="col"
                          className="admin-product-row_table-header"
                        >
                          <div
                            style={{ position: "relative" }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <input
                              // className="form-check-input"
                              type="checkbox"
                              name="all_test"
                              style={{
                                height: "20px",
                                width: "20px",
                                // marginRight: "10px",
                              }}
                              disabled={!allpackagesData.length}
                              //   value={formik.values.all_test}
                              onChange={(e) => {
                                const { checked, value } = e.target;
                                if (checked) {
                                  setallPackage(true);
                                  let packages = allpackagesData.map(
                                    (t) => t.package_id
                                  );

                                  setSelectedPackage(packages);
                                } else {
                                  setallPackage(false);
                                  setSelectedPackage([]);
                                }
                              }}
                              checked={allPackage}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="admin-product-row_table-header"
                        >
                          Package Id
                        </th>
                        <th
                          scope="col"
                          className="admin-product-row-table-header admin-product-row-price-col"
                        >
                          Package Name
                        </th>

                        <th
                          scope="col"
                          className="admin-product-row-table-header   admin-product-row-status-col"
                        >
                          Package Type
                        </th>
                        {/* <th
                            scope="col"
                            className="admin-product-row-table-header  admin-product-row-status-col"
                          >
                            Location
                          </th> */}
                      </tr>
                    </thead>
                    <tbody className="fixedBody">
                      {allpackagesData.length > 0 ? (
                        allpackagesData.map((data, i) => {
                          const borderBottom = `1px solid #B3CDFF`;
                          return (
                            <>
                              <tr
                                key={data.package_id + Math.random()}
                                style={{
                                  //   borderLeft,
                                  borderBottom,
                                  backgroundColor: "white",
                                }}
                                className="admin-product-row-table-row-hover modal__table"
                              >
                                {" "}
                                <td
                                  className="admin-product-row-table-row-data mediumFont"
                                  style={rowdataAlign}
                                >
                                  <div style={{ position: "relative" }}>
                                    <input
                                      // className="form-check-input"
                                      type="checkbox"
                                      name="packages"
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                      value={Number(data.package_id)}
                                      onChange={(e) => {
                                        const { checked, value } = e.target;

                                        if (checked) {
                                          setallPackage(false);
                                          setSelectedPackage([
                                            ...selectedPackage,
                                            Number(value),
                                          ]);
                                        } else {
                                          setSelectedPackage(
                                            selectedPackage &&
                                              selectedPackage.filter(
                                                (v) =>
                                                  Number(v) !== Number(value)
                                              )
                                          );
                                          setallPackage(false);
                                        }
                                      }}
                                      checked={selectedPackage.includes(
                                        Number(data.package_id)
                                      )}
                                    />
                                  </div>
                                </td>
                                <td
                                  scope="row"
                                  id="admin-product-rd"
                                  className="admin-product-row-table-row-data mediumFont"
                                  style={rowdataAlign}
                                >
                                  <p
                                    data-toggle="collapse"
                                    data-target={`#accordion` + i}
                                    className="clickable"
                                    id="button-open"
                                    // style={{ display: "none" }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#4059E2",
                                        size: "25px",
                                        style: {
                                          marginBottom: "3px",
                                        },
                                      }}
                                    >
                                      <IoMdAddCircle className="toggle-row-icon" />
                                    </IconContext.Provider>
                                  </p>{" "}
                                  PID{data.package_id}
                                </td>
                                <td
                                  className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                                  style={rowdataAlign}
                                >
                                  {data.package_name}
                                </td>
                                <td
                                  className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                  style={rowdataAlign}
                                >
                                  {data.package_type}
                                </td>
                              </tr>
                              <tr
                                id={"accordion" + i}
                                className="collapse  admin-product-hided-row"
                                key={data.lab_id + Math.random()}
                              >
                                <td colSpan="6" style={rowdataAlign}>
                                  <ul
                                    className="admin-product-row-table-hide-list text-left mediumFont"
                                    style={{ color: "#525252" }}
                                  >
                                    <li
                                      key={
                                        i + data.package_id + i + Math.random()
                                      }
                                      style={{
                                        // borderBottom: "1px solid #B3CDFF",
                                        padding: "10px",
                                      }}
                                      className="admin-product-row-hided-col-price"
                                    >
                                      <div className="row justify-content-start ml-3">
                                        <div className="col-6  text-center">
                                          Package Name:
                                        </div>
                                        <div className="col-6  text-left">
                                          {data.package_name}
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      key={
                                        i + data.package_id + i + Math.random()
                                      }
                                      style={{
                                        // borderBottom: "1px solid #B3CDFF",
                                        padding: "10px",
                                      }}
                                      // className="admin-product-row-hided-col-price"
                                    >
                                      <div className="row justify-content-start ml-3">
                                        <div className="col-6  text-center">
                                          Package Type:
                                        </div>
                                        <div className="col-6  text-left">
                                          {data.package_type}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                              <img src={emptyPackage} alt="empty-labs" />
                              <p
                                style={{
                                  color: "#D2D2D2",
                                  padding: "20px",
                                  fontSize: "20px",
                                  fontFamily: "mediumFont",
                                }}
                              >
                                Package not found
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    className={
                      allpackagesData.length
                        ? "p-3 d-flex flex-column flex-sm-row justify-content-end"
                        : "p-3 d-none flex-column flex-sm-row justify-content-end"
                    }
                  >
                    <div className="button__container d-flex align-items-center justify-content-end">
                      <Button
                        variant="contained"
                        className="primary__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          padding: "10px 25px",
                          width: "250px",
                          color: "#fff",
                        }}
                        onClick={formik.handleSubmit}
                      >
                        {false ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Add Package"
                        )}
                      </Button>
                    </div>
                  </div>
                </section>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

const EditLabs = ({ editable, lab_id }) => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      "& .MuiTabs-indicator": {
        backgroundColor: "#1565d8",
        height: 3,
        top: "0px",
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#1565d8",
        backgroundColor: "#fff",
      },
    },
  }));
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const [classAccess, setClassAccess] = useState({});
  const [category, setCategory] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [testType, setTestType] = useState([]);
  const [allTestType, setAllTestType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState("");
  const [categorypage, setCategoryPage] = useState(0);
  const [typepage, setTypePage] = useState(0);
  // const
  const [categoryrowsPerPage, setCategoryRowsPerPage] = useState(5);
  const [typerowsPerPage, setTypeRowsPerPage] = useState(5);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [labData, setLabData] = useState([]);
  const [alltestData, setTestData] = useState([]);
  const [allpackagesData, setPackageData] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const [selectedPackages, setSelectedPackage] = useState([]);
  const [privilegeData, setPrivilegeData] = useState([]);
  const [allTest, setallTest] = useState(false);
  const [allTests, setallTests] = useState([]);
  const [allPackages, setallPackages] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const privilege = axios.post(
          Global.base_url + "/lab/check/privileges",
          null,
          config
        );
        const labData = axios.post(
          Global.base_url + "/lab/get/id",
          { lab_id },
          config
        );

        const [res1, res2, res3] = await axios.all([privilege, labData]);
        // console.log(response);

        if (res1.status === 200) {
          setPrivilegeData(res1.data.privileges);
        }
        if (res2.status === 200) {
          setallTest(res2.data.data[0].all_test ? true : false);

          setLabData(res2.data.data[0]);
          setSelectedTest(res2.data.data[0].tests);
          setSelectedPackage(res2.data.data[0].packages);
        }

        setLoading(false);
      } catch (error) {
        console.log(error.response);

        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get test access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      // provilege check api
    };
    getData();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <section className="tabs__wrapper mt-2">
          <div
            className={classes.root}
            classes={{ indicator: classes.indicator }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
            >
              <Tab label="Details" disableRipple={true} {...a11yProps(0)} />
              {privilegeData &&
              _.some(privilegeData, ["privilege_name", "LAB TEST"]) ? (
                <Tab label="Tests" disableRipple={true} {...a11yProps(1)} />
              ) : null}
              {privilegeData &&
              _.some(privilegeData, ["privilege_name", "LAB PACKAGE"]) ? (
                <Tab label="Packages" disableRipple={true} {...a11yProps(2)} />
              ) : null}
            </Tabs>
          </div>
          <div className="tabItems__container">
            <TabPanel value={selectedTab} index={0}>
              <AddHospital editable={true} lab_id={lab_id} data={labData} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <AddTests lab_id={lab_id} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <AddPackages lab_id={lab_id} />
            </TabPanel>
          </div>
        </section>
      )}
    </div>
  );
};

export default function LabView(props) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const editable = location.state.editable;

  const lab_id = editable ? location.state.data.lab_id : null;

  return (
    <div>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <>
          <div
            className="col-12 bg-white align-items-center pt-3 pb-3"
            style={{
              borderBottom: "1px solid #B3CDFF",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                history.replace("/admin/labs");
              }}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <FiArrowLeft />
              </IconContext.Provider>
            </IconButton>
            <p
              style={{
                cursor: "pointer",
                fontSize: "25px",
                marginLeft: "5px",
              }}
              className="semiFont"
            >
              {editable
                ? `Hospital ID : ${location.state.data.lab_id}`
                : "Add Hospital"}
            </p>
          </div>

          {editable ? (
            <EditLabs editable={editable} lab_id={lab_id} />
          ) : (
            <AddHospital editable={editable} lab_id={null} />
          )}
        </>
      )}
    </div>
  );
}
