import { Button, IconButton, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BiEditAlt } from "react-icons/bi";
import { MdLocalHospital } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
// import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
// import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
// import { MdKeyboardBackspace } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import emptyUser from "../assets/images/empty-user.png";
import moment from "moment";
import csc from "country-state-city";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router";
import { BsSliders } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import DeleteModal from "./DeleteModal";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AiOutlineDelete } from "react-icons/ai";

import {
  MuiPickersUtilsProvider,
  //   KeyboardTimePicker,
  //   KeyboardDatePicker,
  DatePicker,
  //   KeyboardDateTimePicker,
} from "@material-ui/pickers";
const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const durationTypeOptions = [
  {
    value: "hrs",
    label: "hrs",
  },
  {
    value: "min",
    label: "min",
  },
];

// add button icon alignment design
const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 15,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Events = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // console.log("AdminProduct", props);
  //   const [period, setPeriod] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [countries, setCountries] = useState([{ name: "Choose Country" }]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userData, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userAccess, setUserAccess] = useState({});
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState("");

  const [editUser, setEdit] = useState({
    data: {},
    index: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedData, setSelectedData] = useState({
    data: {},
    index: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterData, setFilterData] = useState({});
  const [paginationData, setPaginationData] = useState({});
  const [eventOptions, setEventOptions] = useState([]);

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // table pagination handlechange for number of pages
  const handleChangePage = (event, newPage) => {
    getEvents(newPage, rowsPerPage);
  };

  // number of rows handle change for items display
  const handleChangeRowsPerPage = async (event) => {
    getEvents(page, event.target.value);
  };

  useEffect(() => {
    const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Active" ? 1 : 0;
      });
    async function fetchEvents() {
      try {
        const checkUser = await axios.post(
          Global.base_url + "/events/check/privileges",
          null,
          config
        );

        if (checkUser.status === 200) {
          setUserAccess(checkUser.data);
          if (
            _.some(checkUser.data.privileges, ["privilege_name", "VIEW EVENTS"])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/events",
                {
                  currentPage: page + 1,
                  pageSize: rowsPerPage,
                  search: search,
                  status: submitStatus.length ? submitStatus : [],
                },
                config
              );

              if (response.status === 200) {
                setEvents(response.data.data);
                let sortedStatus = Object.keys(response.data.filter.status).map(
                  (item) => {
                    return {
                      name: item,
                    };
                  }
                );
                setFilterData({
                  status: sortedStatus,
                });
                setPaginationData(response.data.pagination);
                setPage(response.data.pagination.currentPage - 1);
                setRowsPerPage(response.data.pagination.pageSize);
                return setLoading(false);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error(error.response.data.msg);
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
              return setLoading(false);
            }
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get event access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchEvents();
  }, [refresh]);

  useEffect(() => {
    fetchEventTypes();
  }, []);

  const fetchEventTypes = async () => {
    try {
      const eventResponse = await axios.get(
        Global.base_url + "/events/types",
        config
      );
      if (eventResponse.status === 200) {
        const modifiedData = eventResponse.data.data.map((item) => {
          return {
            label: item.name,
            value: item.type_id,
          };
        });

        setEventOptions(modifiedData);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
        return toast.error(error.response.data.msg);
      }
    }
  };

  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };

  const getEvents = async (pageData, rowsPerPageData) => {
    const submitStatus = selectedStatus.map((item) => {
      return item === "Active" ? 1 : 0;
    });

    try {
      const response = await axios.post(
        Global.base_url + "/events",
        {
          currentPage: pageData + 1,
          pageSize: rowsPerPageData,
          search: search,
          status: submitStatus.length ? submitStatus : [],
        },
        config
      );

      if (response.status === 200) {
        setEvents(response.data.data);
        setPaginationData(response.data.pagination);
        setPage(response.data.pagination.currentPage - 1);
        setRowsPerPage(response.data.pagination.pageSize);
        setAnchorEl(null);

        let sortedStatus = Object.keys(response.data.filter.status).map(
          (item) => {
            return {
              name: item,
              // checked: false,
            };
          }
        );

        setFilterData({
          status: sortedStatus,
        });

        // setPaginationData(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get events");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  // initial values for input fields
  const initialValues =
    // condition for add or edit modal
    // editUser index indicates to existing products
    editUser.index !== ""
      ? {
          id: editUser.data.event_id,
          name: editUser.data.name,
          duration: editUser.data.duration,
          duration_type: {
            label: editUser.data.duration_type,
            value: editUser.data.duration_type,
          },
          event_type: {
            label: eventOptions.filter(
              (list) => list.value === editUser.data.event_type
            )[0].label,
            value: editUser.data.event_type,
          },

          status: {
            label: editUser.data.status ? "Active" : "Inactive",
            value: editUser.data.status,
          },
        }
      : {
          name: "",
          duration: "",
          duration_type: null,
          event_type: null,
        };

  function MyVerticallyCenteredModal({
    editUser,
    setRefresh,
    initialValues,
    handleModal,
    setEdit,
  }) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const config2 = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const [submitLoader, setSubmitLoader] = useState(false);

    const required = <span style={{ color: "red" }}>*</span>;
    function closeModal() {
      setEdit({
        data: "",
        index: "",
      });
      setShowEditModal(false);
    }

    return (
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              {editUser.index !== "" ? "Edit Event" : "Add Event"}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={yup.object().shape({
              name: yup.string().required("name is required"),
              duration: yup.string().required("Duration is required"),
              duration_type: yup
                .object()
                .required("Please select duration type")
                .nullable(),
              event_type: yup
                .object()
                .required("Please select event type")
                .nullable(),
            })}
            onSubmit={async (values, formik) => {
              setSubmitLoader(true);

              const url =
                editUser.index !== ""
                  ? Global.base_url + "/events/update"
                  : Global.base_url + "/events/add";

              const data = {
                name: values.name,
                duration: values.duration,
                duration_type: values.duration_type.value,
                event_type: values.event_type.value,
              };

              if (editUser.index !== "") {
                data.event_id = values.id;
                data.status = values.status.value;
              }

              try {
                const response = await axios.post(url, data, config2);
                if (response.status === 200) {
                  setSubmitLoader(false);
                  closeModal();
                  editUser.index !== ""
                    ? toast.success("Event Updated Successfully")
                    : toast.success("Event Added Successfully");
                  return setRefresh(Date.now() + 1);
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  toast.error(error.response.data.msg);
                }
                if (error.response && error.response.status === 500) {
                  toast.error("Internal server error");
                }
                return setSubmitLoader(false);
              }
            }}
          >
            {(formik) => {
              return (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="name"
                            className="semiFont login__label"
                          >
                            Event Name {required}
                          </label>
                          <input
                            type="text"
                            placeholder="Event Name"
                            name="name"
                            // className="form-control signup__input"
                            value={formik.values.name}
                            style={{
                              border: formik.errors.name
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "name",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                          />
                          {formik.errors.name && (
                            <div className="err">
                              <p>{formik.errors.name}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="last-name"
                            className="semiFont login__label"
                          >
                            Event Type {required}
                          </label>
                          <Select
                            value={formik.values.event_type}
                            onChange={(p) => {
                              formik.setFieldValue("event_type", p);
                            }}
                            id="inputDropdown"
                            placeholder="Select Event Type"
                            options={eventOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "8px",
                                border: formik.errors.event_type
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.event_type && (
                            <div className="err">
                              <p>{formik.errors.event_type}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <div className="label__row d-flex align-items-center justify-content-between">
                            <label
                              htmlFor="duration"
                              className="semiFont login__label"
                            >
                              Duration {required}
                            </label>
                          </div>
                          <input
                            type="email"
                            id="duration"
                            name="duration"
                            placeholder="Duration"
                            // className="form-control signup__input"
                            value={formik.values.duration}
                            style={{
                              border: formik.errors.duration
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "duration",
                                e.currentTarget.value.replace(/[^0-9]/g, "")
                              )
                            }
                          />
                          {formik.errors.duration && (
                            <div className="err">
                              <p>{formik.errors.duration}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="gender"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Duration Type {required}
                          </label>
                          <Select
                            value={formik.values.duration_type}
                            onChange={(p) => {
                              formik.setFieldValue("duration_type", p);
                            }}
                            id="inputDropdown"
                            placeholder="Select Duration Type"
                            options={durationTypeOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "8px",
                                border: formik.errors.duration_type
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.duration_type && (
                            <div className="err">
                              <p>{formik.errors.duration_type}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {editUser.index !== "" ? (
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="Status"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Status{required}
                            </label>
                            <Select
                              value={formik.values.status}
                              name="Status"
                              onChange={(p) => {
                                formik.setFieldValue("status", p);
                                // console.log("type ", p.value);
                              }}
                              id="inputDropdown"
                              placeholder="Select Status"
                              options={statusOptions}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.status
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.status && (
                              <div className="err">
                                <p>{formik.errors.status}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div
                      className={
                        "mt-3 d-flex flex-column flex-sm-row justify-content-end"
                      }
                    >
                      <div className="d-flex flex-sm-row addproduct__button--aligncenter">
                        <div className="form-group ">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={!formik.dirty || submitLoader}
                            style={{
                              backgroundColor: !formik.dirty
                                ? "#777"
                                : "#1565d8",
                              color: "#fff",
                              padding: submitLoader ? "7px 40px" : "10px 15px",
                              // width: "150px",
                              textTransform: "initial",
                              marginRight: "10px",
                            }}
                          >
                            {submitLoader ? (
                              <div
                                className="spinner-border text-light"
                                // style={{}}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Update/Save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <MyVerticallyCenteredModal
            editUser={editUser}
            initialValues={initialValues}
            setRefresh={setRefresh}
            handleModal={props.handleModal}
            setEdit={setEdit}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          />
          <DeleteModal
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            selectedData={editUser.data?.event_id}
            refresh={setRefresh}
            apiUrl={"/events/delete"}
            name={"Event"}
            // id={"category_id"}
          />
          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Events
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  placeholder="Search Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    // detects Enter key or numberpad enter key
                    if (e.code === "Enter") {
                      getEvents(page, rowsPerPage);
                    }
                  }}
                />
              </div>
              <div
                className="filter__container"
                style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
              >
                <div
                  className="filter__icon p-3"
                  onClick={filterClick}
                  style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
                >
                  {selectedStatus.length !== 0 ? (
                    <Badge badgeContent={selectedStatus.length} color="primary">
                      <IconContext.Provider
                        value={{
                          color: "#333",
                          size: "25px",
                        }}
                      >
                        <BsSliders />
                      </IconContext.Provider>
                    </Badge>
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: "#333",
                        size: "25px",
                      }}
                    >
                      <BsSliders />
                    </IconContext.Provider>
                  )}
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      // width: "45%",
                      backgroundColor: "#f3f3f3",
                      // display: "flex",
                      // justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="pop__container">
                    {selectedStatus.length ? (
                      <div className="selected__items px-4 pt-4 d-flex align-items-center semiFont">
                        <div className="batch__item d-flex align-items-center">
                          <p className="mr-2 mt-1">{selectedStatus.length}</p>
                          <div
                            className="icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedStatus([]);
                            }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#fff",
                                size: "20px",
                              }}
                            >
                              <CgClose />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <p className="ml-2">Filteres are selected</p>
                      </div>
                    ) : null}

                    <div className="row px-4 pb-4 pt-4 justify-content-lg-between">
                      <div className="col">
                        <p className="semiFont pop__header">Status</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.status &&
                            filterData.status.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedStatus.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedStatus([
                                            ...selectedStatus,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedStatus(
                                            selectedStatus &&
                                              selectedStatus.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="carbon__buttoncontainer">
                      {/* {location.state.editable ? ( */}
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => {
                          setSelectedStatus([]);
                        }}
                      >
                        <p>Reset Filter</p>
                      </Button>
                      {/* ) : null} */}

                      <Button
                        variant="contained"
                        className="carbon__primarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          color: "#fff",
                        }}
                        onClick={() => getEvents(page, rowsPerPage)}
                      >
                        <p className="carbon__text">Apply Filter</p>
                        {/* Yes */}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* </> */}
              </div>
              {/* {} */}
              {userAccess &&
              _.some(userAccess.privileges, [
                "privilege_name",
                "ADD EVENTS",
              ]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  style={{ width: "250px" }}
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    setEdit({
                      data: "",
                      index: "",
                    });
                    setShowEditModal(true);
                  }}
                >
                  Add Event
                </Button>
              ) : null}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Duration
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>

                  {(userAccess &&
                    _.some(userAccess.privileges, [
                      "privilege_name",
                      "EDIT EVENTS",
                    ])) ||
                  _.some(userAccess.privileges, [
                    "privilege_name",
                    "DELETE EVENTS",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {events.length ? (
                  events.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;

                    return (
                      <>
                        <tr
                          key={data.event_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            EID{data.event_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {data.name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.duration} {data.duration_type}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.status ? "Active" : "Inactive"}
                          </td>

                          {userAccess &&
                          (_.some(userAccess.privileges, [
                            "privilege_name",
                            "EDIT EVENTS",
                          ]) ||
                            _.some(userAccess.privileges, [
                              "privilege_name",
                              "DELETE EVENTS",
                            ])) ? (
                            <td
                              className="admin-product-row-action-col py-3 "
                              // colSpan={2}
                              style={rowdataAlign}
                            >
                              <div className="iconButtons__container justify-content-center">
                                {_.some(userAccess.privileges, [
                                  "privilege_name",
                                  "EDIT EVENTS",
                                ]) ? (
                                  <Tooltip
                                    title={"Edit"}
                                    placement="top-center"
                                  >
                                    <IconButton
                                      className="edit__IconButton"
                                      onClick={() => {
                                        setEdit({
                                          data: data,
                                          index: i,
                                        });
                                        setShowEditModal(true);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          color: "#1565d8",
                                          size: "30px",
                                        }}
                                      >
                                        <BiEditAlt />
                                      </IconContext.Provider>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                {_.some(userAccess.privileges, [
                                  "privilege_name",
                                  "DELETE EVENTS",
                                ]) ? (
                                  <Tooltip
                                    title={"Remove"}
                                    placement="top-center"
                                  >
                                    <IconButton
                                      className="delete__IconButton"
                                      onClick={() => {
                                        setEdit({
                                          data: data,
                                          index: i,
                                        });
                                        setDeleteModal(true);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          color: "#cc0000",
                                          size: "30px",
                                        }}
                                      >
                                        <AiOutlineDelete />
                                      </IconContext.Provider>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.event_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul
                              className="admin-product-row-table-hide-list text-left mediumFont"
                              style={{ color: "#525252" }}
                            >
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Duration
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.duration} {data.duration_type}
                                  </div>
                                </div>
                              </li>

                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.status ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </li>

                              {userAccess &&
                              (_.some(userAccess.privileges, [
                                "privilege_name",
                                "EDIT EVENTS",
                              ]) ||
                                _.some(userAccess.privileges, [
                                  "privilege_name",
                                  "DELETE EVENTS",
                                ])) ? (
                                <li
                                  key={data.package_id + Math.random()}
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center iconButtons__container">
                                      {_.some(userAccess.privileges, [
                                        "privilege_name",
                                        "EDIT EVENTS",
                                      ]) ? (
                                        <Tooltip
                                          title={"Edit"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="edit__IconButton"
                                            onClick={() => {
                                              history.push({
                                                pathname: "/admin/doctor-view",
                                                state: {
                                                  editable: true,
                                                  data: data,
                                                  userAccess: userAccess,
                                                },
                                              });
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "30px",
                                              }}
                                            >
                                              <BiEditAlt />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                      {_.some(userAccess.privileges, [
                                        "privilege_name",
                                        "DELETE EVENTS",
                                      ]) ? (
                                        <Tooltip
                                          title={"Remove"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="delete__IconButton"
                                            onClick={() => {
                                              setEdit({
                                                data: data,
                                                index: i,
                                              });
                                              setDeleteModal(true);
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#cc0000",
                                                size: "30px",
                                              }}
                                            >
                                              <AiOutlineDelete />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyUser} alt="empty-user" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {userAccess &&
                      _.some(userAccess.privileges, [
                        "privilege_name",
                        "VIEW EVENTS",
                      ])
                        ? "Events not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {paginationData && paginationData.totalData > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={paginationData ? paginationData.totalData : 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Events;
