import { Button, IconButton, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
import { CgClose } from "react-icons/cg";
// import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
// import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
// import { MdKeyboardBackspace } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import emptyUser from "../assets/images/empty-user.png";
import { BsSliders } from "react-icons/bs";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";

import DeleteModal from "./DeleteModal";
const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

// add button icon alignment design
const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 15,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Members = (props) => {
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // console.log("AdminProduct", props);
  //   const [period, setPeriod] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  // const [allMembers, setAllMembers] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [memberData, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [memberAccess, setMemberAccess] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);
  const [labOptions, setLabOptions] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  // console.log("loading", loading);
  const [refresh, setRefresh] = useState("");

  const [editMember, setEdit] = useState({
    data: {},
    index: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({});

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedActivation, setSelectedActivation] = useState([]);

  // console.log("aselectedTypenchor", [selectedStatus, selectedType]);

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // table pagination handlechange for number of pages
  const handleChangePage = (event, newPage) => {
    getMembers(newPage, rowsPerPage);
  };

  // number of rows handle change for items display
  const handleChangeRowsPerPage = async (event) => {
    getMembers(page, event.target.value);
  };

  useEffect(() => {
    const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Active" ? 1 : 0;
      });
    const submitActivation =
      selectedActivation &&
      selectedActivation.map((item) => {
        return item == "Active" ? 1 : 0;
      });
    async function fetchMembers() {
      try {
        const checkUser = await axios.post(
          Global.base_url + "/member/check/privileges",
          null,
          config
        );

        if (checkUser.status === 200) {
          console.log("checkUser", checkUser);
          setMemberAccess(checkUser.data);
          if (
            _.some(checkUser.data.privileges, [
              "privilege_name",
              "VIEW MEMBERS",
            ])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/member",
                {
                  currentPage: page + 1,
                  pageSize: rowsPerPage,
                  search: search,
                  activated: submitActivation.length ? submitActivation : [],
                  status: submitStatus.length ? submitStatus : [],
                },
                config
              );

              // console.log(response);

              if (response.status === 200) {
                // setLoading(false);

                setMembers(response.data.data);

                let sortedStatus = Object.keys(response.data.filter.status).map(
                  (item) => {
                    return {
                      name: item,
                      // checked: false,
                    };
                  }
                );

                let sortedActivation = Object.keys(
                  response.data.filter.activated
                ).map((item) => {
                  return {
                    name: item,
                    // checked: false,
                  };
                });
                setFilterData({
                  status: sortedStatus,
                  activation: sortedActivation,
                });
                setPaginationData(response.data.pagination);
                setPage(response.data.pagination.currentPage - 1);
                setRowsPerPage(response.data.pagination.pageSize);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error("Unable to get members");
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }

            try {
              const getRoles = await axios.post(
                Global.base_url + "/member/getRoles",
                null,
                config
              );

              if (getRoles.status === 200) {
                console.log("user getRoles", getRoles);
                let getRoleOptions = getRoles.data.data.map((item) => {
                  return {
                    value: item.role_id,
                    label: `${item.role_name} (RID${item.role_id})`,
                  };
                });

                setRoleOptions(getRoleOptions);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error("Unable to get roles");
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }

            const labItems = {
              all_cities: true,
              cities: [],
            };
            try {
              const getLabData = await axios.post(
                Global.base_url + "/package/get/labs",
                labItems,
                config
              );

              console.log("getLabData", getLabData);

              if (getLabData.status === 200) {
                let getLabOptions = getLabData.data.labs.map((item) => {
                  return {
                    value: item.lab_id,
                    label: `${item.lab_name} (LID${item.lab_id})`,
                  };
                });
                setLabOptions(getLabOptions);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error("Unable to get labs");
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }
        }

        return setLoading(false);
      } catch (error) {
        if (error.checkUser) {
          if (error.checkUser.status === 401) {
            return toast.error("Unable to get member access");
          }
          if (error.checkUser.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchMembers();
  }, [refresh]);

  console.log("roleOptions", roleOptions);
  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };

  const getMembers = async (pageData, rowsPerPageData) => {
    const submitStatus = selectedStatus.map((item) => {
      return item === "Active" ? 1 : 0;
    });

    const submitActivation =
      selectedActivation &&
      selectedActivation.map((item) => {
        return item === "active" ? 1 : 0;
      });
    try {
      const response = await axios.post(
        Global.base_url + "/member",
        {
          currentPage: pageData + 1,
          pageSize: rowsPerPageData,
          search: search,
          activated: submitActivation.length ? submitActivation : [],
          status: submitStatus.length ? submitStatus : [],
        },
        config
      );
      // console.log(response);

      if (response.status === 200) {
        // console.log("get test data", response.data);

        setMembers(response.data.data);
        setPaginationData(response.data.pagination);
        setPage(response.data.pagination.currentPage - 1);
        setRowsPerPage(response.data.pagination.pageSize);
        setAnchorEl(null);

        let sortedStatus = Object.keys(response.data.filter.status).map(
          (item) => {
            return {
              name: item,
              // checked: false,
            };
          }
        );

        let sortedActivation = Object.keys(response.data.filter.activated).map(
          (item) => {
            return {
              name: item,
              // checked: false,
            };
          }
        );
        setFilterData({
          status: sortedStatus,
          activation: sortedActivation,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get members");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  const getEditData = async (data, i) => {
    console.log("edit data", data);
    try {
      const editResponse = await axios.post(
        Global.base_url + "/member/editById",
        {
          admin_id: data.admin_id,
        },
        config
      );
      // console.log(response);

      if (editResponse.status === 200) {
        // setLoading(false);

        console.log("editResponse", editResponse);
        setEdit({
          data: editResponse.data.data[0],
          index: i,
        });
        // props.setMemberss(data);
      }
      // return setLoading(false);
    } catch (error) {
      if (error.editResponse) {
        if (error.editResponse.status === 401) {
          return toast.error("Unable to get edit roles");
        }
        if (error.editResponse.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  console.log("editMember", labOptions);

  // initial values for input fields
  const initialValues =
    // condition for add or edit modal
    // editMember index indicates to existing products
    editMember.index !== ""
      ? {
          admin_id: editMember.data.admin_id,
          firstname: editMember.data.firstname,
          lastname: editMember.data.lastname,
          email: editMember.data.email,
          role_id: {
            label: `${editMember.data.role_name} (RID${editMember.data.role_id})`,
            value: editMember.data.role_id,
          },
          // labs: editMember?.data?.hospitals?.split(",") || [],
          labs: labOptions?.filter((td) => {
            let selectedIDs =
              editMember?.data?.hospitals?.split(",")?.map(Number) || [];
            return selectedIDs.includes(td?.value);
          }),
          // labs: editMember?.data?.hospitals?.split(",").map((labId) => ({
          //   label: `${editMember.data.hospitals} (LID${labId.trim()})`,
          //   value: labId.trim(),
          // })),
          status: {
            label: editMember.data.status ? "Active" : "Inactive",
            value: editMember.data.status,
          },
        }
      : {
          // product_id: "",
          firstname: "",
          lastname: "",
          role_id: null,
          labs: [],
          email: "",
          status: null,
          //   product_yellowcolortest: "",
        };

  function MyVerticallyCenteredModal({
    editMember,
    setRefresh,
    initialValues,
    handleModal,
    setEdit,
    modalStatus,
  }) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const config2 = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const [uploader, setUploader] = useState(false);
    const [resetLoader, setResetLoader] = useState(false);
    const required = <span style={{ color: "red" }}>*</span>;
    console.log("editMember", editMember);
    function closeModal() {
      setEdit({
        data: "",
        index: "",
      });
      setShowEditModal(false);
    }
    const deleteProduct = async () => {
      try {
        const response = await axios.post(
          Global.base_url + "/member/delete",
          {
            admin_id: editMember.data.admin_id,
          },
          config2
        );

        // console.log(response);

        if (response.status === 200) {
          await closeModal();
          toast.success("Member Deleted Successfully");

          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Member Deletion failed");
        }

        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
        // return console.log("admin delete fetch error", error);
      }
    };

    const resetPassword = async (admin_id) => {
      setResetLoader(true);
      try {
        const response = await axios.post(
          Global.base_url + "/member/resetPassword",
          {
            admin_id: admin_id,
          },
          config2
        );

        // console.log(response);

        if (response.status === 200) {
          setResetLoader(false);
          await closeModal();
          toast.success("Reset link sent to E-mail");

          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        setResetLoader(false);
        if (error.response && error.response.status === 401) {
          toast.error("Reset failed");
        }

        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
        // return console.log("admin delete fetch error", error);
      }
    };

    return (
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              {editMember.index !== "" ? "Edit Member" : "Add Member"}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={
              editMember.index !== ""
                ? yup.object().shape({
                    firstname: yup
                      .string()
                      .required("Firstname cannot be empty"),
                    lastname: yup.string().required("Lastname cannot be empty"),
                    role_id: yup
                      .object()
                      .required("Please select role")
                      .nullable(),

                    labs: yup
                      .array()
                      .min(1, "select atleast 1 hospitals")
                      .required("Please select hospitals"),

                    status: yup
                      .object()
                      .required("Please select status")
                      .nullable(),
                  })
                : yup.object().shape({
                    firstname: yup
                      .string()
                      .required("Firstname cannot be empty"),
                    lastname: yup.string().required("Lastname cannot be empty"),
                    role_id: yup
                      .object()
                      .required("Please select role")
                      .nullable(),
                    email: yup
                      .string()
                      .email("Invalid Email")
                      .required("Email cannot be empty"),
                    status: yup
                      .object()
                      .required("Please select status")
                      .nullable(),
                  })
            }
            onSubmit={async (values, formik) => {
              setUploader(true);
              // console.log(values);
              const url =
                editMember.index !== ""
                  ? Global.base_url + "/member/update"
                  : Global.base_url + "/member/add";

              const data =
                editMember.index !== ""
                  ? {
                      firstname: values.firstname,
                      lastname: values.lastname,
                      admin_id: editMember.data.admin_id,
                      role_id: values.role_id.value,
                      status: values.status.value,
                      hospitals: values.labs.map((lab) => lab.value).join(","),
                    }
                  : {
                      ...values,
                      role_id: values.role_id.value,
                      status: values.status.value,
                      hospitals: values.labs.map((lab) => lab.value).join(","),
                    };
              // return;
              try {
                const response = await axios.post(url, data, config2);
                // console.log(response);
                if (response.status === 200) {
                  setUploader(false);
                  await closeModal();
                  editMember.index !== ""
                    ? toast.success("Member Updated Successfully")
                    : toast.success("Member Added Successfully");
                  //   formik.resetForm();
                  return setRefresh(Date.now() + 1);
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  editMember.index !== ""
                    ? toast.error("Member Updated failed")
                    : toast.error("Member is not added");
                  return setUploader(false);
                }
                if (error.response && error.response.status === 500) {
                  toast.error("Internal server error");
                  return setUploader(false);
                }
              }
            }}
          >
            {(formik) => {
              console.log(formik.values.labs);
              return (
                <>
                  <div className="container p-4">
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="first-name"
                            className="semiFont login__label"
                          >
                            First Name {required}
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            placeholder="First Name"
                            // className="form-control signup__input"
                            value={formik.values.firstname}
                            style={{
                              border: formik.errors.firstname
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "firstname",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                          />
                          {formik.errors.firstname && (
                            <div className="err">
                              <p>{formik.errors.firstname}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="last-name"
                            className="semiFont login__label"
                          >
                            Last Name {required}
                          </label>
                          <input
                            type="text"
                            className="form-control addproduct--input"
                            id="last-name"
                            placeholder="Last Name"
                            value={formik.values.lastname}
                            style={{
                              border: formik.errors.lastname
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "lastname",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                          />
                          {formik.errors.lastname && (
                            <div className="err">
                              <p>{formik.errors.lastname}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <div className="label__row d-flex align-items-center justify-content-between">
                            <label
                              htmlFor="Email"
                              className="semiFont login__label"
                            >
                              Email {editMember.index !== "" ? null : required}
                            </label>
                            {editMember.index !== "" ? (
                              <p
                                className="member__status semiFont"
                                style={{
                                  color: editMember.data.activated
                                    ? "#03A33B"
                                    : "#ffb100",
                                }}
                              >
                                {editMember.data.activated
                                  ? "Verified"
                                  : "Pending"}
                              </p>
                            ) : null}
                          </div>
                          <input
                            type="email"
                            id="Email"
                            placeholder="Email"
                            // className="form-control signup__input"
                            value={formik.values.email}
                            disabled={editMember.index !== "" ? true : false}
                            style={{
                              border: formik.errors.email
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                              backgroundColor:
                                editMember.index !== ""
                                  ? "rgb(223 223 223)"
                                  : "#fff",
                            }}
                            onChange={formik.handleChange("email")}
                          />
                          {formik.errors.email && (
                            <div className="err">
                              <p>{formik.errors.email}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="select-role"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Select Role{required}
                          </label>
                          <Select
                            value={formik.values.role_id}
                            name="select-role"
                            onChange={(p) => {
                              formik.setFieldValue("role_id", p);
                              // console.log("type ", p.value);
                            }}
                            id="inputDropdown"
                            placeholder="Select Role"
                            options={roleOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.role_id
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.role_id && (
                            <div className="err">
                              <p>{formik.errors.role_id}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="Status"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Status{required}
                          </label>
                          <Select
                            value={formik.values.status}
                            name="Status"
                            onChange={(p) => {
                              formik.setFieldValue("status", p);
                              // console.log("type ", p.value);
                            }}
                            id="inputDropdown"
                            placeholder="Select Status"
                            options={statusOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.status
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.status && (
                            <div className="err">
                              <p>{formik.errors.status}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-12 col-lg-12">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="select-role"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Select Hospitals{required}
                          </label>
                          <Select
                            value={formik.values.labs}
                            name="select-lab"
                            onChange={(selectedOptions) => {
                              formik.setFieldValue("labs", selectedOptions);
                            }}
                            id="inputDropdown"
                            placeholder="Select Hospitals"
                            options={labOptions}
                            isMulti
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.role_id
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.labs && (
                            <div className="err">
                              <p>{formik.errors.labs}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carbon__buttoncontainer">
                    {/* {location.state.editable ? ( */}
                    {editMember.index !== "" ? (
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        disabled={resetLoader}
                        onClick={() => {
                          resetPassword(editMember.data.admin_id);
                        }}
                      >
                        {resetLoader ? (
                          <div
                            className="spinner-border text-light"
                            // style={{}}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Reset Password"
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => setShowEditModal(false)}
                      >
                        <p>Cancel</p>
                      </Button>
                    )}

                    {/* ) : null} */}

                    <Button
                      variant="contained"
                      className="carbon__primarybtn carbon__btn"
                      id="admin-reportview-download-btn"
                      style={{
                        //   padding: "10px 25px",

                        color: "#fff",
                      }}
                      disabled={!formik.dirty || uploader}
                      onClick={formik.handleSubmit}
                    >
                      {uploader ? (
                        <div
                          className="spinner-border text-light"
                          // style={{}}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Update/Save"
                      )}
                      {/* Yes */}
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <DeleteModal
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            selectedData={editMember.data.admin_id}
            refresh={setRefresh}
            apiUrl={"/member/delete"}
            name={"Member"}
            // id={"category_id"}
          />
          <MyVerticallyCenteredModal
            editMember={editMember}
            initialValues={initialValues}
            setRefresh={setRefresh}
            handleModal={props.handleModal}
            setEdit={setEdit}
            // modalStatus={props.modalStatus}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          />
          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Members
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search ID, Name, Test for"
                  onKeyDown={(e) => {
                    // detects Enter key or numberpad enter key
                    if (e.code === "Enter") {
                      getMembers(page, rowsPerPage);
                    }
                  }}
                />
              </div>
              <div
                className="filter__container"
                style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
              >
                <div
                  className="filter__icon p-3"
                  onClick={filterClick}
                  style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
                >
                  {selectedActivation.length + selectedStatus.length !== 0 ? (
                    <Badge
                      badgeContent={
                        selectedActivation.length + selectedStatus.length
                      }
                      color="primary"
                    >
                      <IconContext.Provider
                        value={{
                          color: "#333",
                          size: "25px",
                        }}
                      >
                        <BsSliders />
                      </IconContext.Provider>
                    </Badge>
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: "#333",
                        size: "25px",
                      }}
                    >
                      <BsSliders />
                    </IconContext.Provider>
                  )}
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      // width: "45%",
                      backgroundColor: "#f3f3f3",
                      // display: "flex",
                      // justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="pop__container">
                    {selectedActivation.length + selectedStatus.length !== 0 ? (
                      <div className="selected__items px-4 pt-4 d-flex align-items-center semiFont">
                        <div className="batch__item d-flex align-items-center">
                          <p className="mr-2 mt-1">
                            {selectedActivation.length + selectedStatus.length}
                          </p>
                          <div
                            className="icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedActivation([]);
                              setSelectedStatus([]);
                            }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#fff",
                                size: "20px",
                              }}
                            >
                              <CgClose />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <p className="ml-2">Filteres are selected</p>
                      </div>
                    ) : null}

                    <div className="row px-4 pb-4 pt-4 justify-content-lg-between">
                      <div className="col">
                        <p className="semiFont pop__header">Activation</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.activation &&
                            filterData.activation.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedActivation.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedActivation([
                                            ...selectedActivation,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedActivation(
                                            selectedActivation &&
                                              selectedActivation.filter(
                                                (list) => {
                                                  return item.name !== list;
                                                }
                                              )
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div className="col">
                        <p className="semiFont pop__header">Status</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.status &&
                            filterData.status.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedStatus.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedStatus([
                                            ...selectedStatus,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedStatus(
                                            selectedStatus &&
                                              selectedStatus.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="carbon__buttoncontainer">
                      {/* {location.state.editable ? ( */}
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => {
                          setSelectedActivation([]);
                          setSelectedStatus([]);
                        }}
                      >
                        <p>Reset Filter</p>
                      </Button>
                      {/* ) : null} */}

                      <Button
                        variant="contained"
                        className="carbon__primarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "10px 25px",

                          color: "#fff",
                        }}
                        onClick={() => getMembers(page, rowsPerPage)}
                      >
                        <p className="carbon__text">Apply Filter</p>
                        {/* Yes */}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* </> */}
              </div>
              {/* {} */}
              {memberAccess &&
              _.some(memberAccess.privileges, [
                "privilege_name",
                "ADD MEMBERS",
              ]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button"
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  style={{ width: "250px" }}
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    setEdit({
                      data: "",
                      index: "",
                    });
                    setShowEditModal(true);
                  }}
                >
                  Add Member
                </Button>
              ) : null}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    Member ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Member Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Member Email
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Role Name
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Verification
                  </th>
                  {memberAccess &&
                  _.some(memberAccess.privileges, [
                    "privilege_name",
                    "EDIT MEMBERS",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {memberData.length ? (
                  memberData.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;

                    return (
                      <>
                        <tr
                          key={data.admin_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            MID{data.admin_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {" "}
                            {data.admin_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.email}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.role_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.status ? "Active" : "Inactive"}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{
                              color: data.activated ? "#03A33B" : "#ffb100",
                              ...rowdataAlign,
                            }}
                          >
                            {data.activated ? "Verified" : "Pending"}
                          </td>
                          {(memberAccess &&
                            _.some(memberAccess.privileges, [
                              "privilege_name",
                              "EDIT MEMBERS",
                            ])) ||
                          (memberAccess &&
                            _.some(memberAccess.privileges, [
                              "privilege_name",
                              "DELETE MEMBERS",
                            ])) ? (
                            <td
                              className="admin-product-row-action-col py-3 "
                              style={rowdataAlign}
                            >
                              <div className="iconButtons__container">
                                {memberAccess &&
                                _.some(memberAccess.privileges, [
                                  "privilege_name",
                                  "EDIT MEMBERS",
                                ]) ? (
                                  <Tooltip
                                    title={"Edit"}
                                    placement="top-center"
                                  >
                                    <IconButton
                                      className="edit__IconButton"
                                      onClick={() => {
                                        getEditData(data, i);

                                        setShowEditModal(true);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          color: "#1565d8",
                                          size: "30px",
                                        }}
                                      >
                                        <BiEditAlt />
                                      </IconContext.Provider>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                {memberAccess &&
                                _.some(memberAccess.privileges, [
                                  "privilege_name",
                                  "DELETE MEMBERS",
                                ]) ? (
                                  <Tooltip
                                    title={"Remove"}
                                    placement="top-center"
                                  >
                                    <IconButton
                                      className="delete__IconButton"
                                      onClick={() => {
                                        setEdit({
                                          data: data,
                                          index: i,
                                        });
                                        setDeleteModal(true);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          color: "#cc0000",
                                          size: "30px",
                                        }}
                                      >
                                        <AiOutlineDelete />
                                      </IconContext.Provider>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.admin_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul
                              className="admin-product-row-table-hide-list text-left mediumFont"
                              style={{ color: "#525252" }}
                            >
                              <li
                                key={data.admin_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Email
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.email}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.admin_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Role Name
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.role_name}
                                  </div>
                                </div>
                              </li>

                              <li
                                key={data.admin_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.status ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.admin_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Verification:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{
                                      color: data.activated
                                        ? "#03A33B"
                                        : "#ffb100",
                                    }}
                                  >
                                    {data.activated ? "Verified" : "Pending"}
                                  </div>
                                </div>
                              </li>
                              {(memberAccess &&
                                _.some(memberAccess.privileges, [
                                  "privilege_name",
                                  "EDIT MEMBERS",
                                ])) ||
                              (memberAccess &&
                                _.some(memberAccess.privileges, [
                                  "privilege_name",
                                  "DELETE MEMBERS",
                                ])) ? (
                                <li
                                  key={data.admin_id + Math.random()}
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center iconButtons__container">
                                      {memberAccess &&
                                      _.some(memberAccess.privileges, [
                                        "privilege_name",
                                        "EDIT MEMBERS",
                                      ]) ? (
                                        <Tooltip
                                          title={"Edit"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="edit__IconButton"
                                            onClick={() => {
                                              getEditData(data, i);

                                              setShowEditModal(true);
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "30px",
                                              }}
                                            >
                                              <BiEditAlt />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                      {memberAccess &&
                                      _.some(memberAccess.privileges, [
                                        "privilege_name",
                                        "DELETE MEMBERS",
                                      ]) ? (
                                        <Tooltip
                                          title={"Remove"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="delete__IconButton"
                                            onClick={() => {
                                              setEdit({
                                                data: data,
                                                index: i,
                                              });
                                              setDeleteModal(true);
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#cc0000",
                                                size: "30px",
                                              }}
                                            >
                                              <AiOutlineDelete />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyUser} alt="empty-user" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {memberAccess &&
                      _.some(memberAccess.privileges, [
                        "privilege_name",
                        "VIEW MEMBERS",
                      ])
                        ? "Members not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {paginationData && paginationData.totalData > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={paginationData ? paginationData.totalData : 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Members;
