import { Button, Tooltip } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

import "../assets/css/report.css";
// import { ProgressBar } from "react-bootstrap";
// import AdminReportView from "./AdminReportView";

import { AiOutlineCloudUpload } from "react-icons/ai";

import { FaCommentSlash } from "react-icons/fa";
import { FiArrowLeft, FiEdit, FiDownload, FiUpload } from "react-icons/fi";
import { AiTwotoneEdit, AiOutlineDelete } from "react-icons/ai";
import { Formik } from "formik";
import * as yup from "yup";
import { IconContext } from "react-icons";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import Global from "../global";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import MomentUtils from "@date-io/moment";
import { Modal } from "react-bootstrap";
import emptyComment from "../assets/images/empty-comment.png";
import emptyReport from "../assets/images/empty_report.png";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const requiredField = <span style={{ color: "red" }}>*</span>;

const BlueSwitch = withStyles({
  switchBase: {
    color: "#0F61FD",
    "&$checked": {
      color: "#0F61FD",
    },
    "&$checked + $track": {
      backgroundColor: "#0F61FD",
    },
  },
  checked: {},
  track: {},
})(Switch);

const ReportView = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const history = useHistory();
  const location = useLocation();
  const individualUploadRef = useRef([]);
  const combinedUploadRef = useRef(null);
  const [reportStatus, setReportStatus] = useState(null);
  const [reportData, setReportData] = useState("");
  const [uploadedReports, setUploadedReports] = useState({});

  // console.log("uploadedReports",uploadedReports)

  const [loading, setLoading] = useState(true);
  const [reportSwitch, setReportSwitch] = useState(false);
  const [refresh, setRefresh] = useState("");
  const [open, setOpen] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState({
    data: "",
    index: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };

  // console.log("uploadedReports", uploadedReports);

  const walkinOptions = [
    {
      value: "Awaiting Confirmation",
      label: "Awaiting Confirmation",
      isDisabled: reportData && reportData.report.appointment ? true : false,
    },
    {
      value: "Scheduled",
      label: "Scheduled",
      isDisabled: reportData && reportData.report.appointment ? true : false,
    },
    {
      value: "Sample Processing",
      label: "Sample Processing",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Rescheduled",
      label: "Rescheduled",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Completed",
      label: "Completed",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Cancelled",
      label: "Cancelled",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
  ];
  const hometestOptions = [
    {
      value: "Awaiting Confirmation",
      label: "Awaiting Confirmation",
      isDisabled: reportData && reportData.report.appointment ? true : false,
    },
    {
      value: "Scheduled",
      label: "Scheduled",
      isDisabled: reportData && reportData.report.appointment ? true : false,
    },
    {
      value: "Sample Taken",
      label: "Sample Taken",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Sample Processing",
      label: "Sample Processing",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Rescheduled",
      label: "Rescheduled",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Completed",
      label: "Completed",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
    {
      value: "Cancelled",
      label: "Cancelled",
      isDisabled: reportData && reportData.report.appointment ? false : true,
    },
  ];

  console.log("selectedComment", selectedComment);

  const handleStatus = async () => {
    try {
      const response = await axios.post(
        Global.base_url + "/report/status",
        {
          report_id: location.state.data.report_id,
          status: reportStatus,
        },
        config
      );

      if (response.status === 200) {
        toast.success("Status updated");
        // setLoading(false);
        console.log("reports", response);
        return setRefresh(Date.now() + 1);
        // setReportData(response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to add status");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  useEffect(() => {
    const getReportView = async () => {
      try {
        const response = await axios.post(
          Global.base_url + "/report/get",
          {
            report_id: location.state.data.report_id,
          },
          config
        );

        if (response.status === 200) {
          console.log("reports view", response);
          setReportData(response.data);
          if (response.data.report.pack_linked === null) {
            setReportSwitch(true);
          }
          setReportStatus(response.data.report.status);
          try {
            const reportUpload = await axios.post(
              Global.base_url + "/report/document/list",
              {
                report_id: location.state.data.report_id,
              },
              config
            );

            if (reportUpload.status === 200) {
              console.log("uploaded reponse", reportUpload);

              setUploadedReports(reportUpload.data);
            }
          } catch (error) {
            if (error.reportUpload) {
              if (error.reportUpload.status === 401) {
                return toast.error("Unable to get uploaded reports");
              }
              if (error.reportUpload.status === 500) {
                return toast.error("Internal server error");
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get report details");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    };
    getReportView();
  }, [refresh]);

  console.log("reportData", reportData);

  const downloadFile = async () => {
    try {
      const response = await axios.post(
        Global.base_url + "dashboard/admin/report/report_download",
        {
          test_id: reportData.test_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      // console.log(response);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report#${reportData.test_id}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return;
      }
    } catch (error) {
      if (error && error.response.status === 403)
        return toast.warning("No reports found");
      return console.log("failed");
    }
  };

  // selectedComment index indicates existing comments
  const initialValues =
    selectedComment.index !== ""
      ? {
          title: selectedComment.data.title,
          comment: selectedComment.data.comment,
        }
      : {
          title: "",
          comment: "",
        };

  const DeleteModal = () => {
    const deleteItem = async () => {
      try {
        const response = await axios.post(
          Global.base_url + "/report/comment/delete",
          {
            report_id: location.state.data.report_id,
            comment_id: selectedComment.data.comment_id,
          },
          config
        );
        // console.log(response);
        if (response.status === 200) {
          // setUploader(false);
          // await closeModal();
          setSelectedComment({
            data: "",
            index: "",
          });
          setDeleteModal(false);
          toast.success("Comment deleted Successfully");
          //   formik.resetForm();
          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Unable to comment role");
        }
        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
      }
    };
    return (
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              Delete
              {/* {editProduct.index !== "" ? "Edit Test" : "Add Test"} */}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" py-5 semiFont">
          Are you sure want to delete this comment ?
        </Modal.Body>
        <div className="carbon__buttoncontainer">
          {/* {location.state.editable ? ( */}
          <Button
            variant="contained"
            className="carbon__secondarybtn carbon__btn"
            id="admin-reportview-download-btn"
            style={{
              //   padding: "25px ",
              color: "#fff",
              //   border: "1px solid #1565d8",
              //   marginRight: "15px",
            }}
            onClick={() => {
              setSelectedComment({
                data: "",
                index: "",
              });
              setDeleteModal(false);
            }}
          >
            <p>Cancel</p>
          </Button>
          {/* ) : null} */}

          <Button
            variant="contained"
            className="carbon__primarybtn carbon__btn"
            id="admin-reportview-download-btn"
            style={{
              //   padding: "10px 25px",

              color: "#fff",
            }}
            onClick={deleteItem}
          >
            <p className="carbon__text">Yes</p>
            {/* Yes */}
          </Button>
        </div>
      </Modal>
    );
  };

  const CommentModal = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const required = <span style={{ color: "red" }}>*</span>;
    function closeModal() {
      setSelectedComment({
        data: "",
        index: "",
      });
      setCommentModal(false);
    }
    // console.log("delete");

    return (
      <Modal
        show={commentModal}
        onHide={() => setCommentModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              {selectedComment.index !== "" ? "Edit Comment" : "Add Comment"}
            </p>
          </Modal.Title>
        </Modal.Header>

        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            title: yup.string().required("Title cannot be empty"),
            comment: yup.string().required("Description cannot be empty"),
          })}
          onSubmit={async (values, formik) => {
            setSubmitLoading(true);
            const url =
              selectedComment.index !== ""
                ? Global.base_url + "/report/comment/update"
                : Global.base_url + "/report/comment/add";

            const data =
              selectedComment.index !== ""
                ? {
                    report_id: location.state.data.report_id,
                    title: values.title,
                    comment: values.comment,
                    comment_id: selectedComment.data.comment_id,
                  }
                : {
                    report_id: location.state.data.report_id,
                    title: values.title,
                    comment: values.comment,
                  };

            console.log("data", data);

            try {
              const response = await axios.post(url, data, config);

              // console.log(response);
              if (response.status === 200) {
                setSubmitLoading(false);
                await closeModal();
                formik.resetForm({
                  title: "",
                  comment: "",
                });
                //   resetFrom({ values: "" });
                selectedComment.index !== ""
                  ? toast.success("Comment Updated Successfully")
                  : toast.success("Comment Added Successfully");
                return setRefresh(Date.now() + 1);
              }
              return;
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  selectedComment.index !== ""
                    ? toast.error("Comment Updated failed")
                    : toast.error("Comment is not added");
                  return setSubmitLoading(false);
                }
                if (error.response.status === 500) {
                  toast.error("Internal server error");
                  return setSubmitLoading(false);
                }
              }
            }
          }}
        >
          {(formik) => {
            return (
              <>
                <Modal.Body>
                  <>
                    <div
                      className="inputBox"
                      style={{
                        position: "relative",
                        marginTop: 0,
                        marginBottom: "10px",
                      }}
                    >
                      <label htmlFor="title" className="semiFont login__label">
                        Title {required}
                      </label>
                      <input
                        type="text"
                        id="title"
                        style={{
                          border: formik.errors.title
                            ? "1px solid red"
                            : "1px solid #c3cad9",
                          marginTop: "5px",
                          width: "auto",
                        }}
                        placeholder="Enter Title"
                        {...formik.getFieldProps("title")}
                      />
                      {formik.errors.title && (
                        <p className="err">{formik.errors.title}</p>
                      )}
                    </div>
                    <div
                      className="inputBox"
                      style={{
                        position: "relative",
                        marginTop: 0,
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor="description"
                        className="semiFont login__label"
                      >
                        Description {required}
                      </label>
                      <textarea
                        style={{
                          border: formik.errors.comment
                            ? "1px solid red"
                            : "1px solid #c3cad9",
                          marginTop: "5px",
                          width: "auto",
                          resize: "none",
                          borderRadius: "8px",
                          padding: "10px",
                          color: "#183b56",
                          fontSize: "17px",
                          fontFamily: "mediumFont",
                        }}
                        placeholder="Description"
                        id="description"
                        rows="3"
                        {...formik.getFieldProps("comment")}
                      ></textarea>

                      {formik.errors.comment && (
                        <p className="err">{formik.errors.comment}</p>
                      )}
                    </div>
                  </>
                </Modal.Body>
                <div className="carbon__buttoncontainer">
                  {/* {location.state.editable ? ( */}
                  <Button
                    variant="contained"
                    className="carbon__secondarybtn carbon__btn"
                    id="admin-reportview-download-btn"
                    style={{
                      //   padding: "25px ",
                      color: "#fff",
                      //   border: "1px solid #1565d8",
                      //   marginRight: "15px",
                    }}
                    onClick={() => closeModal()}
                  >
                    <p className="carbon__text">Cancel</p>
                  </Button>
                  {/* ) : null} */}

                  <Button
                    variant="contained"
                    className="carbon__primarybtn carbon__btn"
                    id="admin-reportview-download-btn"
                    disabled={submitLoading}
                    style={{
                      color: "#fff",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {submitLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <p className="carbon__text">
                        {selectedComment.index !== "" ? "Update" : "Add"}
                      </p>
                    )}

                    {/* Yes */}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  const ReportModal = () => {
    const [documents, setDocuments] = useState([
      {
        title: "",
        file: "",
        remove: false,
        chosen: false,
      },
    ]);
    const [uploadErr, setUploadErr] = useState(false);
    const [modalSubmitLoader, setModalSubmitLoader] = useState(false);

    const handleInput = (event, i) => {
      const values = [...documents];
      values[i].title = event.target.value;
      setDocuments(values);
    };

    const handleAdd = () => {
      setDocuments([
        ...documents,
        { title: "", file: "", remove: true, chosen: false },
      ]);
    };

    const handleRemove = (id) => {
      const values = [...documents];
      values.splice(id, 1);
      setDocuments(values);
      // setDocuments()
    };

    const handleUpload = (event, i, item) => {
      console.log("title", i);
      const files = event.target.files;
      console.log("upload files", files);

      if (files.length === 0) {
        return null;
      }

      if (files[0].type !== "application/pdf") {
        console.log("error");
        return toast.error("Please upload a PDF file");
      } else if (files[0].size > 3145728) {
        return toast.error("File size must be less than 3MB");
      } else {
        const values = [...documents];
        // const fileName = files[0];
        // fileName.name = `${item.title}.pdf`;
        // console.log("fileName", fileName);

        values[i].file = files[0];
        setDocuments(values);
      }
    };

    let nextLineCheck = false;
    documents &&
      documents.filter((list) =>
        list.title !== "" && list.file !== ""
          ? (nextLineCheck = true)
          : (nextLineCheck = false)
      );

    // console.log("documents", documents);

    const uploadSubmit = async () => {
      if (!nextLineCheck) {
        setUploadErr(true);
      } else {
        setUploadErr(false);
        setModalSubmitLoader(true);
        // console.log("ready to submit");

        const formData = new FormData();

        // return;
        formData.append("report_id", location.state.data.report_id);
        // formData.append("fileNames", fileName);
        documents.map((item) => {
          formData.append(
            "reports",
            item.file,
            `RID${location.state.data.report_id}-${item.title}`
          );
        });

        try {
          const response = await axios.post(
            Global.base_url + "/report/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200) {
            setModalSubmitLoader(false);
            // setUploader(false);
            // await closeModal();

            setReportModal(false);
            toast.success("Reports Uploaded Successfully");
            //   formik.resetForm();
            return setRefresh(Date.now() + 1);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast.error("Unable upload report");
          }
          if (error.response && error.response.status === 500) {
            toast.error("Internal server error");
          }
        }
      }
    };
    return (
      <Modal
        show={reportModal}
        onHide={() => setReportModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              Upload Documents
              {/* {editProduct.index !== "" ? "Edit Test" : "Add Test"} */}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="semiFont">
          <div className="document__container pb-3">
            <section
              className="modal__header d-flex align-items-center justify-content-end"
              style={{ position: "relative" }}
            >
              {/* <div style={{ position: "relative" }}> */}
              {uploadErr ? (
                <p
                  className="login__label positioned__error"
                  style={{ color: "#cc0000" }}
                >
                  All fields are required
                </p>
              ) : null}
              {/* </div> */}

              <Button
                variant="contained"
                className="my-2 my-sm-0"
                onClick={handleAdd}
                disabled={!nextLineCheck}
                // disabled={!formik.dirty || uploader}
                style={{
                  textTransform: "initial",
                  fontFamily: "semiFont",
                  fontSize: "18px",
                  backgroundColor: nextLineCheck ? "#1565d8" : "#666",
                  padding: "5px 25px",
                  // width: "150px",
                  color: "#fff",
                }}
              >
                <p>Add</p>
              </Button>
            </section>
            <div className="document__section">
              {documents &&
                documents.map((item, i) => {
                  return (
                    <div
                      className="input__row d-flex flex-column flex-lg-row align-items-lg-center"
                      key={i}
                    >
                      <div
                        className="inputBox__report"
                        style={{
                          display: "flex",
                          marginTop: 0,
                        }}
                      >
                        <input
                          type="text"
                          id="title"
                          placeholder="Title"
                          value={item.title}
                          style={{
                            border: "1px solid #c3cad9",
                            marginTop: "5px",
                          }}
                          onChange={(e) => {
                            handleInput(e, i);
                          }}
                        />
                      </div>
                      <div
                        className="button__row d-flex flex-column flex-lg-row align-items-lg-center"
                        // style={{ flexGrow: "0.5" }}
                      >
                        <div className="file__container--row d-flex flex-column flex-lg-row align-items-lg-center">
                          <div
                            className="file__container"
                            style={{ position: "relative" }}
                          >
                            <label
                              htmlFor="file"
                              className="ml-lg-2 mt-2 mt-lg-0"
                            >
                              Choose
                            </label>
                            <input
                              type="file"
                              name={`file ${i}`}
                              class="input__upload ml-lg-2 mt-2 mt-lg-0"
                              placeholder="Choose"
                              onChange={(event) => handleUpload(event, i, item)}
                              accept="application/pdf"
                            />
                          </div>

                          <p
                            className="mediumFont ml-lg-4 mt-2 mt-lg-0 login__label"
                            style={{ wordBreak: "break-word" }}
                          >
                            {item.file.name}
                          </p>
                        </div>

                        {item.remove ? (
                          <div className="button__lastItem  d-flex align-items-center justify-content-lg-end flex-grow-1">
                            <Button
                              variant="contained"
                              className="secondary__btn ml-lg-2 mt-2 mt-lg-0"
                              style={{
                                color: "#cc0000",
                                padding: "10px 20px",
                              }}
                              onClick={() => handleRemove(i)}
                            >
                              Remove
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <div className="carbon__buttoncontainer">
          {/* {location.state.editable ? ( */}
          <Button
            variant="contained"
            className="carbon__secondarybtn carbon__btn"
            id="admin-reportview-download-btn"
            style={{
              //   padding: "25px ",
              color: "#fff",
              //   border: "1px solid #1565d8",
              //   marginRight: "15px",
            }}
            onClick={() => {
              // setSelectedComment({
              //   data: "",
              //   index: "",
              // });
              setReportModal(false);
            }}
          >
            <p>Cancel</p>
          </Button>
          {/* ) : null} */}

          <Button
            variant="contained"
            className="carbon__primarybtn carbon__btn"
            id="admin-reportview-download-btn"
            style={{
              //   padding: "10px 25px",

              color: "#fff",
            }}
            disabled={modalSubmitLoader}
            onClick={uploadSubmit}
            // onClick={deleteItem}
          >
            {modalSubmitLoader ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <p className="carbon__text">Upload</p>
            )}
          </Button>
        </div>
      </Modal>
    );
  };

  // upload report handler
  const individualUploadHandle = async (event, i, doc) => {
    console.log("doc", doc);
    const files = event.target.files;
    console.log("upload files", files);

    if (files.length === 0) {
      return null;
    }

    if (files[0].type !== "application/pdf") {
      // console.log("error");
      return toast.error("Please upload a PDF file");
    } else if (files[0].size > 3145728) {
      return toast.error("File size must be less than 3MB");
    } else {
      const formData = new FormData();
      formData.append("report_id", location.state.data.report_id);
      doc.test_id
        ? formData.append("test_id", doc.test_id)
        : formData.append("unlinked_id", doc.unlinked_id);
      formData.append(
        "report",
        files[0],
        `RID${location.state.data.report_id}-${doc.test_name}`
      );
      try {
        const response = await axios.post(
          Global.base_url + "/report/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Report Uploaded Successfully");
          //   formik.resetForm();
          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Unable to upload report");
        }
        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
      }
    }

    // const
  };
  const combinedUploadHandle = async (event) => {
    // console.log("doc", doc);
    const files = event.target.files;
    console.log("upload files", typeof files);

    if (files.length === 0) {
      return null;
    }

    if (files.length > 5) {
      return toast.error("Please select upto 5 files");
    }
    let valid = true;

    for (let i = 0; i < files.length; i++) {
      if (files[i].type === "application/pdf") {
        if (files[i].size > 3145728) {
          valid = false;
          return toast.error("Files size must be less than 3MB");
        }
      } else {
        valid = false;
        return toast.error("Please upload a PDF file");
      }
    }

    if (valid) {
      // alert("submit");
      // return;
      const formData = new FormData();
      formData.append("report_id", location.state.data.report_id);
      for (let i = 0; i < files.length; i++) {
        formData.append("reports", files[i]);
      }
      try {
        const response = await axios.post(
          Global.base_url + "/report/uploadALL",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Reports Uploaded Successfully");
          //   formik.resetForm();
          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Unable to upload report");
        }
        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
      }
    }

    // const
  };

  // handler for download individual / combined report
  const downloadReport = async (doc) => {
    console.log("doc", doc);
    try {
      const response = await axios.post(
        Global.base_url + "/report/download",
        {
          report_id: location.state.data.report_id,
          doc_id: doc.doc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        console.log("reponse", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Report-${
            Object.keys(doc).includes("test_name")
              ? doc.test_name
              : location.state.data.report_id
          }.pdf`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unable to download report");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };

  const deleteReport = async (doc) => {
    console.log("doc", doc);
    try {
      const response = await axios.post(
        Global.base_url + "/report/document/remove",
        {
          report_id: location.state.data.report_id,
          doc_id: doc.doc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        // console.log("reponse", response);
        toast.success("Report deleted");
        return setRefresh(Date.now() + 1);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unable to delete report");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };

  const downloadAllReport = async () => {
    setDownloadLoader(true);
    // console.log("doc", doc);
    try {
      const response = await axios.post(
        Global.base_url + "/report/downloadAll",
        {
          report_id: location.state.data.report_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        console.log("reponse", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `report-${location.state.data.report_id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        setDownloadLoader(false);
      }
    } catch (error) {
      setDownloadLoader(false);
      if (error.response && error.response.status === 401) {
        toast.error("Unable to download reports");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <CommentModal
        // formikValues={formik.values}
        show={commentModal}
        onHide={() => setCommentModal(false)}
      />
      <ReportModal
        // formikValues={formik.values}
        show={reportModal}
        onHide={() => setReportModal(false)}
      />
      <DeleteModal
        // formikValues={formik.values}
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div
          className="main__container mb-2"
          style={{ backgroundColor: "#fff", minHeight: "90vh" }}
        >
          <div className="row  w-100 m-0">
            <div
              className="col-12 bg-white align-items-center pt-3 pb-3"
              style={{
                borderBottom: "1px solid #B3CDFF",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  history.push("/admin/reports");
                }}
              >
                <IconContext.Provider value={{ size: "30px" }}>
                  <FiArrowLeft />
                </IconContext.Provider>
              </IconButton>
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                  marginLeft: "5px",
                }}
                className="semiFont"
              >
                Back
              </p>
            </div>
            {reportData ? (
              <>
                {reportData.report ? (
                  <div className="row  mt-3 p-3 w-100 ml-1">
                    <div className="col-xl-5 col-lg-5 col-12 ">
                      <div className="row mt-2">
                        <div className="col ">
                          <span className="admin-reportview-span  admin-reportview-heading-text ">
                            Report ID
                          </span>
                          <span className="admin-reportview-span admin-reportview-sub-text">
                            RID{reportData.report.report_id}
                          </span>
                        </div>
                        <div className="col">
                          <span className="admin-reportview-span admin-reportview-heading-text">
                            Name
                          </span>
                          <span className="admin-reportview-span admin-reportview-sub-text">
                            {/* {reportData.report_data
                    ? reportData.report_data.name
                    : "user"} */}
                            {reportData.report.member_name}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-6 col-sm-6">
                          <span className="admin-reportview-span admin-reportview-heading-text">
                            {reportData.report.package_name
                              ? "Package"
                              : "Test"}{" "}
                            Type
                          </span>
                          <span className="admin-reportview-span admin-reportview-sub-text">
                            {reportData.report.type}
                          </span>
                        </div>
                        <div className="col-12  col-md-6 col-sm-6 mt-3 mt-sm-0">
                          <span className="admin-reportview-span admin-reportview-heading-text">
                            {reportData.report.package_name
                              ? "Package"
                              : "Test"}{" "}
                            Name
                          </span>
                          <span className="admin-reportview-span admin-reportview-sub-text">
                            {reportData.report.package_name ||
                              reportData.report.test_name}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-12 col-sm-12">
                          <span className="admin-reportview-span admin-reportview-heading-text">
                            {reportData.report.package_name
                              ? "Test Inclusion"
                              : "Test Description"}{" "}
                          </span>
                          <span className="admin-reportview-span admin-reportview-sub-text">
                            {(reportData.report.test_inclusion &&
                              reportData.report.test_inclusion.map((item) => {
                                return (
                                  <>
                                    <span style={{ color: "#333" }}>
                                      {item.test_for}
                                    </span>
                                    : {item.test_name}{" "}
                                  </>
                                );
                              })) ||
                              reportData.report.test_desc}
                          </span>
                        </div>
                      </div>
                      {/* <div className="col-12 col-xl-8 col-lg-8   admin-reportview-btns mt-4 ">
                <Button
                  variant="contained"
                  id="admin-reportview-bill-btn"
                  style={{ border: "1px solid #B3CDFF", width: "100%" }}
                >
                  Bill PDF
                </Button>
              </div> */}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-12">
                      <div className="row">
                        <div className="col-12 mt-sm-1 mt-3">
                          <span className="admin-reportview-span admin-reportview-heading-text">
                            Test Center
                          </span>
                        </div>
                        <div className="col mt-1 ">
                          <span className="admin-reportview-span  admin-reportview-sub-text">
                            {reportData.report.lab_name}
                          </span>
                          <span className="admin-reportview-span  admin-reportview-sub-address">
                            {reportData.report.city +
                              "," +
                              reportData.report.state}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3">
                          <span className="admin-reportview-span admin-reportview-heading-text">
                            Requested Date
                          </span>
                          <span className="admin-reportview-span admin-reportview-sub-text mt-1">
                            {moment(reportData.report.requested_date).format(
                              "DD-MM-YYYY h:mm A"
                            )}
                          </span>
                        </div>
                      </div>
                      <Formik
                        initialValues={{
                          schedule: moment(
                            reportData.report.appointment,
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        }}
                        onSubmit={async (values) => {
                          // console.log("schedule", values);

                          try {
                            const response = await axios.post(
                              Global.base_url + "/report/schedule",
                              {
                                report_id: location.state.data.report_id,
                                appointment: values.schedule,
                              },
                              config
                            );
                            // console.log("schdule", response);
                            if (response.status === 200) {
                              setRefresh(Date.now() + 1);
                              let timer = setInterval(() => {
                                toast.success("Successfully Updated");
                                clearInterval(timer);
                              }, 1000);
                            }
                          } catch (error) {
                            return console.log(error);
                          }
                        }}
                      >
                        {(formik) => {
                          return (
                            <div className="row">
                              <div className="col-12 mt-3">
                                <span className="admin-reportview-span admin-reportview-heading-text">
                                  Appointment Date
                                </span>
                                {reportData.report.appointment !== null ? (
                                  <div className="d-flex align-items-center">
                                    <span className="admin-reportview-span admin-reportview-sub-text mt-1">
                                      {moment(
                                        reportData.report.appointment
                                      ).format("DD-MM-YYYY h:mm A")}
                                    </span>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {/* <IconButton> */}
                                      <IconContext.Provider
                                        value={{
                                          size: "25px",
                                          color: "#555",
                                        }}
                                      >
                                        <AiTwotoneEdit
                                          onClick={() => setOpen(!open)}
                                        />
                                      </IconContext.Provider>
                                      {/* </IconButton> */}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <span
                                      className="admin-reportview-span admin-reportview-sub-text mt-1"
                                      style={{ color: "red" }}
                                    >
                                      Not Schedule
                                    </span>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {/* <IconButton> */}
                                      <IconContext.Provider
                                        value={{
                                          size: "25px",
                                          color: "#555",
                                        }}
                                      >
                                        <AiTwotoneEdit
                                          onClick={() => setOpen(!open)}
                                        />
                                      </IconContext.Provider>
                                      {/* </IconButton> */}
                                    </div>
                                  </div>
                                )}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDateTimePicker
                                    value={formik.values.schedule}
                                    disablePast={true}
                                    onChange={(date) =>
                                      formik.setFieldValue("schedule", date)
                                    }
                                    ampm={false}
                                    open={open}
                                    onClose={() => {
                                      setOpen((open) => !open);
                                    }}
                                    hidden={true}
                                    onAccept={(date) => {
                                      formik.setFieldValue("schedule", date);
                                      formik.handleSubmit();
                                    }}
                                    okLabel={
                                      reportStatus === "Shipped" ||
                                      reportData.report.type === "Kit Delivery"
                                        ? "Schedule Delivery"
                                        : "Schedule Appointment"
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          );
                        }}
                      </Formik>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-12">
                      <div className="row">
                        <div className="col mt-2">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="product_typeoftest"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Status
                            </label>
                            <Select
                              id="inputDropdown"
                              placeholder="Select Status"
                              value={{
                                label: reportStatus,
                                value: reportStatus,
                              }}
                              onChange={(v) => setReportStatus(v.value)}
                              options={
                                reportData.report &&
                                reportData.report &&
                                reportData.report.type === "HOME TEST"
                                  ? hometestOptions
                                  : walkinOptions
                              }
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "7px",
                                  borderRadius: "8px",
                                  border: "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isDisabled ? "#888" : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {/* {formik.errors.package_type && (
                            <div className="err">
                              <p>{formik.errors.package_type}</p>
                            </div>
                          )} */}
                          </div>
                          <div className="d-flex justify-content-md-end">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleStatus}
                              // disabled={!formik.dirty || uploader}
                              style={{
                                backgroundColor: "#1565d8",
                                color: "#fff",
                                padding: "10px 20px",
                                fontSize: "18px",
                                textTransform: "initial",
                                marginTop: "5px",
                                // marginLeft: "10px",
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>

                      {reportData.report.tracking_info !== null &&
                      reportData.report.type === "KIT DELIVERY" &&
                      (reportData.report.status === "Sample Processing" ||
                        reportData.report.status === "Completed") ? (
                        <div className="col mt-4 pl-0">
                          <p className="admin-reportview-heading-text">
                            Tracking Details
                          </p>
                          <p className="admin-reportview-sub-text">
                            {reportData.report.tracking_info}
                          </p>
                        </div>
                      ) : null}
                      {reportData.report.type === "KIT DELIVERY" &&
                      reportData.report.status === "Awaiting Sample" ? (
                        <div className="col mt-4 pl-0">
                          {/* <p className="covid-status-label">Tracking Info</p> */}
                          <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={{
                              tracking_info:
                                reportData.report.tracking_info !== null &&
                                reportData.report.tracking_info
                                  ? reportData.report.tracking_info
                                  : "",
                            }}
                            validationSchema={yup.object().shape({
                              tracking_info: yup
                                .string()
                                .min(10, "Must be more than 10 letters")
                                .required("Tracking Info cannot be empty"),
                            })}
                            onSubmit={async (values, formik) => {
                              // console.log(values);

                              try {
                                const response = await axios.post(
                                  Global.base_url +
                                    "/user/report/update/tracking",
                                  {
                                    report_id: reportData.report.report_id,
                                    ...values,
                                  },
                                  config
                                );

                                // console.log(response);
                                if (response.status === 200) {
                                  toast.success(
                                    "Tracking Details Updated Successfully"
                                  );
                                  return setRefresh(Date.now() + 1);
                                }

                                return;
                              } catch (error) {
                                if (
                                  error.response &&
                                  error.response.status === 401
                                ) {
                                  return toast.error("Update failed");
                                }
                                if (
                                  error.response &&
                                  error.response.status === 500
                                ) {
                                  return toast.error("Internal server error");
                                }
                                // console.log("error", error);
                              }
                            }}
                          >
                            {(formik) => {
                              return (
                                <>
                                  <div
                                    className="inputBox"
                                    style={{
                                      position: "relative",
                                      marginTop: 0,
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <label
                                      htmlFor="address"
                                      className="admin-reportview-heading-text"
                                    >
                                      Tracking Info {requiredField}
                                    </label>
                                    <textarea
                                      id="tracking_info"
                                      rows="2"
                                      style={{
                                        border: formik.errors.tracking_info
                                          ? "1px solid red"
                                          : "1px solid #c3cad9",
                                        marginTop: "5px",
                                        width: "auto",
                                        resize: "none",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        color: "#183b56",
                                        fontSize: "17px",
                                        fontFamily: "mediumFont",
                                      }}
                                      value={formik.values.tracking_info}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "tracking_info",
                                          e.currentTarget.value.replace(
                                            /[^a-zA-Z0-9-_ ]/gi,
                                            ""
                                          )
                                        );
                                      }}
                                    />

                                    {formik.errors.tracking_info && (
                                      <p className="err">
                                        {formik.errors.tracking_info}
                                      </p>
                                    )}
                                  </div>
                                  <div className="form-group mt-3">
                                    <Button
                                      className="primary__btn"
                                      variant="contained"
                                      onClick={formik.handleSubmit}
                                      // id="admin-reportview-send-btn"
                                      style={{
                                        padding: "8px 30px",
                                        color: "white",
                                      }}
                                    >
                                      {reportData.report.tracking_info !== null
                                        ? "Update"
                                        : "Submit"}
                                    </Button>
                                  </div>
                                </>
                              );
                            }}
                          </Formik>
                        </div>
                      ) : null}
                      {reportData.report.status === "Completed" ? (
                        <div className="col mt-4">
                          <div className="col-12  col-xl-12 col-lg-12 admin-reportview-btns mt-4">
                            <Button
                              variant="contained"
                              id="admin-reportview-download-btn"
                              style={{
                                border: "1px solid #B3CDFF",
                                width: "100%",
                              }}
                              onClick={downloadFile}
                            >
                              Report Download
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div className="p-3 w-100">
                  <div className="d-flex align-items-start align-items-sm-center justify-content-between flex-sm-row flex-column">
                    <p className="report__header mt-2">Reports</p>
                    {/* <div className="button__row d-flex" style={{ gap: "10px" }}>
                      <Button
                        variant="contained"
                        className="secondary__btn  my-2 my-sm-0"
                        startIcon={
                          <IconContext.Provider
                            value={{
                              color: "#1565d8",
                              size: "20px",
                            }}
                          >
                            <FiDownload />
                          </IconContext.Provider>
                        }
                        onClick={() => {
                          downloadAllReport();
                        }}
                        style={{
                          padding: "8px 25px",
                          // width: "150px",
                          color: "#1565d8",
                        }}
                      >
                        <p>Download All</p>
                      </Button>
                      <Button
                        variant="contained"
                        className="primary__btn  my-2 my-sm-0"
                        startIcon={
                          <IconContext.Provider
                            value={{
                              color: "#fff",
                              size: "20px",
                            }}
                          >
                            <AiOutlineCloudUpload />
                          </IconContext.Provider>
                        }
                        onClick={() => {
                          // setSelectedComment({
                          //   data: "",
                          //   index: "",
                          // });
                          setReportModal(true);
                        }}
                        // disabled={!formik.dirty || uploader}
                        style={{
                          padding: "8px 25px",
                          // width: "150px",
                          color: "#fff",
                        }}
                      >
                        <p>Upload</p>
                      </Button>
                    </div> */}
                  </div>
                  <div className="col-12 admin-reportview-comments py-3 mt-2">
                    {uploadedReports ? (
                      <>
                        <div className="d-flex align-items-start align-items-sm-center justify-content-between flex-sm-row flex-column mb-4">
                          {reportData &&
                          reportData.report &&
                          reportData.report.pack_linked === null ? null : (
                            <FormControlLabel
                              // Needed by MaterialUI to create labels, like Santa, Grinch
                              control={
                                <BlueSwitch
                                  checked={reportSwitch} // boolean true/false
                                  onChange={() => {
                                    setReportSwitch((prev) => !prev);
                                  }} // "!" <-- lets the state flip, otherwise state flips once
                                />
                              }
                              label={reportSwitch ? "Combined" : "Individual"}
                            />
                          )}
                          {!reportSwitch &&
                          uploadedReports.individual &&
                          uploadedReports.individual.filter(
                            (list) => list.doc_id && list.filename
                          ).length >= 2 ? (
                            <Button
                              variant="contained"
                              className="secondary__btn  my-2 my-sm-0"
                              startIcon={
                                !downloadLoader && (
                                  <IconContext.Provider
                                    value={{
                                      color: "#1565d8",
                                      size: "20px",
                                    }}
                                  >
                                    <FiDownload />
                                  </IconContext.Provider>
                                )
                              }
                              onClick={() => {
                                downloadAllReport();
                              }}
                              style={{
                                padding: downloadLoader
                                  ? "8px 100px"
                                  : "8px 25px",
                                // width: "150px",
                                color: "#1565d8",
                              }}
                              disabled={downloadLoader}
                            >
                              {downloadLoader ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <p className="carbon__text">Download All</p>
                              )}
                              {/* <p>Download All</p> */}
                            </Button>
                          ) : null}
                        </div>

                        <div
                          className=" d-flex flex-wrap justify-content-between mt-2"
                          style={{ gap: "10px" }}
                        >
                          {/* <> */}

                          {!reportSwitch && uploadedReports.individual ? (
                            uploadedReports.individual.map((report, i) => {
                              return (
                                <div
                                  className="admin-reportview-comments-box p-3 mb-0 uploaded__items"
                                  key={i}
                                >
                                  <div className="testItem__row d-flex justify-content-between">
                                    <div className="test__item">
                                      <p className="admin-reportview-heading-text">
                                        Test Name
                                      </p>
                                      <p className="admin-reportview-sub-text">
                                        {report.test_name}
                                      </p>
                                    </div>
                                    <p
                                      className="admin-reportview-heading-text"
                                      style={{
                                        color:
                                          report.doc_id && report.filename
                                            ? "#03A33B"
                                            : "#ffb100",
                                      }}
                                    >
                                      {report.doc_id && report.filename
                                        ? "Uploaded"
                                        : "Inprogress"}
                                    </p>
                                  </div>

                                  <div className="testItem__row d-flex align-items-center justify-content-end mt-2">
                                    {report.doc_id && report.filename ? (
                                      <>
                                        <div className="item__row">
                                          <Tooltip
                                            title={"Download"}
                                            placement="top-center"
                                          >
                                            <IconButton
                                              style={{
                                                backgroundColor: "#1565d817",
                                              }}
                                              onClick={() => {
                                                downloadReport(report);
                                                // history.push("/admin/reports");
                                              }}
                                            >
                                              <IconContext.Provider
                                                value={{
                                                  color: "#1565d8",
                                                  size: "20px",
                                                }}
                                              >
                                                <FiDownload />
                                              </IconContext.Provider>
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                        <div className="item__row ml-2">
                                          <Tooltip
                                            title={"Remove"}
                                            placement="top-center"
                                          >
                                            <IconButton
                                              style={{
                                                backgroundColor: "#cc000017",
                                              }}
                                              onClick={() => {
                                                deleteReport(report);
                                                // history.push("/admin/reports");
                                              }}
                                            >
                                              <IconContext.Provider
                                                value={{
                                                  color: "#cc0000",
                                                  size: "20px",
                                                }}
                                              >
                                                <AiOutlineDelete />
                                              </IconContext.Provider>
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="item__row">
                                        <Tooltip
                                          title={"Upload"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            style={{
                                              backgroundColor: "#1565d817",
                                            }}
                                            onClick={() =>
                                              individualUploadRef.current[
                                                i
                                              ].click()
                                            }
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "20px",
                                              }}
                                            >
                                              <FiUpload />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                        <input
                                          type="file"
                                          ref={(el) =>
                                            (individualUploadRef.current[i] =
                                              el)
                                          }
                                          onChange={(event) =>
                                            individualUploadHandle(
                                              event,
                                              i,
                                              report
                                            )
                                          }
                                          style={{ display: "none" }}
                                          accept="application/pdf"
                                        />
                                      </div>
                                    )}

                                    {/* <p className="admin-reportview-heading-text">
                                      Test For
                                    </p>
                                    <p className="admin-reportview-sub-text">
                                      {report.test_for}
                                    </p> */}
                                  </div>
                                  {/* <p className="login__label mediumFont mt-2">
                                {report.filename}
                              </p> */}
                                  {/* <div className="button__row d-flex mt-2">
                                    <Button
                                      variant="contained"
                                      className="secondary__btn mt-2"
                                      startIcon={
                                        <IconContext.Provider
                                          value={{
                                            color: "#1565d8",
                                            size: "20px",
                                          }}
                                        >
                                          <FiDownload />
                                        </IconContext.Provider>
                                      }
                                      onClick={() => {
                                        downloadReport(report);
                                      }}
                                      style={{
                                        padding: "5px 20px",
                                        // width: "150px",
                                        color: "#1565d8",
                                      }}
                                    >
                                      <p>Download</p>
                                    </Button>
                                    <Button
                                      variant="contained"
                                      className="secondary__btn mt-2"
                                      id="admin-reportview-download-btn"
                                      startIcon={
                                        <IconContext.Provider
                                          value={{
                                            color: "#cc0000",
                                            size: "20px",
                                          }}
                                        >
                                          <AiOutlineDelete />
                                        </IconContext.Provider>
                                      }
                                      style={{
                                        padding: "5px 20px",
                                        // width: "150px",
                                        color: "#cc0000",
                                        //   border: "1px solid #cc0000",
                                        // marginLeft: "15px",
                                      }}
                                      onClick={() => {
                                        deleteReport(report);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div> */}
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="admin-reportview-comments-box p-3 mb-0 uploaded__items"
                              // key={i}
                            >
                              <div className="testItem__row d-flex justify-content-between">
                                <div className="test__item">
                                  <p className="admin-reportview-heading-text">
                                    Package / Test
                                  </p>
                                  <p className="admin-reportview-sub-text">
                                    {(reportData.report &&
                                      reportData.report.package_name) ||
                                      (reportData.report &&
                                        reportData.report.test_name)}
                                  </p>
                                </div>
                                <p
                                  className="admin-reportview-heading-text"
                                  style={{
                                    color: uploadedReports.combined.length
                                      ? "#03A33B"
                                      : "#ffb100",
                                  }}
                                >
                                  {uploadedReports.combined.length
                                    ? "Uploaded"
                                    : "Inprogress"}
                                </p>
                              </div>

                              <div className="testItem__row d-flex align-items-center justify-content-end mt-2">
                                {uploadedReports.combined.length ? (
                                  <>
                                    <div className="item__row">
                                      <Tooltip
                                        title={"Download"}
                                        placement="top-center"
                                      >
                                        <IconButton
                                          style={{
                                            backgroundColor: "#1565d817",
                                          }}
                                          onClick={() => {
                                            downloadReport(
                                              uploadedReports.combined[0]
                                            );
                                            // history.push("/admin/reports");
                                          }}
                                        >
                                          <IconContext.Provider
                                            value={{
                                              color: "#1565d8",
                                              size: "20px",
                                            }}
                                          >
                                            <FiDownload />
                                          </IconContext.Provider>
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                    <div className="item__row ml-2">
                                      <Tooltip
                                        title={"Remove"}
                                        placement="top-center"
                                      >
                                        <IconButton
                                          style={{
                                            backgroundColor: "#cc000017",
                                          }}
                                          onClick={() => {
                                            deleteReport(
                                              uploadedReports.combined[0]
                                            );
                                          }}
                                        >
                                          <IconContext.Provider
                                            value={{
                                              color: "#cc0000",
                                              size: "20px",
                                            }}
                                          >
                                            <AiOutlineDelete />
                                          </IconContext.Provider>
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </>
                                ) : (
                                  <div className="item__row">
                                    <Tooltip
                                      title={"Upload"}
                                      placement="top-center"
                                    >
                                      <IconButton
                                        style={{
                                          backgroundColor: "#1565d817",
                                        }}
                                        onClick={() =>
                                          combinedUploadRef.current.click()
                                        }
                                      >
                                        <IconContext.Provider
                                          value={{
                                            color: "#1565d8",
                                            size: "20px",
                                          }}
                                        >
                                          <FiUpload />
                                        </IconContext.Provider>
                                      </IconButton>
                                    </Tooltip>
                                    <input
                                      type="file"
                                      ref={combinedUploadRef}
                                      onChange={(event) =>
                                        combinedUploadHandle(event)
                                      }
                                      multiple
                                      style={{ display: "none" }}
                                      accept="application/pdf"
                                    />
                                  </div>
                                )}

                                {/* <p className="admin-reportview-heading-text">
                                Test For
                              </p>
                              <p className="admin-reportview-sub-text">
                                {report.test_for}
                              </p> */}
                              </div>
                              {/* <p className="login__label mediumFont mt-2">
                          {report.filename}
                        </p> */}
                              {/* <div className="button__row d-flex mt-2">
                              <Button
                                variant="contained"
                                className="secondary__btn mt-2"
                                startIcon={
                                  <IconContext.Provider
                                    value={{
                                      color: "#1565d8",
                                      size: "20px",
                                    }}
                                  >
                                    <FiDownload />
                                  </IconContext.Provider>
                                }
                                onClick={() => {
                                  downloadReport(report);
                                }}
                                style={{
                                  padding: "5px 20px",
                                  // width: "150px",
                                  color: "#1565d8",
                                }}
                              >
                                <p>Download</p>
                              </Button>
                              <Button
                                variant="contained"
                                className="secondary__btn mt-2"
                                id="admin-reportview-download-btn"
                                startIcon={
                                  <IconContext.Provider
                                    value={{
                                      color: "#cc0000",
                                      size: "20px",
                                    }}
                                  >
                                    <AiOutlineDelete />
                                  </IconContext.Provider>
                                }
                                style={{
                                  padding: "5px 20px",
                                  // width: "150px",
                                  color: "#cc0000",
                                  //   border: "1px solid #cc0000",
                                  // marginLeft: "15px",
                                }}
                                onClick={() => {
                                  deleteReport(report);
                                }}
                              >
                                Delete
                              </Button>
                            </div> */}
                            </div>
                          )}
                          {/* </> */}
                        </div>
                      </>
                    ) : (
                      <div
                        className="center__items p-5"
                        style={{ flexDirection: "column", margin: "auto" }}
                      >
                        <img src={emptyReport} alt="Empty Reports" />
                        <p
                          style={{
                            color: "#D2D2D2",
                            padding: "20px",
                            fontSize: "20px",
                            fontFamily: "mediumFont",
                          }}
                        >
                          Reports not found
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="p-3 w-100">
                  <div className="d-flex align-items-start align-items-sm-center justify-content-between flex-sm-row flex-column">
                    <p className="report__header mt-2">Comments</p>
                    <Button
                      variant="contained"
                      className="primary__btn  my-2 my-sm-0"
                      onClick={() => {
                        setSelectedComment({
                          data: "",
                          index: "",
                        });
                        setCommentModal(true);
                      }}
                      // disabled={!formik.dirty || uploader}
                      style={{
                        padding: "8px 25px",
                        // width: "150px",
                        color: "#fff",
                      }}
                    >
                      <p>Add Comment</p>
                    </Button>
                  </div>
                  <div className="col-12 admin-reportview-comments mt-2 ">
                    {reportData.comments.length ? (
                      reportData.comments.map((comment, i) => {
                        return (
                          <>
                            <div
                              className="col-12 admin-reportview-comments-box  mt-3 p-3"
                              key={i}
                            >
                              <div className="d-flex flex-sm-row flex-column justify-content-between">
                                <div className="d-flex flex-column">
                                  <p className="item__title">{comment.title}</p>
                                  <p className="item__subTitle mt-2">
                                    {comment.comment}
                                  </p>
                                </div>
                                <p className="item__subTitle mt-sm-0 mt-2">
                                  {comment.commented_datetime}
                                </p>
                              </div>

                              <div className="button__row mt-2">
                                <Button
                                  variant="contained"
                                  className="primary__btn  my-2 my-sm-0"
                                  startIcon={
                                    <IconContext.Provider
                                      value={{
                                        color: "#fff",
                                        size: "20px",
                                      }}
                                    >
                                      <FiEdit />
                                    </IconContext.Provider>
                                  }
                                  onClick={() => {
                                    setSelectedComment({
                                      data: comment,
                                      index: i,
                                    });
                                    setCommentModal(true);
                                  }}
                                  style={{
                                    padding: "5px 20px",
                                    // width: "150px",
                                    color: "#fff",
                                  }}
                                >
                                  <p>Edit</p>
                                </Button>
                                <Button
                                  variant="contained"
                                  className="secondary__btn"
                                  id="admin-reportview-download-btn"
                                  startIcon={
                                    <IconContext.Provider
                                      value={{
                                        color: "#cc0000",
                                        size: "20px",
                                      }}
                                    >
                                      <AiOutlineDelete />
                                    </IconContext.Provider>
                                  }
                                  style={{
                                    padding: "5px 20px",
                                    // width: "150px",
                                    color: "#cc0000",
                                    //   border: "1px solid #cc0000",
                                    marginLeft: "15px",
                                  }}
                                  onClick={() => {
                                    setSelectedComment({
                                      data: comment,
                                      index: "",
                                    });
                                    setDeleteModal(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        <img src={emptyComment} alt="Empty-incoice" />
                        <p
                          style={{
                            color: "#D2D2D2",
                            padding: "20px",
                            fontSize: "20px",
                            fontFamily: "mediumFont",
                          }}
                        >
                          Comments will be availiable soon...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportView;
