import React from "react";
import { HiCheckCircle } from "react-icons/hi";
import { FaQuoteLeft } from "react-icons/fa";
import { IconContext } from "react-icons";

const LoginSideContent = () => {
  return (
    <section className="primary-bg login__sideContent">
      <div className="login__sideSection">
        <div className="login__quotes">
          <IconContext.Provider
            value={{
              color: "#00DAF7",
              size: "35px",
            }}
          >
            <FaQuoteLeft />
          </IconContext.Provider>
        </div>
        <h2 className="login__sideText">
          We optimize hospital operations and enhance patient care with our user-friendly, efficient, and secure solution.
        </h2>
        <div className="login__authorSection">
          {/* <p className="login__authorName semiFont">Daniel Anderson</p> */}
          <div className="login__authorSign">
            <IconContext.Provider
              value={{
                color: "#00DAF755",
                size: "20px",
                marginBottom: "15px",
              }}
            >
             
            </IconContext.Provider>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginSideContent;
