import { useEffect, useState } from "react";
import "../assets/css/role.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";
import Global from "../global";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FiArrowLeft } from "react-icons/fi";
import IconButton from "@material-ui/core/IconButton";
import { IconContext } from "react-icons";
import { useHistory, useLocation } from "react-router";
import { Button } from "@material-ui/core";
import { IoMdAddCircle } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { BsCheck2Circle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const BlueSwitch = withStyles({
  switchBase: {
    color: "#0F61FD",
    "&$checked": {
      color: "#0F61FD",
    },
    "&$checked + $track": {
      backgroundColor: "#0F61FD",
    },
  },
  checked: {},
  track: {},
})(Switch);

const adminOptions = [
  {
    label: "Global-Admin",
    value: 1,
  },
  {
    label: "Admin",
    value: 0,
  },
];

const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const SwitchButton = ({ data, id, handleChange, index, parentIndex }) => {
  // console.log("switch data", key);
  const switchData = data && data.isSwitched;

  return (
    <>
      <FormControlLabel // Needed by MaterialUI to create labels, like Santa, Grinch
        control={
          <BlueSwitch
            checked={switchData} // boolean true/false
            onChange={() => {
              handleChange(id, !switchData, index, parentIndex);
            }} // "!" <-- lets the state flip, otherwise state flips once
          />
        }
        //   label={student.behaviour.toString()}
      />
    </>
  );
};

const RoleEdit = () => {
  const history = useHistory();
  const location = useLocation();
  console.log("location", location.state);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [privileges, setPrivilleges] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [centers, setCenters] = useState([]);
  const [allCenters, setAllCenters] = useState([]);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectAdmin, setSelectAdmin] = useState(null);
  const [RoleDetails, setRoleDetails] = useState({
    role_name: "",
    role_desc: "",
  });

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const required = <span style={{ color: "red" }}>*</span>;
  const rowdataAlign = { verticalAlign: "middle" };

  useEffect(() => {
    async function fetchPrivilleges() {
      try {
        const response = await axios.post(
          Global.base_url + "/role/features",
          null,
          config
        );

        if (response.status === 200) {
          console.log("response", response);
          let filteredfeature = Object.values(
            response.data.data.reduce((r, o) => {
              r[o.feature_name] = r[o.feature_name] || {
                feature_name: o.feature_name,
                roles: [],
                feature_id: o.feature_id,
                role_id: o.role_id,
              };
              r[o.feature_name]["roles"].push({
                privilege_name: o.privilege_name,
                privilege_id: o.privilege_id,
                feature_id: o.feature_id,
                feature_id: o.feature_id,
                feature_name: o.feature_name,
                isSwitched: false,
              });
              return r;
            }, {})
          );

          if (location.state.editable) {
            try {
              const editResponse = await axios.post(
                Global.base_url + "/role/edit",
                {
                  role_id: location.state.data.role_id,
                },
                config
              );

              if (editResponse.status === 200) {
                console.log("editResponse", editResponse);
                let editedArray = editResponse.data.data.map(
                  (list) => list.privilege_id
                );

                setRoleDetails({
                  role_name: editResponse.data.data[0].role_name,
                  role_desc: editResponse.data.data[0].role_desc,
                  admin_type: {
                    value: editResponse.data.data[0].admin,
                    label: editResponse.data.data[0].admin
                      ? "Global-Admin"
                      : "Admin",
                  },
                  status: {
                    label: editResponse.data.data[0].status
                      ? "Active"
                      : "Inactive",
                    value: editResponse.data.data[0].status,
                  },
                });

                setSelectedCenters(
                  editResponse.data.labs.map((item) => item.lab_id)
                );

                filteredfeature.forEach(function iter(a) {
                  if (editedArray.includes(a.privilege_id)) {
                    a.isSwitched = true;
                  }

                  Array.isArray(a.roles) && a.roles.forEach(iter);
                });

                setPrivilleges(filteredfeature);
              }
            } catch (error) {
              if (error.editResponse) {
                if (error.editResponse.status === 401) {
                  return toast.error("Unable to get Existing privileges");
                }
                if (error.editResponse.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          } else {
            setPrivilleges(filteredfeature);
          }
          setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get privileges");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      try {
        const centerResponse = await axios.post(
          Global.base_url + "/role/centers",
          null,
          config
        );

        if (centerResponse.status === 200) {
          // console.log("center Response", centerResponse);
          setCenters(centerResponse.data.data);
          setAllCenters(centerResponse.data.data);
        }
      } catch (error) {
        if (error.centerResponse) {
          if (error.centerResponse.status === 401) {
            return toast.error("Unable to get centers");
          }
          if (error.centerResponse.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchPrivilleges();
  }, []);

  console.log("privillage", privileges);

  const initialValues = location.state.editable
    ? RoleDetails && RoleDetails
    : location.state.systemFeature
    ? {
        role_name: "",
        role_desc: "",
        admin_type: null,
        status: null,
        // admin: null
      }
    : {
        role_name: "",
        role_desc: "",
        status: null,
        admin_type: 0,
      };

  // console.log();

  const handleChange = (id, switchState, index, parentIndex) => {
    // let arr = [];
    const updatedData = privileges[parentIndex].roles[index];

    // console.log("updatedData", index);
    updatedData.isSwitched = switchState;

    const newData = [...privileges];
    newData[parentIndex].roles[index] = updatedData;
    // arr.push(updatedData);

    // console.log("newAttitude", arr);
    setPrivilleges(newData);
  };

  const ConfirmModal = ({ formikValues }) => {
    // console.log("selectedValue", selectedFeatures);

    const submitRole = async () => {
      if (location.state.editable === false) {
        const privilegesData = {
          role_name: formikValues.role_name,
          role_desc: formikValues.role_desc,
          roles: selectedFeatures,
          admin: location.state.systemFeature
            ? formikValues.admin_type.value
            : formikValues.admin_type,
          status: formikValues.status.value,
          labs: selectedCenters.length
            ? selectedCenters.map((item) => {
                return {
                  lab_id: item,
                };
              })
            : [],
        };

        // [0, 1, 2]

        try {
          const response = await axios.post(
            Global.base_url + "/role/add",
            privilegesData,
            config
          );
          // console.log(response);
          if (response.status === 200) {
            // setUploader(false);
            // await closeModal();
            toast.success("Role Added Successfully");
            return history.push("/admin/roles");
            //   formik.resetForm();
            // return setRefresh(Date.now() + 1);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast.error("Role is not added");
          }
          if (error.response && error.response.status === 500) {
            toast.error("Internal server error");
          }
        }
      } else if (location.state.editable) {
        const privilegesData = {
          role_id: location.state.data.role_id,
          role_name: formikValues.role_name,
          role_desc: formikValues.role_desc,
          roles: selectedFeatures,
          admin: formikValues.admin_type.value,
          status: formikValues.status.value,
          labs: selectedCenters.length
            ? selectedCenters.map((item) => {
                return {
                  lab_id: item,
                };
              })
            : [],
        };

        console.log("privilegesData", privilegesData);
        // return;
        try {
          const response = await axios.post(
            Global.base_url + "/role/update",
            privilegesData,
            config
          );
          // console.log(response);
          if (response.status === 200) {
            // setUploader(false);
            // await closeModal();
            toast.success("Role Updated Successfully");
            return history.push("/admin/roles");
            //   formik.resetForm();
            // return setRefresh(Date.now() + 1);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast.error("Role is not added");
          }
          if (error.response && error.response.status === 500) {
            toast.error("Internal server error");
          }
        }
      }
    };
    return (
      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p
              className="boldFont"
              onClick={() => console.log("selectedValues", selectedFeatures)}
            >
              Confirmation
              {/* {editProduct.index !== "" ? "Edit Test" : "Add Test"} */}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="confirm__container">
            <section
              className="admin-product-filter-section table-responsive fixHeader 
"
            >
              <table
                className="table  text-center  admin-product_usertable mb-0"
                style={{
                  backgroundColor: "white",
                }}
                id="bill-table"
              >
                <thead>
                  <tr
                    id="admin-product-row"
                    style={{
                      border: "none",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    <th scope="col" className="admin-product-row_table-header">
                      Privilege ID
                    </th>
                    <th scope="col" className="admin-product-row-table-header">
                      Privilege Name
                    </th>
                    <th
                      scope="col"
                      className="admin-product-row-table-header  admin-product-row-status-col"
                    >
                      Feature Name
                    </th>
                    <th
                      scope="col"
                      className="admin-product-row-table-header  admin-product-row-status-col"
                    >
                      Enabled
                    </th>
                  </tr>
                </thead>
                <tbody className="fixedBody">
                  {allFeatures.length > 0 ? (
                    allFeatures.map((data, i) => {
                      const borderBottom = `1px solid #B3CDFF`;
                      return (
                        <>
                          <tr
                            key={data.privilege_id + Math.random()}
                            style={{
                              //   borderLeft,
                              borderBottom,
                              backgroundColor: "white",
                            }}
                            className="admin-product-row-table-row-hover modal__table"
                          >
                            <td
                              scope="row"
                              id="admin-product-rd"
                              className="admin-product-row-table-row-data mediumFont"
                              style={rowdataAlign}
                            >
                              <p
                                data-toggle="collapse"
                                data-target={`#accordion` + i}
                                className="clickable"
                                id="button-open"
                                // style={{ display: "none" }}
                              >
                                <IconContext.Provider
                                  value={{
                                    color: "#4059E2",
                                    size: "25px",
                                    style: {
                                      marginBottom: "3px",
                                    },
                                  }}
                                >
                                  <IoMdAddCircle className="toggle-row-icon" />
                                </IconContext.Provider>
                              </p>{" "}
                              PID{data.privilege_id}
                            </td>
                            <td
                              className="admin-product-row-table-row-data mediumFont"
                              style={rowdataAlign}
                            >
                              {" "}
                              {data.privilege_name}
                            </td>
                            <td
                              className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                              style={rowdataAlign}
                            >
                              {data.feature_name}
                            </td>
                            <td
                              className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                              style={rowdataAlign}
                            >
                              {/* <SwitchButton
                                                  index={i}
                                                  data={data}
                                                  id={data.privilege_id}
                                                  handleChange={handleChange}
                                                  parentIndex={parentIndex}
                                                /> */}
                              <IconContext.Provider
                                value={{
                                  color: data.isSwitched
                                    ? "#44d450"
                                    : "#cc0000",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                {data.isSwitched ? (
                                  <BsCheck2Circle />
                                ) : (
                                  <RiCloseCircleLine />
                                )}
                              </IconContext.Provider>
                            </td>
                          </tr>
                          <tr
                            id={"accordion" + i}
                            className="collapse  admin-product-hided-row"
                            key={data.privilege_id + Math.random()}
                          >
                            <td colSpan="6" style={rowdataAlign}>
                              <ul
                                className="admin-product-row-table-hide-list text-left mediumFont"
                                style={{ color: "#525252" }}
                              >
                                <li
                                  key={data.privilege_id + Math.random()}
                                  style={{
                                    padding: "10px",
                                  }}
                                  // className="admin-product-row-hided-col-price"
                                >
                                  <div className="row justify-content-start ml-3">
                                    <div className="col-6  text-center">
                                      Feature Name
                                    </div>
                                    <div className="col-6  text-left">
                                      {data.feature_name}
                                    </div>
                                  </div>
                                </li>
                                <li
                                  key={data.privilege_id + Math.random()}
                                  style={{
                                    padding: "10px",
                                  }}
                                  // className="admin-product-row-hided-col-price"
                                >
                                  <div className="row justify-content-start ml-3">
                                    <div className="col-6  text-center">
                                      Enabled
                                    </div>
                                    <div className="col-6  text-left">
                                      <IconContext.Provider
                                        value={{
                                          color: data.isSwitched
                                            ? "#44d450"
                                            : "#cc0000",
                                          size: "25px",
                                          style: { marginBottom: "3px" },
                                        }}
                                      >
                                        {data.isSwitched ? (
                                          <BsCheck2Circle />
                                        ) : (
                                          <RiCloseCircleLine />
                                        )}
                                      </IconContext.Provider>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      {/* <IconContext.Provider
                          value={{ color: "#D2D2D2", size: "100px" }}
                        >
                          <MdLocalHospital />
                        </IconContext.Provider> */}
                      <p
                        style={{
                          color: "#D2D2D2",
                          padding: "20px",
                          fontSize: "20px",
                          fontFamily: "mediumFont",
                        }}
                      >
                        No Selected Roles found...
                      </p>
                    </div>
                  )}
                </tbody>
              </table>
            </section>
          </section>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <div className="carbon__buttoncontainer">
          {/* {location.state.editable ? ( */}
          <Button
            variant="contained"
            className="carbon__secondarybtn carbon__btn"
            id="admin-reportview-download-btn"
            style={{
              //   padding: "25px ",
              color: "#fff",
              //   border: "1px solid #1565d8",
              //   marginRight: "15px",
            }}
            onClick={() => setConfirmModal(false)}
          >
            <p>Cancel</p>
          </Button>
          {/* ) : null} */}

          <Button
            variant="contained"
            className="carbon__primarybtn carbon__btn"
            id="admin-reportview-download-btn"
            style={{
              //   padding: "10px 25px",

              color: "#fff",
            }}
            onClick={submitRole}
          >
            <p className="carbon__text">Yes</p>
            {/* Yes */}
          </Button>
        </div>
        {/* </Modal.Footer> */}
      </Modal>
    );
  };

  const searchItems = (event) => {
    let filteredLabs = allCenters.filter((list) => {
      return event !== null
        ? list.lab_name.toString().toLowerCase().includes(event.toLowerCase())
        : allCenters;
    });
    setCenters(filteredLabs);
  };

  return (
    <section>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      {/* Add role here */}
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={
            location.state.systemFeature
              ? yup.object().shape({
                  role_name: yup.string().required("Name cannot be empty"),
                  role_desc: yup
                    .string()
                    .required("Description cannot be empty"),
                  admin_type: yup
                    .object()
                    .required("Please Select Type")
                    .nullable(),
                  status: yup
                    .object()
                    .required("Please Select Status")
                    .nullable(),
                })
              : yup.object().shape({
                  role_name: yup.string().required("Name cannot be empty"),
                  role_desc: yup
                    .string()
                    .required("Description cannot be empty"),
                  status: yup
                    .object()
                    .required("Please Select Status")
                    .nullable(),
                })
          }
          onSubmit={async (values, formik) => {
            console.log("formik submit values", selectAdmin);

            let data = [];
            let allRoles = [];

            let sortedData = privileges.map((list) => {
              list.roles.map((role) => {
                console.log("roles", role);
                allRoles.push(role);
                if (role.isSwitched) {
                  data.push(role);
                }
              });
            });

            console.log("swicthed data", data);

            if (values.admin_type.value === 0 && selectedCenters.length === 0) {
              toast.error("Please select atleast one Center");
              return;
            } else if (data.length === 0) {
              toast.error("Please select atleast one Privilege");
              return;
            } else {
              setSelectedFeatures(data);
              setAllFeatures(allRoles);
              setConfirmModal(true);
            }
          }}
        >
          {(formik) => {
            console.log("formik", formik.errors);
            return (
              <>
                <ConfirmModal
                  formikValues={formik.values}
                  show={confirmModal}
                  onHide={() => setConfirmModal(false)}
                />
                <DeleteModal
                  show={deleteModal}
                  onHide={() => setDeleteModal(false)}
                  selectedData={location.state?.data?.role_id}
                  // refresh={setRefresh}
                  apiUrl={"/role/delete"}
                  name={"Role"}
                  // id={"category_id"}
                />

                <div className="role__continer p-3">
                  <div className="role__header mb-3">
                    <div className="d-flex align-items-center">
                      <IconButton
                        onClick={() => {
                          history.push("/admin/roles");
                        }}
                      >
                        <IconContext.Provider value={{ size: "30px" }}>
                          <FiArrowLeft />
                        </IconContext.Provider>
                      </IconButton>
                      <p
                        style={{
                          cursor: "pointer",
                          fontSize: "25px",
                          marginLeft: "10px",
                        }}
                        className="semiFont"
                      >
                        {location.state.editable ? "Edit Role" : "Add Role"}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="role_name"
                          className="semiFont login__label"
                        >
                          Role Name{required}
                        </label>
                        <input
                          type="text"
                          id="role_name"
                          value={formik.values.role_name}
                          style={{
                            border: formik.errors.role_name
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          placeholder="Role Name"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "role_name",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9-_+./,' ]/g,
                                ""
                              )
                            );
                          }}
                        />
                        {formik.errors.role_name && (
                          <div className="err">
                            <p>{formik.errors.role_name}</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                        id="a123"
                      >
                        <label
                          htmlFor="status"
                          className="semiFont login__label"
                        >
                          Status {required}
                        </label>
                        <Select
                          value={formik.values.status}
                          onChange={(p) => {
                            formik.setFieldValue("status", p);
                          }}
                          id="inputDropdown"
                          options={statusOptions}
                          placeholder={"Select Status"}
                          // className="admim__Addproduct--dropdown "
                          styles={{
                            control: (base, baseState) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "10px 10px",
                              border: formik.errors.status
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                        />

                        {formik.errors.status && (
                          <div className="err">
                            <p>{formik.errors.status}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="role_desc"
                          className="semiFont login__label"
                        >
                          Role Description{required}
                        </label>
                        <textarea
                          id="role_desc"
                          rows="5"
                          style={{
                            border: formik.errors.role_desc
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "10px",
                            width: "auto",
                            resize: "none",
                            borderRadius: "8px",
                            padding: "10px",
                            color: "#183b56",
                            fontSize: "17px",
                            fontFamily: "mediumFont",
                          }}
                          placeholder="Description"
                          value={formik.values.role_desc}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "role_desc",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9-_+.,/' ]/g,
                                ""
                              )
                            );
                          }}
                        ></textarea>
                        {formik.errors.role_desc && (
                          <div className="err">
                            <p>{formik.errors.role_desc}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="row"> */}

                  {/* </div> */}
                  {location.state.systemFeature ? (
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="select_admin"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Select Admin{required}
                          </label>
                          <Select
                            value={formik.values.admin_type}
                            onChange={(p) => {
                              console.log("PPPPPPPPPPPPPPP", p);
                              formik.setFieldValue("admin_type", p);
                            }}
                            id="inputDropdown"
                            placeholder="Select Admin Type"
                            options={adminOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.admin_type
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.admin_type && (
                            <div className="err">
                              <p>{formik.errors.admin_type}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {(formik.values.admin_type &&
                    !formik.values.admin_type.value) ||
                  location.state.systemFeature === false ? (
                    <div className={classes.root}>
                      <p className="boldFont my-2 section__title">
                        Select Center
                      </p>

                      <Accordion>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon htmlColor="#0F61FD" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="acc__title">
                            {selectedCenters.length} Hospital Selected
                          </p>
                          {/* <button type="button" onClick={() => alert("hello")}>
                            click here
                          </button> */}
                        </AccordionSummary>
                        <AccordionDetails style={{ flexDirection: "column" }}>
                          <section className=" d-flex justify-content-end">
                            <div
                              className="filter__container"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="text"
                                className="filter__input"
                                placeholder="Search Lab"
                                onChange={(e) => {
                                  searchItems(e.target.value);
                                }}
                              />
                              <IconContext.Provider
                                value={{
                                  size: "25px",
                                  color: "#333",
                                }}
                              >
                                <IoSearchOutline
                                  className="filter-search-icon"
                                  color="#818181"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContext.Provider>
                            </div>
                          </section>

                          <section
                            className="mt-2 admin-product-filter-section table-responsive fixHeader 
"
                          >
                            <table
                              className="table  text-center  admin-product_usertable mb-0"
                              style={{
                                backgroundColor: "white",
                              }}
                              id="bill-table"
                            >
                              <thead>
                                <tr
                                  id="admin-product-row"
                                  style={{
                                    border: "none",
                                    backgroundColor: "#E0E0E0",
                                  }}
                                >
                                  <th
                                    scope="col"
                                    className="admin-product-row_table-header"
                                  >
                                    <div
                                      style={{ position: "relative" }}
                                      className="d-flex align-items-center justify-content-center"
                                    >
                                      <input
                                        // className="form-check-input"
                                        type="checkbox"
                                        name="product_id"
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          // marginRight: "10px",
                                        }}
                                        // value={Number(data.lab_id)}
                                        onChange={(e) => {
                                          const { checked, value } = e.target;

                                          console.log("chceking", [
                                            checked,
                                            value,
                                          ]);
                                          if (checked) {
                                            setSelectedCenters(
                                              centers &&
                                                centers.map(
                                                  (item) => item.lab_id
                                                )
                                            );
                                          } else {
                                            setSelectedCenters([]);
                                          }
                                        }}
                                        // checked={
                                        //   selectedCenters.map
                                        // }
                                      />
                                      {/* <label
                                      htmlFor="Select-all"
                                      className="form-check-label"
                                    >
                                      {" "}
                                      All
                                    </label> */}
                                    </div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="admin-product-row_table-header"
                                  >
                                    Hospital Id
                                  </th>
                                  <th
                                    scope="col"
                                    className="admin-product-row-table-header admin-product-row-price-col"
                                  >
                                    Hospital Name
                                  </th>

                                  <th
                                    scope="col"
                                    className="admin-product-row-table-header   admin-product-row-status-col"
                                  >
                                    City
                                  </th>
                                  <th
                                    scope="col"
                                    className="admin-product-row-table-header  admin-product-row-status-col"
                                  >
                                    Location
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="fixedBody">
                                {centers.length > 0 ? (
                                  centers.map((data, i) => {
                                    const borderBottom = `1px solid #B3CDFF`;
                                    return (
                                      <>
                                        <tr
                                          key={data.lab_id + Math.random()}
                                          style={{
                                            //   borderLeft,
                                            borderBottom,
                                            backgroundColor: "white",
                                          }}
                                          className="admin-product-row-table-row-hover modal__table"
                                        >
                                          {" "}
                                          <td
                                            className="admin-product-row-table-row-data mediumFont"
                                            style={rowdataAlign}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <input
                                                // className="form-check-input"
                                                type="checkbox"
                                                name="product_id"
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                }}
                                                value={Number(data.lab_id)}
                                                onChange={(e) => {
                                                  const { checked, value } =
                                                    e.target;

                                                  console.log("chceking", [
                                                    checked,
                                                    value,
                                                  ]);
                                                  if (checked) {
                                                    setSelectedCenters([
                                                      ...selectedCenters,
                                                      Number(value),
                                                    ]);
                                                  } else {
                                                    setSelectedCenters(
                                                      selectedCenters &&
                                                        selectedCenters.filter(
                                                          (v) =>
                                                            Number(v) !==
                                                            Number(value)
                                                        )
                                                    );
                                                  }
                                                }}
                                                checked={
                                                  selectedCenters &&
                                                  selectedCenters.includes(
                                                    Number(data.lab_id)
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            scope="row"
                                            id="admin-product-rd"
                                            className="admin-product-row-table-row-data mediumFont"
                                            style={rowdataAlign}
                                          >
                                            <p
                                              data-toggle="collapse"
                                              data-target={`#accordion` + i}
                                              className="clickable"
                                              id="button-open"
                                              // style={{ display: "none" }}
                                            >
                                              <IconContext.Provider
                                                value={{
                                                  color: "#4059E2",
                                                  size: "25px",
                                                  style: {
                                                    marginBottom: "3px",
                                                  },
                                                }}
                                              >
                                                <IoMdAddCircle className="toggle-row-icon" />
                                              </IconContext.Provider>
                                            </p>{" "}
                                            LID{data.lab_id}
                                          </td>
                                          <td
                                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                                            style={rowdataAlign}
                                          >
                                            {data.lab_name}
                                          </td>
                                          <td
                                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                            style={rowdataAlign}
                                          >
                                            {data.city}
                                          </td>
                                          <td
                                            className="admin-product-row-table-row-data  admin-product-row-status-col mediumFont"
                                            style={rowdataAlign}
                                          >
                                            {data.state}, {data.country}
                                          </td>
                                        </tr>
                                        <tr
                                          id={"accordion" + i}
                                          className="collapse  admin-product-hided-row"
                                          key={data.lab_id + Math.random()}
                                        >
                                          <td colSpan="6" style={rowdataAlign}>
                                            <ul
                                              className="admin-product-row-table-hide-list text-left mediumFont"
                                              style={{ color: "#525252" }}
                                            >
                                              <li
                                                key={
                                                  i +
                                                  data.lab_id +
                                                  i +
                                                  Math.random()
                                                }
                                                style={{
                                                  // borderBottom: "1px solid #B3CDFF",
                                                  padding: "10px",
                                                }}
                                                className="admin-product-row-hided-col-price"
                                              >
                                                <div className="row justify-content-start ml-3">
                                                  <div className="col-6  text-center">
                                                    Hospital Name:
                                                  </div>
                                                  <div className="col-6  text-left">
                                                    {data.lab_name}
                                                  </div>
                                                </div>
                                              </li>
                                              <li
                                                key={
                                                  i +
                                                  data.lab_id +
                                                  i +
                                                  Math.random()
                                                }
                                                style={{
                                                  // borderBottom: "1px solid #B3CDFF",
                                                  padding: "10px",
                                                }}
                                                // className="admin-product-row-hided-col-price"
                                              >
                                                <div className="row justify-content-start ml-3">
                                                  <div className="col-6  text-center">
                                                    City:
                                                  </div>
                                                  <div className="col-6  text-left">
                                                    {data.city}
                                                  </div>
                                                </div>
                                              </li>
                                              <li
                                                key={
                                                  i +
                                                  data.lab_id +
                                                  i +
                                                  Math.random()
                                                }
                                                style={{
                                                  // borderBottom: "1px solid #B3CDFF",
                                                  padding: "10px",
                                                }}
                                              >
                                                <div className="row justify-content-start ml-3">
                                                  <div className="col-6  text-center">
                                                    Location:
                                                  </div>
                                                  <div className="col-6  text-left">
                                                    {data.state}, {data.country}
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })
                                ) : (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      padding: "20px",
                                    }}
                                  >
                                    {/* <IconContext.Provider
                          value={{ color: "#D2D2D2", size: "100px" }}
                        >
                          <MdLocalHospital />
                        </IconContext.Provider> */}
                                    <p
                                      style={{
                                        color: "#D2D2D2",
                                        padding: "20px",
                                        fontSize: "20px",
                                        fontFamily: "mediumFont",
                                      }}
                                    >
                                      No Selected Roles found...
                                    </p>
                                  </div>
                                )}
                              </tbody>
                            </table>
                          </section>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ) : null}

                  <div className={classes.root}>
                    <p className="boldFont my-3 section__title">
                      Select Features and privileges
                    </p>
                    {privileges &&
                      privileges.map((item, parentIndex) => {
                        return (
                          <>
                            <Accordion key={parentIndex}>
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon htmlColor="#0F61FD" />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <p className="acc__title">
                                  {item.feature_name
                                    .toLowerCase()
                                    .replace(/./, (name) => name.toUpperCase())}
                                </p>
                              </AccordionSummary>
                              <AccordionDetails>
                                <section
                                  className="admin-product-filter-section table-responsive fixHeader
"
                                >
                                  <table
                                    className="table  text-center  admin-product_usertable mb-0"
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                    id="bill-table"
                                  >
                                    <thead>
                                      <tr
                                        id="admin-product-row"
                                        style={{
                                          border: "none",
                                          backgroundColor: "#E0E0E0",
                                        }}
                                      >
                                        <th
                                          scope="col"
                                          className="admin-product-row_table-header"
                                        >
                                          Privilege ID
                                        </th>
                                        <th
                                          scope="col"
                                          className="admin-product-row-table-header"
                                        >
                                          Privilege Name
                                        </th>
                                        <th
                                          scope="col"
                                          className="admin-product-row-table-header admin-product-row-status-col"
                                        >
                                          ON / OFF
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.roles.length > 0 ? (
                                        item.roles.map((data, i) => {
                                          const borderBottom = `1px solid #B3CDFF`;
                                          return (
                                            <>
                                              <tr
                                                key={
                                                  data.privilege_id +
                                                  Math.random()
                                                }
                                                style={{
                                                  //   borderLeft,
                                                  borderBottom,
                                                  backgroundColor: "white",
                                                }}
                                                className="admin-product-row-table-row-hover "
                                              >
                                                <td
                                                  scope="row"
                                                  id="admin-product-rd"
                                                  className="admin-product-row-table-row-data mediumFont"
                                                  style={rowdataAlign}
                                                >
                                                  <p
                                                    data-toggle="collapse"
                                                    data-target={
                                                      `#accordion` + i
                                                    }
                                                    className="clickable"
                                                    id="button-open"
                                                    // style={{ display: "none" }}
                                                  >
                                                    <IconContext.Provider
                                                      value={{
                                                        color: "#4059E2",
                                                        size: "25px",
                                                        style: {
                                                          marginBottom: "3px",
                                                        },
                                                      }}
                                                    >
                                                      <IoMdAddCircle className="toggle-row-icon" />
                                                    </IconContext.Provider>
                                                  </p>{" "}
                                                  PID{data.privilege_id}
                                                </td>
                                                <td
                                                  className="admin-product-row-table-row-data mediumFont"
                                                  style={rowdataAlign}
                                                >
                                                  {" "}
                                                  {data.privilege_name}
                                                </td>
                                                <td
                                                  className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                                  style={rowdataAlign}
                                                >
                                                  <SwitchButton
                                                    index={i}
                                                    data={data}
                                                    id={data.privilege_id}
                                                    handleChange={handleChange}
                                                    parentIndex={parentIndex}
                                                  />
                                                </td>
                                              </tr>
                                              <tr
                                                id={"accordion" + i}
                                                className="collapse  admin-product-hided-row"
                                                key={
                                                  data.privilege_id +
                                                  Math.random()
                                                }
                                              >
                                                <td
                                                  colSpan="6"
                                                  style={rowdataAlign}
                                                >
                                                  <ul
                                                    className="admin-product-row-table-hide-list text-left mediumFont"
                                                    style={{ color: "#525252" }}
                                                  >
                                                    <li
                                                      key={
                                                        data.privilege_id +
                                                        Math.random()
                                                      }
                                                      style={{
                                                        padding: "10px",
                                                      }}
                                                    >
                                                      <div className="row justify-content-start align-items-center ml-3 ">
                                                        <div className="col-6  text-center">
                                                          ON / OFF
                                                        </div>
                                                        <div className="col-6  text-left">
                                                          <SwitchButton
                                                            index={i}
                                                            data={data}
                                                            id={
                                                              data.privilege_id
                                                            }
                                                            handleChange={
                                                              handleChange
                                                            }
                                                            parentIndex={
                                                              parentIndex
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan={6}>
                                            {" "}
                                            <div
                                              style={{
                                                textAlign: "center",
                                                padding: "20px",
                                              }}
                                            >
                                              {/* <IconContext.Provider
                          value={{ color: "#D2D2D2", size: "100px" }}
                        >
                          <MdLocalHospital />
                        </IconContext.Provider> */}
                                              <p
                                                style={{
                                                  color: "#D2D2D2",
                                                  padding: "20px",
                                                  fontSize: "20px",
                                                  fontFamily: "mediumFont",
                                                }}
                                              >
                                                No Privileges found...
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </section>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      })}
                  </div>
                  <div className="button__container mt-4 d-flex align-items-center justify-content-end">
                    {location.state.deletable ? (
                      <Button
                        variant="contained"
                        className="secondary__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          padding: "10px 25px",
                          width: "150px",
                          color: "#cc0000",
                          //   border: "1px solid #cc0000",
                          marginRight: "15px",
                        }}
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      className="primary__btn"
                      id="admin-reportview-download-btn"
                      style={{
                        padding: "10px 25px",
                        width: "150px",
                        color: "#fff",
                      }}
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </section>
  );
};

export default RoleEdit;
