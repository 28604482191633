import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Global from "../global";
import "../assets/css/home.css";
import _ from "lodash";
import Completed from "../assets/images/completed.png";
import Inprogress from "../assets/images/inprogress.png";
import TotalTest from "../assets/images/total_test.png";
import Revenue from "../assets/images/revenue.png";
import emptyTrending from "../assets/images/empty-trending.png";
import Lab from "../assets/images/lab.png";
import Loader from "react-loader-spinner";

const Home = () => {
  const [trending, setTrending] = useState([]);
  const [revenue, setRevenue] = useState("");
  const [test, setTest] = useState("");
  const [inprogress, setInprogress] = useState("");
  const [labs, setLabs] = useState("");
  const [loading, setLoading] = useState(true);
  const [dashboardAccess, setDashboardAccess] = useState({});
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const checkDashboard = await axios.post(
          Global.base_url + "/dashboard/check/privileges",
          null,
          config
        );
        // console.log(checkDashboard);

        if (checkDashboard.status === 200) {
          console.log("check dash", checkDashboard);
          setDashboardAccess(checkDashboard.data);
          if (
            _.some(checkDashboard.data.privileges, [
              "privilege_name",
              "TRENDING TEST DASHLET",
            ])
          ) {
            try {
              const trendingData = await axios.post(
                Global.base_url + "/dashboard/trending",
                null,
                config
              );
              // console.log(response);

              if (trendingData.status === 200) {
                console.log("trendingData", trendingData);
                setTrending(trendingData.data.trending);
              }
            } catch (error) {
              if (error.trendingData) {
                if (error.trendingData.status === 401) {
                  return toast.error("Unable to get tranding dashlet");
                }
                if (error.trendingData.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }

          if (
            _.some(checkDashboard.data.privileges, [
              "privilege_name",
              "TOTAL INPROGRESS DASHLET",
            ])
          ) {
            try {
              const inprogressData = await axios.post(
                Global.base_url + "/dashboard/inprogress",
                null,
                config
              );
              // console.log(response);

              if (inprogressData.status === 200) {
                console.log("inprogressData", inprogressData);
                setInprogress(inprogressData.data.inprogress);
                // setTrending(inprogressData.data.trending);
              }
            } catch (error) {
              if (error.inprogressData) {
                if (error.inprogressData.status === 401) {
                  return toast.error("Unable to get inprogress dashlet");
                }
                if (error.inprogressData.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }
          if (
            _.some(checkDashboard.data.privileges, [
              "privilege_name",
              "TOTAL LABS DASHLET",
            ])
          ) {
            try {
              const labData = await axios.post(
                Global.base_url + "/dashboard/totallab",
                null,
                config
              );
              // console.log(response);

              if (labData.status === 200) {
                console.log("labData", labData);
                setLabs(labData.data.labs);
                // setTrending(labData.data.trending);
              }
            } catch (error) {
              if (error.labData) {
                if (error.labData.status === 401) {
                  return toast.error("Unable to get labs dashlet");
                }
                if (error.labData.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }

          if (
            _.some(checkDashboard.data.privileges, [
              "privilege_name",
              "TOTAL INCOME DASHLET",
            ])
          ) {
            try {
              const revenueData = await axios.post(
                Global.base_url + "/dashboard/revenue",
                null,
                config
              );
              // console.log(response);

              if (revenueData.status === 200) {
                console.log("revenueData", revenueData);
                setRevenue(revenueData.data.revenue);
                // setTrending(revenueData.data.trending);
              }
            } catch (error) {
              if (error.revenueData) {
                if (error.revenueData.status === 401) {
                  return toast.error("Unable to get revenue dashlet");
                }
                if (error.revenueData.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }

          if (
            _.some(checkDashboard.data.privileges, [
              "privilege_name",
              "TOTAL TEST DASHLET",
            ])
          ) {
            try {
              const testData = await axios.post(
                Global.base_url + "/dashboard/totaltest",
                null,
                config
              );
              // console.log(response);

              if (testData.status === 200) {
                console.log("testData", testData);
                setTest(testData.data.test);
                // setTrending(testData.data.trending);
              }
            } catch (error) {
              if (error.testData) {
                if (error.testData.status === 401) {
                  return toast.error("Unable to get test dashlet");
                }
                if (error.testData.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }

          return setLoading(false);
          // setDashboardAccess();
        }
      } catch (error) {
        if (error.checkDashboard) {
          if (error.checkDashboard.status === 401) {
            toast.error("Unable to get tests");
            return setLoading(false);
          }
          if (error.checkDashboard.status === 500) {
            toast.error("Internal server error");
            return setLoading(false);
          }
        }
      }
    };
    getDashboardData();
  }, []);
  return (
    <div className="home__container">
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div className="home__reportContainer">
          <div className="status__wrapper">
            <div className="status__container">
              {_.some(dashboardAccess.privileges, [
                "privilege_name",
                "TOTAL INCOME DASHLET",
              ]) ? (
                <div className="status__itemsConatiner">
                  <p className="status__title">Revenue</p>
                  <div className="status__detailsRow">
                    <p className="status__count"> &#8377; {revenue}</p>
                    <img src={Revenue} alt="Test completed" />
                  </div>
                </div>
              ) : null}
              {_.some(dashboardAccess.privileges, [
                "privilege_name",
                "TOTAL INPROGRESS DASHLET",
              ]) ? (
                <div className="status__itemsConatiner">
                  <p className="status__title">In Progress</p>
                  <div className="status__detailsRow">
                    <p className="status__count">{inprogress}</p>
                    <img src={Inprogress} alt="Test inprogress" />
                  </div>
                </div>
              ) : null}
              {_.some(dashboardAccess.privileges, [
                "privilege_name",
                "TOTAL TEST DASHLET",
              ]) ? (
                <div className="status__itemsConatiner">
                  <p className="status__title">Total Tests</p>
                  <div className="status__detailsRow">
                    <p className="status__count">{test}</p>
                    <img src={TotalTest} alt="Total test" />
                  </div>
                </div>
              ) : null}
              {_.some(dashboardAccess.privileges, [
                "privilege_name",
                "TOTAL LABS DASHLET",
              ]) ? (
                <div className="status__itemsConatiner">
                  <p className="status__title">Total Labs</p>
                  <div className="status__detailsRow">
                    <p className="status__count">{labs}</p>
                    <img src={Lab} alt="Total test" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {_.some(dashboardAccess.privileges, [
            "privilege_name",
            "TRENDING TEST DASHLET",
          ]) ? (
            <div className="reports__container">
              <div className="report__header mx-2 mt-2">
                <p>Trending</p>
              </div>
              <div className="reports__details p-2 mt-2">
                <p className="report__headerTitle name">ID</p>
                <p className="report__headerTitle package">Package / Test</p>
                <p className="report__headerTitle days">Total</p>
              </div>
              {trending.length ? (
                trending.map((item, i) => {
                  return (
                    <div className="reports__details-data" key={i}>
                      <p className="report__data name">
                        {item.package_id || item.test_id}
                        {/* hello */}
                      </p>
                      <p className="report__data package">
                        {item.package_name || item.test_name}
                        {/* test data */}
                      </p>
                      <p className="report__data days">
                        {item.packagecount || item.testcount}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div
                  className="center__items p-5"
                  style={{ flexDirection: "column" }}
                >
                  <img src={emptyTrending} alt="Empty appointment" />
                  <p style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}>
                    Trending not found
                  </p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Home;
