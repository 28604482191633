import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Header from "./Header";
import { IconContext } from "react-icons";
import { Button } from "@material-ui/core";

// import logo from "../assets/images/Logo.png";
// import profilePic from "../assets/images/userImage.png";
import "../assets/css/settings.css";
import axios from "axios";
import Global from "../global";
import Loader from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import "yup-phone";
import _ from "lodash";

import { GlobalContext } from "../context/Context";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      padding: 0,
      backgroundColor: "#fff",

      "& .MuiInputBase-input": {
        padding: 15,
        // borderRadius: 15,

        backgroundColor: "#fff",
      },
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const requiredField = <span style={{ color: "red" }}>*</span>;

const Settings = (props) => {
  // console.log("settings props", props);
  const [progress, setProgress] = useState("");
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);

  const [field, addField] = useState(false);
  const [refresh, setRefresh] = useState("");
  // const [image_url, setImage_url] = useState(User);
  const [user, setUser] = useState({});
  const aadhaarRegex = "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$";
  const hiddenFileInput = React.useRef(null);
  const [securetext, setSecuretext] = useState(true);
  const [securetext2, setSecuretext2] = useState(true);
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  const { image_url, setImage_url } = useContext(GlobalContext);

  // console.log("context data", image_data);
  useEffect(() => {
    // console.log("refreshh");
    async function fetchUser() {
      try {
        const response = await axios.post(Global.base_url + "/profile", null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        // console.log(response);

        if (response.status === 200) {
          setUser(response.data.data);

          try {
            const imageResponse = await axios.post(
              Global.base_url + "/profile/image/load",
              null,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
              }
            );
            // console.log(response);

            if (imageResponse.status === 200) {
              // setImage_url(imageResponse.data);
              let imageBlob = URL.createObjectURL(imageResponse.data);
              console.log("image get reponse", imageBlob);
              // toast.error("Image success");
              setImage_url(imageBlob);
            }
          } catch (error) {
            if (error.imageResponse) {
              if (error.imageResponse.status === 401) {
                toast.error("Unable to get admin image");
                return;
              }
            }
          }

          console.log("Profile details", response);

          return setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("Unable to get admin details");
            return;
          }
          if (error.response.status === 500) {
            toast.error("Internal server error");
            return;
          }
        }
      }
    }

    fetchUser();
  }, [refresh]);
  // console.log("User", user);
  const FILE_SIZE = 5000000;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg"];
  const handleChange = async (event) => {
    // setUploader(true);
    const files = event.target.files;

    if (files.length === 0) {
      // console.log("cancelled");
      return null;
    }
    // console.log("upload file", files);
    // console.log(eventId);
    // setLoading(true);
    // console.log(files[0].type.split("/")[1]);

    if (
      !(
        files[0].type.split("/")[1] === "jpg" ||
        files[0].type.split("/")[1] === "jpeg" ||
        files[0].type.split("/")[1] === "png"
      )
    ) {
      return toast.error("Please upload a jpg/jpeg/png file");
    }

    const formData = new FormData();
    formData.append("admin_image", files[0], `user-${user.user_id}`);

    console.log("upload image", files[0]);
    try {
      const response = await axios.post(
        Global.base_url + "/profile/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (data) => {
            // console.log("progress", data.loaded / data.total);
            setProgress(Math.floor((100 * data.loaded) / data.total));
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        setRefresh(Date.now() + 1);
        toast.success("Admin image Updated ..");
      }
      // return setUploader(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Image upload failed");
      }

      // setUploader(false);
    }
  };

  const validationSchema = yup.object({
    firstname: yup.string().required("Firstname cannot be empty"),
    lastname: yup.string().required("Lastname cannot be empty"),
    oldPassword: yup
      .string()
      .min(8, "Old password must be at least 8 characters")
      .max(15),
    newPassword: yup.string().when("oldPassword", (oldPassword) => {
      if (oldPassword)
        return yup
          .string()
          .required("New password cannot be empty")
          .notOneOf(
            [yup.ref("oldPassword"), ""],
            "New password must not be same as old password"
          )
          .notOneOf(["password"], "Password must not be 'password'")
          .min(8)
          .max(15);
    }),
    confirmPassword: yup
      .string()
      .when(["oldPassword", "newPassword"], (oldPassword, newPassword) => {
        if (oldPassword || newPassword)
          return yup
            .string()
            .required("Confirm password cannot be empty")
            .oneOf(
              [yup.ref("newPassword")],
              "Confirm password must be same as new password"
            )
            .min(8)
            .max(15);
      }),
  });
  const initialValues = {
    firstname: user.firstname,
    lastname: user.lastname,

    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <>
      <>
        <section className="main__container p-4">
          <>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              // newestOnTop={false}
              closeOnClick
              rtl={false}
            />

            <h3 className="semiFont avator__userName">
              {`${user.firstname} ${user.lastname}`}
            </h3>
            <div>
              <div
                className="image__container mt-3"
                style={{ position: "relative" }}
              >
                <div>
                  <div className="image_container ">
                    <img
                      src={image_url}
                      alt="user"
                      className="profile_image shadow"
                    />
                  </div>
                  {progress > 0 && progress < 100 && (
                    <ProgressBar now={progress} label={`${progress} %`} />
                  )}
                </div>

                <div style={{ marginLeft: "15px" }}>
                  <Button
                    // variant="contained"
                    // color="primary"
                    className={"tab__items"}
                    style={{ width: "auto" }}
                    //   disabled={true}
                    onClick={() => hiddenFileInput.current.click()}
                  >
                    Change Photo
                  </Button>
                </div>
              </div>
            </div>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept="image/*"
            />

            <section className="tab__container mt-5 d-flex">
              <Button
                className={active === 0 ? "tab__items--active" : "tab__items"}
                variant={active === 0 ? "contained" : ""}
                onClick={() => setActive(0)}
              >
                Personal
              </Button>

              <Button
                className={active === 1 ? "tab__items--active" : "tab__items"}
                onClick={() => setActive(1)}
                variant={active === 1 ? "contained" : ""}
              >
                Security
              </Button>
            </section>

            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={async (values, formik) => {
                // console.log("submiit");
                console.log(values);

                if (values.newPassword && values.oldPassword === "") {
                  return formik.setFieldError(
                    "oldpassword",
                    "old password is required"
                  );
                }
                formik.setSubmitting(true);
                let keys = Object.keys(values);
                const formData = new FormData();
                keys.map((key) => {
                  console.log("userdata", values[key]);
                  formData.append(key, values[key]);
                });

                // formData.append(
                //   "user_image",y
                //   values.user_image,
                //   `user-${user.user_id}`
                // );

                // return;
                try {
                  const response = await axios.post(
                    Global.base_url + "/profile/update",
                    values,
                    config2
                  );
                  // console.log(response);

                  if (response.status === 200) {
                    formik.resetForm(initialValues);
                    toast.success("admin details Updated");
                    // formik.setSubmitting(false);
                    return setRefresh(Date.now() + 1);
                  }
                } catch (error) {
                  if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.msg);
                  }
                  if (error.response && error.response.status === 403) {
                    if (error.response.data.code === 1) {
                      formik.setFieldError(
                        "oldPassword",
                        "old password mismatch"
                      );
                    }
                    /*  if (error.response.data.code === 2) {
                      formik.setFieldError(
                        "aadhaar",
                        "Aadhaar already registered"
                      );
                    } */
                    formik.setSubmitting(false);
                    return console.log(error);
                  }
                }
              }}
            >
              {(formik) => {
                // console.log("values", formik.errors);

                return (
                  <>
                    {active === 0 ? (
                      <div className="active__container mt-4">
                        <div className="row m-0 ">
                          <div className="col-12 p-0">
                            {/* <div className="row">
                        <div className=" col-11 offset-1 col-sm-10 offset-sm-2 col-md-12 offset-md-0">
                          <h6 className="">{requiredField} required Fields</h6>
                        </div>
                      </div> */}

                            <div className="row align-767">
                              <div className="col-lg-6 col-xl-6 col-md-6  col-12">
                                <div
                                  className="inputBox"
                                  style={{
                                    position: "relative",
                                    marginTop: 0,
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    htmlFor="firstname"
                                    className="semiFont login__label"
                                  >
                                    First Name {requiredField}
                                  </label>
                                  <input
                                    type="text"
                                    value={formik.values.firstname}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "firstname",
                                        e.currentTarget.value.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        )
                                      );
                                    }}
                                    style={{
                                      border: formik.errors.firstname
                                        ? "1px solid red"
                                        : "1px solid #c3cad9",
                                      marginTop: "5px",
                                      width: "auto",
                                    }}
                                    id="Firstname"
                                    aria-describedby="firstname"
                                    placeholder="First name"
                                  />
                                  {formik.errors.firstname && (
                                    <p className="err">
                                      {formik.errors.firstname}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-6 col-md-6  col-12">
                                <div
                                  className="inputBox"
                                  style={{
                                    position: "relative",
                                    marginTop: 0,
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    htmlFor="lastname"
                                    className="semiFont login__label"
                                  >
                                    Last Name {requiredField}
                                  </label>
                                  <input
                                    type="text"
                                    value={formik.values.lastname}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "lastname",
                                        e.currentTarget.value.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        )
                                      );
                                    }}
                                    style={{
                                      border: formik.errors.lastname
                                        ? "1px solid red"
                                        : "1px solid #c3cad9",
                                      marginTop: "5px",
                                      width: "auto",
                                    }}
                                    id="lastname"
                                    aria-describedby="lastname"
                                    placeholder="last name"
                                  />
                                  {formik.errors.lastname && (
                                    <p className="err">
                                      {formik.errors.lastname}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 ml-2">
                          <Button
                            className={
                              formik.dirty
                                ? "primary__btn"
                                : "primary__btn--disabled"
                            }
                            type="contained"
                            style={{
                              padding: "8px 30px",
                              color: formik.dirty
                                ? "#fff"
                                : "rgba(0, 0, 0, 0.26)",
                            }}
                            disabled={!formik.dirty}
                            onClick={function (e) {
                              formik.handleSubmit();
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    ) : null}

                    {active === 1 ? (
                      <div className="active__container mt-4">
                        {/* <> */}
                        <div className="col-12 p-0 ">
                          <div className="row ">
                            <div className="col-lg-6 col-xl-6 col-md-6  col-12">
                              <div
                                className="inputBox"
                                style={{
                                  position: "relative",
                                  marginTop: 0,
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="oldPassword"
                                  className="semiFont login__label"
                                >
                                  Old Password {requiredField}
                                </label>
                                <input
                                  type="text"
                                  maxLength={15}
                                  value={formik.values.oldPassword}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "oldPassword",
                                      e.currentTarget.value.replace(
                                        /[^a-zA-Z0-9@]/g,
                                        ""
                                      )
                                    );
                                  }}
                                  style={{
                                    border: formik.errors.oldPassword
                                      ? "1px solid red"
                                      : "1px solid #c3cad9",
                                    marginTop: "5px",
                                    width: "auto",
                                  }}
                                  id="oldPassword"
                                  aria-describedby="oldPassword"
                                  placeholder="Old password"
                                />
                                {formik.errors.oldPassword && (
                                  <p className="err">
                                    {formik.errors.oldPassword}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-6 col-md-6  col-12">
                              <div
                                className="inputBox"
                                style={{
                                  position: "relative",
                                  marginTop: 0,
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="newPassword"
                                  className="semiFont login__label"
                                >
                                  New Password {requiredField}
                                </label>
                                <input
                                  type={securetext ? "password" : "text"}
                                  maxLength={15}
                                  value={formik.values.newPassword}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "newPassword",
                                      e.currentTarget.value.replace(
                                        /[^a-zA-Z0-9@]/g,
                                        ""
                                      )
                                    );
                                  }}
                                  style={{
                                    border: formik.errors.newPassword
                                      ? "1px solid red"
                                      : "1px solid #c3cad9",
                                    marginTop: "5px",
                                    width: "auto",
                                  }}
                                  id="newPassword"
                                  aria-describedby="newPassword"
                                  placeholder="New password"
                                />
                                <div
                                  className="signuppwd__icon"
                                  onClick={(e) =>
                                    securetext
                                      ? setSecuretext(false)
                                      : setSecuretext(true)
                                  }
                                >
                                  <IconContext.Provider
                                    value={{
                                      color: "#959EAD",
                                      size: "20px",
                                    }}
                                  >
                                    {securetext ? <VscEye /> : <VscEyeClosed />}
                                  </IconContext.Provider>
                                </div>

                                {formik.errors.newPassword && (
                                  <p className="err">
                                    {formik.errors.newPassword}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-6 col-md-6  col-12">
                              <div
                                className="inputBox"
                                style={{
                                  position: "relative",
                                  marginTop: 0,
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="confirmPassword"
                                  className="semiFont login__label"
                                >
                                  Confirm Password {requiredField}
                                </label>
                                <input
                                  type={securetext2 ? "password" : "text"}
                                  maxLength={15}
                                  value={formik.values.confirmPassword}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "confirmPassword",
                                      e.currentTarget.value.replace(
                                        /[^a-zA-Z0-9@]/g,
                                        ""
                                      )
                                    );
                                  }}
                                  style={{
                                    border: formik.errors.confirmPassword
                                      ? "1px solid red"
                                      : "1px solid #c3cad9",
                                    marginTop: "5px",
                                    width: "auto",
                                  }}
                                  id="confirmPassword"
                                  aria-describedby="confirmPassword"
                                  placeholder="Confirm passsword"
                                />
                                <div
                                  className="signuppwd__icon"
                                  onClick={(e) =>
                                    securetext2
                                      ? setSecuretext2(false)
                                      : setSecuretext2(true)
                                  }
                                >
                                  <IconContext.Provider
                                    value={{
                                      color: "#959EAD",
                                      size: "20px",
                                    }}
                                  >
                                    {securetext2 ? (
                                      <VscEye />
                                    ) : (
                                      <VscEyeClosed />
                                    )}
                                  </IconContext.Provider>
                                </div>

                                {formik.errors.confirmPassword && (
                                  <p className="err">
                                    {formik.errors.confirmPassword}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="mt-2 ml-2">
                            <Button
                              className={
                                formik.dirty
                                  ? "primary__btn"
                                  : "primary__btn--disabled"
                              }
                              type="contained"
                              style={{
                                padding: "8px 30px",
                                color: formik.dirty
                                  ? "#fff"
                                  : "rgba(0, 0, 0, 0.26)",
                              }}
                              disabled={!formik.dirty}
                              onClick={function (e) {
                                formik.handleSubmit();
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
                // );
              }}
            </Formik>
          </>
        </section>
      </>
    </>
  );
};

export default Settings;
