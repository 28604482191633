const config = {
  headers: {
    Authorization: `Bearer "${localStorage.getItem("token")}"`,
  },
};
const config2 = {
  headers: {
    Authorization: `Bearer "${localStorage.getItem("token")}"`,
    "Content-Type": "application/json",
  },
};

module.exports = {
  token: localStorage.getItem("token"),
  // base_url: "http://localhost:4000/api/admin",
  // base_url: "http://192.168.1.9:4000/api/admin",
  // base_url: "https://ampathapi.prodmirror.com/api/admin",
  base_url: "https://hmobw.mgmhealthcare.in/api/admin",
  appointment_base_url: "https://hmobw.mgmhealthcare.in/api",
  headers: { Accept: "*/*", "Content-type": "application/json; charset=UTF-8" },
  user_auth: "",
  config,
  config2,
};
