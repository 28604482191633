import { createContext, useReducer, useMemo, useState } from "react";
import User from "../assets/images/user.png";

const initialState = {
  test: "hello world",
  token: null,
};

const appReducer = (state, action) => {
  console.log("reducer values", action);
  switch (action.type) {
    case "UPDATE_EMAIL":
      return {
        ...state,
        test: action.test,
        token: action.token,
      };

    default:
      return state;
  }
};

export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [image_url, setImage_url] = useState(User);

  const userContext = useMemo(
    () => ({
      // UPDATE_EMAIL
      updateEmail: async (data) => {
        console.log("dispatch Data", data);

        dispatch({
          type: "UPDATE_EMAIL",
          test: data.test,
          token: data.token,
        });
      },
    }),
    [state.token]
  );

  // const values = { adminRole, setAdminRole, screens, setScreens };

  return (
    <GlobalContext.Provider
      value={{ ...state, ...userContext, image_url, setImage_url }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
