import axios from "axios";
import Global from "../global";
import { Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

const DeleteModal = ({
  show,
  onHide,
  selectedData,
  refresh,
  apiUrl,
  name,
  //   id,
}) => {
  const history = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // console.log("selectedData", selectedData);
  const deleteItem = async () => {
    const ID =
      name === "Category"
        ? {
            category_id: selectedData,
          }
        : name === "Test type"
        ? {
            type_id: selectedData,
          }
        : name === "Test"
        ? { test_id: selectedData }
        : name === "Package"
        ? {
            package_id: selectedData,
          }
        : name === "Lab"
        ? { lab_id: selectedData }
        : name === "Member"
        ? { admin_id: selectedData }
        : name === "Role"
        ? {
            role_id: selectedData,
          }
        : name === "Doctor"
        ? {
            doctor_id: selectedData,
          }
        : name === "Event"
        ? {
            event_id: selectedData,
          }
        : name === "Department"
          ? {
              department_id: selectedData,
            }
        : name === "Holiday"
        ? {
            holiday_id: selectedData,
          }
        : null;
    try {
      const response = await axios.post(Global.base_url + apiUrl, ID, config);
      // console.log(response);
      if (response.status === 200) {
        onHide();
        toast.success(`${name} deleted Successfully`);
        if (name === "Role") {
          return history.replace("/admin/roles");
        }

        return refresh(Date.now() + 1);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(`Unable to delete ${name}`);
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="boldFont">
            Delete
            {/* {editProduct.index !== "" ? "Edit Test" : "Add Test"} */}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" py-5 semiFont">
        Are you sure want to delete this {name} ?
      </Modal.Body>
      <div className="carbon__buttoncontainer">
        {/* {location.state.editable ? ( */}
        <Button
          variant="contained"
          className="carbon__secondarybtn carbon__btn"
          id="admin-reportview-download-btn"
          style={{
            //   padding: "25px ",
            color: "#fff",
            //   border: "1px solid #1565d8",
            //   marginRight: "15px",
          }}
          onClick={() => {
            onHide();
          }}
        >
          <p className="carbon__text">Cancel</p>
        </Button>
        {/* ) : null} */}

        <Button
          variant="contained"
          className="carbon__deletebtn carbon__btn"
          id="admin-reportview-download-btn"
          style={{
            //   padding: "10px 25px",

            color: "#fff",
          }}
          onClick={deleteItem}
        >
          <p className="carbon__text">Delete</p>
          {/* Yes */}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
