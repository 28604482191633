import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { FiArrowLeft } from "react-icons/fi";
import { IconContext } from "react-icons";
import { IconButton, Button, Tooltip } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";
import axios from "axios";
// import { AiOutlineExclamationCircle } from "react-icons/ai";
import Global from "../global";
import Select from "react-select";
import Slider from "@material-ui/core/Slider";
// import Switch from "@material-ui/core/Switch";
import { IoMdAddCircle } from "react-icons/io";

// import { withStyles } from "@material-ui/core/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";
import { IoSearchOutline } from "react-icons/io5";
import emptyLabs from "../assets/images/empty-lab.png";

// const BlueSwitch = withStyles({
//   switchBase: {
//     color: "#0F61FD",
//     "&$checked": {
//       color: "#0F61FD",
//     },
//     "&$checked + $track": {
//       backgroundColor: "#0F61FD",
//     },
//   },
//   checked: {},
//   track: {},
// })(Switch);

const TestView = () => {
  const location = useLocation();
  const history = useHistory();
  const editable = location.state.editable;
  const [loading, setLoading] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [testForOptions, setTestForOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState({});
  const [labs, setLabs] = useState([]);
  const [allLabs, setAllLabs] = useState([]);
  const statusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const testOptions = [
    { value: "WALK IN", label: "WALK IN" },
    { value: "HOME TEST", label: "HOME TEST" },
    // { value: "Kit Delivery", label: "Kit Delivery" },
  ];

  const allCities = { label: "All Cities", value: true };

  const [uploader, setUploader] = useState(false);
  const rowdataAlign = { verticalAlign: "middle" };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  console.log("location", location.state);

  useEffect(() => {
    const getTestData = async () => {
      try {
        const testData = await axios.post(
          Global.base_url + "/test/data",
          null,
          config
        );
        // console.log(response);

        if (testData.status === 200) {
          //   console.log("response data for test view", testData);
          let getCategory = testData.data.categories.map((item) => {
            return {
              label: item.category_name,
              value: item.category_id,
            };
          });
          setCategoryOptions(getCategory);

          const getCity = testData.data.cities.map((item) => {
            return {
              label: item.city,
              value: item.city,
            };
          });
          setCitiesOptions(getCity);

          const getTestFor = testData.data.test_for.map((item) => {
            return {
              label: item.type_name,
              value: item.type_id,
            };
          });
          setTestForOptions(getTestFor);

          if (editable) {
            try {
              const editResponse = await axios.post(
                Global.base_url + "/test/get/id",
                { test_id: location.state.data.test_id },
                config
              );
              // console.log(response);

              if (editResponse.status === 200) {
                console.log("edit Data", editResponse);

                let matchingCategory = [];

                editResponse.data.data.category.map((item) => {
                  let fileredCategory = getCategory.filter(
                    (list) => list.value === item && matchingCategory.push(list)
                  );
                });

                let getEditLabs = [];

                const data = editResponse.data.data.all_cities
                  ? {
                      all_cities: true,
                      cities: [],
                    }
                  : {
                      all_cities: false,
                      cities: editResponse.data.data.cities,
                    };

                try {
                  const getLabData = await axios.post(
                    Global.base_url + "/test/get/labs",
                    data,
                    config
                  );
                  // console.log(response);

                  if (getLabData.status === 200) {
                    console.log("response data for labs", getLabData);
                    getEditLabs = getLabData.data.labs;
                    setLabs(getLabData.data.labs);
                    setAllLabs(getLabData.data.labs);
                    //   console.log("getTestFor", getTestFor);
                  }
                  // return setLoading(false);
                } catch (error) {
                  if (error.getLabData) {
                    if (error.getLabData.status === 401) {
                      toast.error("Unable to get test labs");
                      return;
                    }
                    if (error.getLabData.status === 500) {
                      toast.error("Internal server error");
                      return;
                    }
                  }
                }

                let matchingLabs = [];

                if (editResponse.data.data.all_labs) {
                  let getSelectedLabs = getEditLabs.map((item) => item.lab_id);
                  setSelectedLabs(getSelectedLabs);
                } else {
                  editResponse.data.data.labs.map((item) => {
                    getEditLabs.filter(
                      (list) =>
                        list.lab_id === item && matchingLabs.push(list.lab_id)
                    );
                  });
                  setSelectedLabs(matchingLabs);
                }

                console.log("selected category", matchingCategory);

                let sortedEditData = {
                  test_id: editResponse.data.data.test_id,
                  test_name: editResponse.data.data.test_name,
                  test_description: editResponse.data.data.test_description,
                  test_for: {
                    label: editResponse.data.data.test_for,
                    value: editResponse.data.data.test_for,
                  },
                  test_type: {
                    label: editResponse.data.data.test_type,
                    value: editResponse.data.data.test_type,
                  },
                  category: matchingCategory,
                  recommended_for: editResponse.data.data.recommended_for,
                  age: [
                    editResponse.data.data.age_min,
                    editResponse.data.data.age_max,
                  ],
                  pre_info: editResponse.data.data.pre_info,
                  price: editResponse.data.data.price,
                  offer_price: editResponse.data.data.offer_price,
                  status: {
                    label: editResponse.data.data.status
                      ? "Active"
                      : "Inactive",
                    value: editResponse.data.data.status,
                  },
                  offer_text: editResponse.data.data.offer_text,
                  all_cities: editResponse.data.data.all_cities,
                  cities: editResponse.data.data.all_cities
                    ? [{ label: "All Cities", value: true }]
                    : editResponse.data.data.cities.map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      }),
                  all_labs: editResponse.data.data.all_labs ? true : false,
                  labs: getEditLabs,
                };

                setSelectedEditData(sortedEditData);

                //   console.log("getTestFor", getTestFor);
              }
              // return setLoading(false);
            } catch (error) {
              if (error.editResponse) {
                if (error.editResponse.status === 401) {
                  toast.error("Unable to get test edit data");
                  return;
                }
                if (error.editResponse.status === 500) {
                  toast.error("Internal server error");
                  return;
                }
              }
            }
          }

          //   console.log("getTestFor", getTestFor);
        }
        return setLoading(false);
      } catch (error) {
        if (error.testData) {
          if (error.testData.status === 401) {
            toast.error("Unable to get test Data");
            return;
          }
          if (error.testData.status === 500) {
            toast.error("Internal server error");
            return;
          }
        }
      }
    };

    getTestData();
  }, []);

  //   console.log("selectedLabs", selectedLabs);

  const required = <span style={{ color: "red" }}>*</span>;

  // initial values for input fields
  const initialValues =
    // condition for add or edit view
    // editproduct index indicates to existing products
    editable
      ? selectedEditData && selectedEditData
      : {
          test_name: "",
          test_description: "",
          test_for: null,
          test_type: null,
          category: [],
          recommended_for: "",
          age: [3, 100],
          pre_info: "",
          price: "",
          offer_price: "",
          status: null,
          offer_text: "",
          all_labs: false,
          labs: [],
          all_cities: false,
          cities: [],

          //   product_yellowcolortest: "",
        };

  const getLabs = async (city) => {
    console.log("formik values", city);

    const data = _.some(city, ["label", "All Cities"])
      ? {
          all_cities: true,
          cities: [],
        }
      : {
          all_cities: false,
          cities: city.map((item) => item.label),
        };
    console.log("send data", data);
    // return;
    try {
      const getLabData = await axios.post(
        Global.base_url + "/test/get/labs",
        data,
        config
      );
      // console.log(response);

      if (getLabData.status === 200) {
        console.log("response data for labs", getLabData);
        let newSelectedlabs = [];

        getLabData.data.labs.forEach(function (lab) {
          if (selectedLabs.includes(lab.lab_id)) {
            newSelectedlabs.push(lab.lab_id);
          }
          //   console.log("sdfghjkl;", [lab.lab_id == item, lab.lab_id, item]);
        });

        console.log("newSelectedlabs", newSelectedlabs);
        setSelectedLabs(newSelectedlabs);
        setLabs(getLabData.data.labs);
        setAllLabs(getLabData.data.labs);
        //   console.log("getTestFor", getTestFor);
      }
      // return setLoading(false);
    } catch (error) {
      if (error.getLabData) {
        if (error.getLabData.status === 401) {
          toast.error("Unable to get test labs");
          return;
        }
        if (error.getLabData.status === 500) {
          toast.error("Internal server error");
          return;
        }
      }
    }
  };

  console.log("labs", labs);

  const searchItems = (event) => {
    let filteredLabs = allLabs.filter((list) => {
      return event !== null
        ? list.lab_name
            .toString()
            .toLowerCase()
            .includes(event.toLowerCase()) ||
            list.lab_id
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            list.city.toString().toLowerCase().includes(event.toLowerCase())
        : allLabs;
    });
    setLabs(filteredLabs);
  };

  //   console.log("location", location.state);
  return (
    <div>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <>
          <div
            className="col-12 bg-white align-items-center pt-3 pb-3"
            style={{
              borderBottom: "1px solid #B3CDFF",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                history.push("/admin/tests");
              }}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <FiArrowLeft />
              </IconContext.Provider>
            </IconButton>
            <p
              style={{
                cursor: "pointer",
                fontSize: "25px",
                marginLeft: "5px",
              }}
              className="semiFont"
            >
              {editable ? `Test ID : ${location.state.data.test_id}` : "Back"}
            </p>
          </div>
          <div
            className="bg-white pt-3 px-2 px-xl-5 px-lg-3"
            style={{ paddingBottom: "30px" }}
          >
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialValues}
              validationSchema={yup.object().shape({
                // test_id: yup.string(),
                test_name: yup.string().required("Test Name cannot be empty"),
                test_for: yup
                  .object()
                  .required("Please select test for")
                  .nullable(),
                test_description: yup
                  .string()
                  .required("Test Description cannot be empty"),
                recommended_for: yup
                  .string()
                  .required("Recommended for cannot be empty"),
                pre_info: yup.string().required("pre test cannot be empty"),
                age: yup.array(),
                price: yup
                  .number("price must be valid")
                  .required("price cannot be empty"),
                offer_price: yup
                  .number("Offer price must be valid")
                  .required("Offer price cannot be empty"),
                test_type: yup
                  .object()
                  .required("Type cannot be empty")
                  .nullable(),
                status: yup
                  .object()
                  .required("Please select status")
                  .nullable(),
                offer_text: yup.string().required("Offer test cannot be empty"),
                category: yup.array().min(1, "Please select category"),
                cities: yup.array().min(1, "Please select cities"),
              })}
              onSubmit={async (values, formik) => {
                setUploader(true);
                // console.log(values);
                const url = editable
                  ? Global.base_url + "/test/update"
                  : Global.base_url + "/test/add";
                let data = {
                  test_name: values.test_name,
                  test_for: values.test_for.label,
                  status: values.status.value,
                  test_description: values.test_description,
                  recommended_for: values.recommended_for,
                  price: values.price,
                  offer_price: values.offer_price,
                  test_type: values.test_type.value.toUpperCase(),
                  offer_text: values.offer_text,
                  age_min: values.age[0],
                  age_max: values.age[1],
                  pre_info: values.pre_info,
                  category: values.category.map((item) => item.value),
                  all_labs: values.all_labs ? 1 : 0,
                  labs: selectedLabs,
                  all_cities: _.some(values.cities, ["label", "All Cities"])
                    ? 1
                    : 0,
                  cities: _.some(values.cities, ["label", "All Cities"])
                    ? null
                    : values.cities.map((item) => item.value).toString(),
                };

                if (editable) {
                  data = {
                    ...data,
                    test_id: values.test_id,
                  };
                }

                // console.log("selectedLabs", selectedLabs);

                if (selectedLabs.length) {
                  console.log("post send data", data);
                  try {
                    const response = await axios.post(url, data, config2);
                    // console.log(response);
                    if (response.status === 200) {
                      setUploader(false);
                      // await closeModal();
                      editable
                        ? toast.success("Test Updated Successfully")
                        : toast.success("Test Added Successfully");

                      !editable && history.push("/admin/tests");
                      //   formik.resetForm();
                      // return setRefresh(Date.now() + 1);
                    }
                  } catch (error) {
                    if (error.response && error.response.status === 401) {
                      editable
                        ? toast.error("Test Updated failed")
                        : toast.error("Test is not added");
                      return setUploader(false);
                    }
                    if (error.response && error.response.status === 500) {
                      toast.error("Internal server error");
                      return setUploader(false);
                    }
                  }
                } else {
                  toast.error("Please select labs");
                }

                return;
              }}
            >
              {(formik) => {
                console.log("formik.values", formik.values);
                return (
                  <>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "5px",
                            }}
                          >
                            <label
                              htmlFor="product-name"
                              className="semiFont login__label"
                            >
                              Test Name {required}
                            </label>
                            <input
                              type="text"
                              id="product-name"
                              // className="form-control signup__input"
                              value={formik.values.test_name}
                              style={{
                                border: formik.errors.test_name
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              placeholder="Test Name"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "test_name",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+./,' ]/g,
                                    ""
                                  )
                                );
                              }}
                            />
                            {formik.errors.test_name && (
                              <div className="err">
                                <p>{formik.errors.test_name}</p>
                              </div>
                            )}
                          </div>
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="test-for"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Test For {required}
                            </label>
                            <Select
                              value={formik.values.test_for}
                              onChange={(p) => {
                                formik.setFieldValue("test_for", p);
                                // console.log("type ", p.value);
                              }}
                              id="inputDropdown"
                              isClearable={true}
                              placeholder="Select Test for"
                              options={testForOptions}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.test_for
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.test_for && (
                              <div className="err">
                                <p>{formik.errors.test_for}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-12 col-xl-6 col-md-6"
                          //   style={{
                          //     display: editable ? "block" : "none",
                          //   }}
                        >
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="test_description"
                              className="semiFont login__label"
                            >
                              Test Description{required}
                            </label>
                            <textarea
                              id="test_description"
                              rows="5"
                              style={{
                                border: formik.errors.test_description
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              placeholder="Test Description"
                              value={formik.values.test_description}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "test_description",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+.,/' ]/g,
                                    ""
                                  )
                                );
                              }}
                            ></textarea>
                            {formik.errors.test_description && (
                              <div className="err">
                                <p>{formik.errors.test_description}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "5px",
                            }}
                          >
                            <label
                              htmlFor="product_typeoftest"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Type of Test{required}
                            </label>
                            <Select
                              value={formik.values.test_type}
                              onChange={(p) => {
                                formik.setFieldValue("test_type", p);
                              }}
                              isClearable={true}
                              id="inputDropdown"
                              placeholder="Select test type"
                              options={testOptions}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.test_type
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.test_type && (
                              <div className="err">
                                <p>{formik.errors.test_type}</p>
                              </div>
                            )}
                          </div>
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="category"
                              className="semiFont login__label"
                            >
                              Category {required}
                            </label>
                            <Select
                              value={formik.values.category}
                              onChange={(p) => {
                                formik.setFieldValue("category", p);
                              }}
                              id="inputDropdown"
                              isMulti
                              placeholder="Select Category"
                              options={categoryOptions}
                              //   hideSelectedOptions={true}
                              closeMenuOnSelect={false}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.category
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.category && (
                              <div className="err">
                                <p>{formik.errors.category}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-12 col-xl-6 col-md-6"
                          // style={{
                          //   display: editable ? "block" : "none",
                          // }}
                        >
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="product-id"
                              className="semiFont login__label"
                            >
                              Recommended For {required}
                            </label>
                            <textarea
                              id="recommended_for"
                              rows="5"
                              style={{
                                border: formik.errors.recommended_for
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "6px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              placeholder={"Recommended for"}
                              value={formik.values.recommended_for}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "recommended_for",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+.,/' ]/g,
                                    ""
                                  )
                                );
                              }}
                            />
                            {formik.errors.recommended_for && (
                              <div className="err">
                                <p>{formik.errors.recommended_for}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="product-id"
                              className="semiFont login__label"
                            >
                              Age {required}
                            </label>
                            <div className="slider__container px-1 py-3 mt-5">
                              <Slider
                                value={
                                  formik.values && formik.values.age
                                    ? formik.values.age
                                    : [3, 100]
                                }
                                valueLabelDisplay="on"
                                onChange={(event, newValue) => {
                                  formik.setFieldValue("age", newValue);
                                  console.log("newValue", newValue);
                                }}
                                aria-labelledby="range-slider"
                                // getAriaValueText={valuetext}
                              />
                              <p className="login__label mediumFont">
                                <span>
                                  Min :{" "}
                                  {formik.values.age && formik.values.age[0]}
                                </span>
                                <span className="ml-3">
                                  Max :{" "}
                                  {formik.values.age && formik.values.age[1]}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="pre_info"
                              className="semiFont login__label"
                            >
                              Pre test info {required}
                            </label>
                            <textarea
                              id="pre_info"
                              rows="5"
                              style={{
                                border: formik.errors.pre_info
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "6px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              placeholder="Pre test information"
                              value={formik.values.pre_info}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "pre_info",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+.,/' ]/g,
                                    ""
                                  )
                                );
                              }}
                            />
                            {formik.errors.pre_info && (
                              <div className="err">
                                <p>{formik.errors.pre_info}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="test_price"
                              className="semiFont login__label"
                            >
                              Actual Price {required}
                            </label>
                            <input
                              type="number"
                              name="test_price"
                              style={{
                                border: formik.errors.price
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              placeholder="Actual price"
                              value={formik.values.price}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "price",
                                  e.currentTarget.value.replace(/[^0-9]/g, "")
                                );
                              }}
                            />
                            {formik.errors.price && (
                              <div className="err">
                                <p>{formik.errors.price}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="test_offerprice"
                              className="semiFont login__label"
                            >
                              Offer Price {required}
                            </label>
                            <input
                              type="number"
                              name="test_offerprice"
                              style={{
                                border: formik.errors.offer_price
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              placeholder="Offer price"
                              value={formik.values.offer_price}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "offer_price",
                                  e.currentTarget.value.replace(/[^0-9]/g, "")
                                );
                              }}
                            />
                            {formik.errors.offer_price && (
                              <div className="err">
                                <p>{formik.errors.offer_price}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-md-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="test_offertext"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Offer Text{required}
                            </label>
                            <input
                              type="text"
                              name="test_offertext"
                              style={{
                                border: formik.errors.offer_text
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              placeholder="Offet text"
                              value={formik.values.offer_text}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "offer_text",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+./,' ]/g,
                                    ""
                                  )
                                );
                              }}
                            />
                            {formik.errors.offer_text && (
                              <div className="err">
                                <p>{formik.errors.offer_text}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-md-6 ">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                            id="a123"
                          >
                            <label
                              htmlFor="product-status"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Status {required}
                            </label>
                            <Select
                              value={formik.values.status}
                              onChange={(p) => {
                                formik.setFieldValue("status", p);
                              }}
                              isClearable={true}
                              id="inputDropdown"
                              options={statusOptions}
                              className="admim__Addproduct--dropdown "
                              placeholder="Select Status"
                              menuPlacement="auto"
                              // id="product-status"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.status
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                            />

                            {formik.errors.status && (
                              <div className="err">
                                <p>{formik.errors.status}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-xl-6 col-md-6 ">
                          {/* {!formik.values.all_cities ? ( */}
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="cities"
                              className="semiFont login__label"
                            >
                              Select City {required}
                            </label>
                            <Select
                              value={formik.values.cities}
                              onChange={(p) => {
                                formik.setFieldValue("cities", p);

                                p && getLabs(p);
                                formik.setFieldValue("all_labs", false);
                                // setSelectedLabs(
                                //     );

                                // getLabs(p);
                              }}
                              id="inputDropdown"
                              isMulti
                              placeholder="Select cities"
                              options={
                                formik.values.cities &&
                                formik.values.cities.length
                                  ? _.some(formik.values.cities, [
                                      "label",
                                      "All Cities",
                                    ])
                                    ? []
                                    : citiesOptions
                                  : [allCities, ...citiesOptions]
                              }
                              //   hideSelectedOptions={true}
                              closeMenuOnSelect={false}
                              menuPlacement={"auto"}
                              // menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.cities
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.cities && (
                              <div className="err">
                                <p>{formik.errors.cities}</p>
                              </div>
                            )}
                          </div>
                          {/* ) : null} */}
                        </div>
                      </div>
                      {formik.values.cities && formik.values.cities.length ? (
                        <div className="row p-3">
                          <div className="col-12 backDrop__container py-3">
                            <section className=" d-flex flex-column flex-sm-row justify-content-end justify-content-sm-between align-items-start align-items-sm-center ">
                              <div className="mb-2 mb-sm-0">
                                <p className="login__label semiFont">
                                  Select Labs {required}
                                </p>
                              </div>
                              <div
                                className="filter__container"
                                style={{ position: "relative" }}
                              >
                                <input
                                  type="text"
                                  className="filter__input"
                                  placeholder="Search labs"
                                  onChange={(e) => {
                                    searchItems(e.target.value);
                                  }}
                                />
                                <IconContext.Provider
                                  value={{
                                    size: "25px",
                                    color: "#333",
                                  }}
                                >
                                  <IoSearchOutline
                                    className="filter-search-icon"
                                    color="#818181"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContext.Provider>
                              </div>
                            </section>

                            <section
                              className="mt-2 admin-product-filter-section table-responsive fixHeader 
"
                            >
                              <table
                                className="table  text-center  admin-product_usertable mb-0"
                                style={{
                                  backgroundColor: "white",
                                }}
                                id="bill-table"
                              >
                                <thead>
                                  <tr
                                    id="admin-product-row"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#E0E0E0",
                                    }}
                                  >
                                    <th
                                      scope="col"
                                      className="admin-product-row_table-header"
                                    >
                                      <div
                                        style={{ position: "relative" }}
                                        className="d-flex align-items-center justify-content-center"
                                      >
                                        <input
                                          // className="form-check-input"
                                          type="checkbox"
                                          name="product_id"
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            // marginRight: "10px",
                                          }}
                                          disabled={!labs.length}
                                          // value={Number(data.lab_id)}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;

                                            console.log("chceking", [
                                              checked,
                                              value,
                                            ]);
                                            if (checked) {
                                              setSelectedLabs(
                                                labs &&
                                                  labs.map(
                                                    (item) => item.lab_id
                                                  )
                                              );
                                              formik.setFieldValue(
                                                "all_labs",
                                                true
                                              );
                                            } else {
                                              setSelectedLabs([]);
                                              formik.setFieldValue(
                                                "all_labs",
                                                false
                                              );
                                            }
                                          }}
                                          checked={formik.values.all_labs}
                                        />
                                        {/* <label
                                      htmlFor="Select-all"
                                      className="form-check-label"
                                    >
                                      {" "}
                                      All
                                    </label> */}
                                      </div>
                                    </th>
                                    <th
                                      scope="col"
                                      className="admin-product-row_table-header"
                                    >
                                      Lab Id
                                    </th>
                                    <th
                                      scope="col"
                                      className="admin-product-row-table-header admin-product-row-price-col"
                                    >
                                      Lab Name
                                    </th>

                                    <th
                                      scope="col"
                                      className="admin-product-row-table-header   admin-product-row-status-col"
                                    >
                                      City
                                    </th>
                                    {/* <th
                                    scope="col"
                                    className="admin-product-row-table-header  admin-product-row-status-col"
                                  >
                                    Location
                                  </th> */}
                                  </tr>
                                </thead>
                                <tbody className="fixedBody">
                                  {labs.length > 0 ? (
                                    labs.map((data, i) => {
                                      const borderBottom = `1px solid #B3CDFF`;
                                      return (
                                        <>
                                          <tr
                                            key={data.lab_id + Math.random()}
                                            style={{
                                              //   borderLeft,
                                              borderBottom,
                                              backgroundColor: "white",
                                            }}
                                            className="admin-product-row-table-row-hover modal__table"
                                          >
                                            {" "}
                                            <td
                                              className="admin-product-row-table-row-data mediumFont"
                                              style={rowdataAlign}
                                            >
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <input
                                                  // className="form-check-input"
                                                  type="checkbox"
                                                  name="product_id"
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                  }}
                                                  value={Number(data.lab_id)}
                                                  onChange={(e) => {
                                                    const { checked, value } =
                                                      e.target;

                                                    console.log("chceking", [
                                                      checked,
                                                      value,
                                                    ]);
                                                    if (checked) {
                                                      setSelectedLabs([
                                                        ...selectedLabs,
                                                        Number(value),
                                                      ]);
                                                    } else {
                                                      setSelectedLabs(
                                                        selectedLabs &&
                                                          selectedLabs.filter(
                                                            (v) =>
                                                              Number(v) !==
                                                              Number(value)
                                                          )
                                                      );
                                                      formik.setFieldValue(
                                                        "all_labs",
                                                        false
                                                      );
                                                    }
                                                  }}
                                                  checked={
                                                    selectedLabs &&
                                                    selectedLabs.includes(
                                                      Number(data.lab_id)
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td
                                              scope="row"
                                              id="admin-product-rd"
                                              className="admin-product-row-table-row-data mediumFont"
                                              style={rowdataAlign}
                                            >
                                              <p
                                                data-toggle="collapse"
                                                data-target={`#accordion` + i}
                                                className="clickable"
                                                id="button-open"
                                                // style={{ display: "none" }}
                                              >
                                                <IconContext.Provider
                                                  value={{
                                                    color: "#4059E2",
                                                    size: "25px",
                                                    style: {
                                                      marginBottom: "3px",
                                                    },
                                                  }}
                                                >
                                                  <IoMdAddCircle className="toggle-row-icon" />
                                                </IconContext.Provider>
                                              </p>{" "}
                                              LID{data.lab_id}
                                            </td>
                                            <td
                                              className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                                              style={rowdataAlign}
                                            >
                                              {data.lab_name}
                                            </td>
                                            <td
                                              className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                              style={rowdataAlign}
                                            >
                                              {data.city}
                                            </td>
                                          </tr>
                                          <tr
                                            id={"accordion" + i}
                                            className="collapse  admin-product-hided-row"
                                            key={data.lab_id + Math.random()}
                                          >
                                            <td
                                              colSpan="6"
                                              style={rowdataAlign}
                                            >
                                              <ul
                                                className="admin-product-row-table-hide-list text-left mediumFont"
                                                style={{ color: "#525252" }}
                                              >
                                                <li
                                                  key={
                                                    i +
                                                    data.lab_id +
                                                    i +
                                                    Math.random()
                                                  }
                                                  style={{
                                                    // borderBottom: "1px solid #B3CDFF",
                                                    padding: "10px",
                                                  }}
                                                  className="admin-product-row-hided-col-price"
                                                >
                                                  <div className="row justify-content-start ml-3">
                                                    <div className="col-6  text-center">
                                                      Lab Name:
                                                    </div>
                                                    <div className="col-6  text-left">
                                                      {data.lab_name}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li
                                                  key={
                                                    i +
                                                    data.lab_id +
                                                    i +
                                                    Math.random()
                                                  }
                                                  style={{
                                                    // borderBottom: "1px solid #B3CDFF",
                                                    padding: "10px",
                                                  }}
                                                  // className="admin-product-row-hided-col-price"
                                                >
                                                  <div className="row justify-content-start ml-3">
                                                    <div className="col-6  text-center">
                                                      City:
                                                    </div>
                                                    <div className="col-6  text-left">
                                                      {data.city}
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div
                                      className="center__items p-4"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <img src={emptyLabs} alt="empty-labs" />
                                      <p
                                        style={{
                                          color: "#D2D2D2",
                                          padding: "20px",
                                          fontSize: "20px",
                                          fontFamily: "mediumFont",
                                        }}
                                      >
                                        Labs not found
                                      </p>
                                    </div>
                                  )}
                                </tbody>
                              </table>
                            </section>
                          </div>
                        </div>
                      ) : null}
                      <div className="button__container mt-4 d-flex align-items-center justify-content-end">
                        <Button
                          variant="contained"
                          className="primary__btn"
                          id="admin-reportview-download-btn"
                          style={{
                            padding: "10px 25px",
                            width: "150px",
                            color: "#fff",
                          }}
                          disabled={uploader}
                          onClick={formik.handleSubmit}
                        >
                          {uploader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

export default TestView;
