import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LoginPage from "./components/LoginPage";
import Forget from "./components/Forget";
// import SignUp from "./components/SignUp";
import Sidebar from "./components/Sidebar";
import GlobalProvider from "./context/Context";
import AccountActivation from "./components/AccountActivation";
import { ToastContainer } from "react-toastify";
const theme = createTheme({
  typography: {
    fontFamily: `semiFont`,
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        letterSpacing: "1.2px",
        color: "#fff",
        backgroundColor: "#444",
      },
    },
    MuiButton: {
      // root: { borderRadius: 0, padding: "15px" },
      // label: {
      //   justifyContent: "flex-start",
      // },
    },
    MuiCheckbox: {
      colorPrimary: {
        // color: "#1565d8",
        "&$checked": {
          color: "#1565d8",
        },
      },
    },
    MuiSlider: {
      thumb: {
        color: "#1565d8",
      },
      track: {
        color: "#1565d8",
      },
      rail: {
        color: "#1565d8",
      },
    },
  },
});

function App() {
  return (
    <GlobalProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/account/activation" component={AccountActivation} />
            {/* <Route exact path="/forget-password" component={Forget} /> */}
            {/* <Route exact path="/signup" component={SignUp} /> */}
            <Route path="/admin" component={Sidebar} />
            {/* <Route exact path="/forget-password" component={ForgetPassword} />

          <Route path="/student" component={Sidebar} />
          <Route path="/zoom" component={Zoom} />
          <Route path="/videoplay" component={VideoPlay} /> */}
            <Redirect from="/login" to="/" />
          </Switch>
        </Router>
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default App;
