import { useState } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { MdAdd } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import empty_category from "../assets/images/category.png";
import { IconContext } from "react-icons";
import { makeStyles } from "@material-ui/core/styles";
import { IoMdAddCircle } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import * as yup from "yup";
import _ from "lodash";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Category = ({
  getList,
  setList,
  getAllList,
  setAllList,
  getPage,
  setPage,
  setRow,
  getRow,
  refresh,
}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const classes = useStyles();
  const [categoryModal, setCategoryModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState({ data: "", index: "" });
  console.log("getList", getList);

  const rowdataAlign = { verticalAlign: "middle" };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    let startIndex = (newPage + 1) * getRow - getRow;

    const endIndex = startIndex + getRow;
    const data = getAllList.slice(startIndex, endIndex);

    setList([...data]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRow(parseInt(event.target.value, 10));
    setPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = getAllList.slice(startIndex, endIndex);

    setList([...data]);
  };

  const CategoryModal = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const required = <span style={{ color: "red" }}>*</span>;
    function closeModal() {
      setSelectedData({
        data: "",
        index: "",
      });
      setCategoryModal(false);
    }

    // console.log("delete");

    return (
      <Modal
        show={categoryModal}
        onHide={() => setCategoryModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              {selectedData.index !== "" ? "Edit Category" : "Add Category"}
            </p>
          </Modal.Title>
        </Modal.Header>

        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={
            selectedData.index !== ""
              ? { category_name: selectedData.data.category_name }
              : { category_name: "" }
          }
          validationSchema={yup.object().shape({
            category_name: yup.string().required("Name cannot be empty"),
          })}
          onSubmit={async (values, formik) => {
            setSubmitLoading(true);
            const url =
              selectedData.index !== ""
                ? Global.base_url + "/classify/category/edit"
                : Global.base_url + "/classify/category/add";

            const data =
              selectedData.index !== ""
                ? {
                    category_name: values.category_name,

                    category_id: selectedData.data.category_id,
                  }
                : {
                    category_name: values.category_name,
                  };

            console.log("data", data);

            // return;

            try {
              const response = await axios.post(url, data, config);

              // console.log(response);
              if (response.status === 200) {
                setSubmitLoading(false);
                await closeModal();
                // formik.resetForm({
                //   title: "",
                //   comment: "",
                // });
                //   resetFrom({ values: "" });
                selectedData.index !== ""
                  ? toast.success("Category Updated Successfully")
                  : toast.success("Category Added Successfully");
                return refresh(Date.now() + 1);
              }
              return;
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  selectedData.index !== ""
                    ? toast.error("Category Updated failed")
                    : toast.error("Category is not added");
                  return setSubmitLoading(false);
                }
                if (error.response.status === 500) {
                  toast.error("Internal server error");
                  return setSubmitLoading(false);
                }
              }
            }
          }}
        >
          {(formik) => {
            return (
              <>
                <Modal.Body className="py-5">
                  <>
                    <div
                      className="inputBox"
                      style={{
                        position: "relative",
                        marginTop: 0,
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor="category_name"
                        className="semiFont login__label"
                      >
                        Category Name {required}
                      </label>
                      <input
                        type="text"
                        id="category_name"
                        value={formik.values.category_name}
                        style={{
                          border: formik.errors.category_name
                            ? "1px solid red"
                            : "1px solid #c3cad9",
                          marginTop: "5px",
                          width: "auto",
                        }}
                        placeholder="Category Name"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "category_name",
                            e.currentTarget.value.replace(
                              /[^a-zA-Z0-9&,-:"' ]/g,
                              ""
                            )
                          )
                        }
                      />
                      {formik.errors.category_name && (
                        <p className="err">{formik.errors.category_name}</p>
                      )}
                    </div>
                  </>
                </Modal.Body>
                <div className="carbon__buttoncontainer d-flex">
                  {selectedData.index !== "" ? (
                    <Button
                      variant="contained"
                      className="carbon__secondarybtn carbon__btn"
                      id="admin-reportview-download-btn"
                      style={{
                        //   padding: "25px ",
                        color: "#fff",
                        //   border: "1px solid #1565d8",
                        //   marginRight: "15px",
                      }}
                      onClick={() => {
                        setDeleteModal(true);
                        setCategoryModal(false);
                      }}
                    >
                      <p className="carbon__text">Delete</p>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="carbon__secondarybtn carbon__btn"
                      id="admin-reportview-download-btn"
                      style={{
                        //   padding: "25px ",
                        color: "#fff",
                        //   border: "1px solid #1565d8",
                        //   marginRight: "15px",
                      }}
                      onClick={() => closeModal()}
                    >
                      <p className="carbon__text">Cancel</p>
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    className="carbon__primarybtn carbon__btn"
                    id="admin-reportview-download-btn"
                    disabled={submitLoading}
                    style={{
                      color: "#fff",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {submitLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <p className="carbon__text">
                        {selectedData.index !== "" ? "Update" : "Add"}
                      </p>
                    )}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  return (
    <div className="report__container">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <CategoryModal
        // editData={editData}

        // modalStatus={props.modalStatus}
        show={categoryModal}
        onHide={() => setCategoryModal(false)}
      />
      <DeleteModal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        selectedData={selectedData.data.category_id}
        refresh={refresh}
        apiUrl={"/classify/category/delete"}
        name={"Category"}
        // id={"category_id"}
      />
      <section className="filter-section testreport" style={{ border: "none" }}>
        <div className="sectionHeader p-3">
          <h2 className="hkBoldFont" style={{ color: "#161616" }}>
            Category
          </h2>
          <p className="mediumFont mt-2" style={{ color: "#515151" }}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              //   border: showFilter ? "1px solid #c3cad9" : 0,
              borderLeft: "none",
              borderBottom: "none",
            }}
            className="search__testcontainer"
            // id={open ? "searchTransition" : "searchStatic"}
          >
            <input
              type="text"
              name="search"
              id={"search__input"}
              placeholder="Search Catgory"
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            className="primary__button"
            type="submit"
            startIcon={
              <IconContext.Provider
                value={{
                  color: "#fff",
                  size: "25px",
                }}
              >
                <MdAdd />
              </IconContext.Provider>
            }
            style={{ width: "250px" }}
            className={[classes.rootAbsolute, "primary__button"]}
            onClick={() => {
              setSelectedData({
                data: "",
                index: "",
              });
              setCategoryModal(true);
            }}
          >
            Add Category
          </Button>
          {/* <div>
          <Select
            value={status}
            onChange={(v) => {
              filter(v);
              setStatus(v);
            }}
            placeholder="Status"
            options={statusfilterOptions}
            className="ub-dropdown-periods ub-filter_input-small"
          />
        </div> */}
          {/* </div> */}
        </div>
      </section>
      <section
        className="admin-product-filter-section table-responsive fixHeader
"
      >
        <table
          className="table  text-center  admin-product_usertable m-0"
          style={{
            backgroundColor: "white",
          }}
          id="bill-table"
        >
          <thead>
            <tr
              id="admin-product-row"
              style={{ border: "none", backgroundColor: "#E0E0E0" }}
            >
              <th scope="col" className="admin-product-row_table-header">
                Category ID
              </th>
              <th scope="col" className="admin-product-row-table-header">
                Category Name
              </th>

              <th
                scope="col"
                // colSpan={2}
                className="admin-product-row-action-col admin-product-row-table-header"
              ></th>
            </tr>
          </thead>
          <tbody>
            {getList.length > 0 ? (
              getList.map((data, i) => {
                if (i > getRow - 1) {
                  return;
                }
                // const color = data.status === 1 ? "#03A33B" : "red";

                //   const borderLeft = `5px solid ${color}`;
                const borderBottom = `1px solid #B3CDFF`;
                const font = "mediumFont";

                return (
                  <>
                    <tr
                      key={data.category_id + Math.random()}
                      style={{
                        //   borderLeft,
                        borderBottom,
                        backgroundColor: "white",
                      }}
                      className="admin-product-row-table-row-hover "
                    >
                      <td
                        scope="row"
                        id="admin-product-rd"
                        className="admin-product-row-table-row-data mediumFont"
                        style={rowdataAlign}
                      >
                        <p
                          data-toggle="collapse"
                          data-target={`#accordion` + i}
                          className="clickable"
                          id="button-open"
                          // style={{ display: "none" }}
                        >
                          <IconContext.Provider
                            value={{
                              color: "#4059E2",
                              size: "25px",
                              style: { marginBottom: "3px" },
                            }}
                          >
                            <IoMdAddCircle className="toggle-row-icon" />
                          </IconContext.Provider>
                        </p>{" "}
                        CID{data.category_id}
                      </td>
                      <td
                        className="admin-product-row-table-row-data mediumFont"
                        style={rowdataAlign}
                      >
                        {" "}
                        {data.category_name}
                      </td>

                      <td
                        className="admin-product-row-action-col admin-product-row-table-row-data "
                        // colSpan={2}
                        style={rowdataAlign}
                      >
                        <Button
                          key={"view" + i}
                          variant="contained"
                          color="default"
                          className={"admin-product-Filter__button mr-2"}
                          // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                          style={{ padding: "10px 33px" }}
                          onClick={() => {
                            // toast.success("Hello");
                            setSelectedData({
                              data: data,
                              index: i,
                            });
                            setCategoryModal(true);
                          }}
                          startIcon={<BiEditAlt />}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                    <tr
                      id={"accordion" + i}
                      className="collapse  admin-product-hided-row"
                      key={data.category_id + Math.random()}
                    >
                      <td colSpan="6" style={rowdataAlign}>
                        <ul className="admin-product-row-table-hide-list text-left ">
                          <li
                            key={data.category_id + Math.random()}
                            style={{
                              // borderBottom: "1px solid #B3CDFF",
                              padding: "10px",
                            }}
                          >
                            <div className="row justify-content-center mt-2 ml-3">
                              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                <Button
                                  key={i}
                                  variant="contained"
                                  color="default"
                                  className={
                                    "admin-product-Filter__button mr-2"
                                  }
                                  style={{ padding: "10px 33px" }}
                                  onClick={() => {
                                    setSelectedData({
                                      data: data,
                                      index: i,
                                    });
                                    setCategoryModal(true);
                                  }}
                                  startIcon={<BiEditAlt />}
                                >
                                  Edit
                                </Button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <div
                className="center__items p-5"
                style={{ flexDirection: "column" }}
              >
                <img src={empty_category} alt="Empty category" />
                <p
                  style={{
                    color: "#D2D2D2",
                    padding: "20px",
                    fontSize: "20px",
                    fontFamily: "mediumFont",
                  }}
                >
                  Categories not found
                </p>
              </div>
            )}
          </tbody>
        </table>
      </section>
      {getAllList.length > 5 || getPage > 0 ? (
        <div className="d-flex flex-row align-items-center justify-content-end mb-4">
          <TablePagination
            component="div"
            count={getAllList.length}
            page={getPage}
            onChangePage={handleChangePage}
            rowsPerPage={getRow}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 50]}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Category;
