import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { FiArrowLeft } from "react-icons/fi";
import { IconContext } from "react-icons";
import { IconButton, Button, Tooltip } from "@material-ui/core";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";
import axios from "axios";
// import { AiOutlineExclamationCircle } from "react-icons/ai";
import Global from "../global";
import Select from "react-select";
import Slider from "@material-ui/core/Slider";
// import Switch from "@material-ui/core/Switch";
import { IoMdAddCircle } from "react-icons/io";
import emptyTest from "../assets/images/empty-test.png";
import emptyLabs from "../assets/images/empty-lab.png";
import { CgClose } from "react-icons/cg";

// import { withStyles } from "@material-ui/core/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";
import { IoSearchOutline } from "react-icons/io5";

const CustomizedMultiSelect = (props) => {
  //   console.log("selected props", props);
  if (props.allowSelectAll) {
    return (
      <Select
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={(selected) => {
          // console.log("parent selected", selected);
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <Select {...props} />;
};

CustomizedMultiSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

const PackageView = () => {
  const location = useLocation();
  const history = useHistory();
  const { editable, selectedPackage, data } = location.state;
  const [loading, setLoading] = useState(true);
  const [productData, setProducts] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [testForOptions, setTestForOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  // const [checkedLabs, setCheckedLabs] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState({});
  const [labs, setLabs] = useState([]);
  const [allLabs, setAllLabs] = useState([]);
  const [tests, setTests] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [selectedTestItems, setSelectedTestItems] = useState([]);
  // const [submitLoading, setSubmitLoading] = useState(false);

  const statusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const testOptions = [
    { value: "WALK IN", label: "WALK IN" },
    { value: "HOME TEST", label: "HOME TEST" },
    // { value: "Kit Delivery", label: "Kit Delivery" },
  ];

  const allCities = { label: "All Cities", value: true };

  const [submitLoading, setSubmitLoading] = useState(false);

  const rowdataAlign = { verticalAlign: "middle" };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  const required = <span style={{ color: "red" }}>*</span>;

  useEffect(() => {
    const getPackageData = async () => {
      try {
        const packageData = await axios.post(
          Global.base_url + "/package/data",
          null,
          config
        );

        if (packageData.status === 200) {
          console.log("packageData", packageData);
          let getCategory = packageData.data.categories.map((item) => {
            return {
              label: item.category_name,
              value: item.category_id,
            };
          });
          setCategoryOptions(getCategory);
          const getCity = packageData.data.cities.map((item) => {
            return {
              label: item.city,
              value: item.city,
            };
          });
          setCitiesOptions(getCity);

          const getTestFor = packageData.data.test_for.map((item) => {
            return {
              label: item.type_name,
              value: item.type_id,
            };
          });
          setTestForOptions(getTestFor);
          if (editable) {
            try {
              const editResponse = await axios.post(
                Global.base_url + "/package/get/id",
                { package_id: location.state.data.package_id },
                config
              );

              if (editResponse.status === 200) {
                console.log("editResponse", editResponse);

                let getEditLabs = [];

                const labItems = editResponse.data.data.all_cities
                  ? {
                      all_cities: true,
                      cities: [],
                    }
                  : {
                      all_cities: false,
                      cities: editResponse.data.data.cities,
                    };

                try {
                  const getLabData = await axios.post(
                    Global.base_url + "/package/get/labs",
                    labItems,
                    config
                  );
                  // console.log(response);

                  if (getLabData.status === 200) {
                    console.log(
                      "response data for labs for pacckage",
                      getLabData
                    );
                    getEditLabs = getLabData.data.labs;
                    setLabs(getLabData.data.labs);
                    setAllLabs(getLabData.data.labs);
                    //   console.log("getTestFor", getTestFor);
                  }
                  // return setLoading(false);
                } catch (error) {
                  if (error.getLabData) {
                    if (error.getLabData.status === 401) {
                      toast.error("Unable to get package labs");
                      return;
                    }
                    if (error.getLabData.status === 500) {
                      toast.error("Internal server error");
                      return;
                    }
                  }
                }

                let matchingLabs = [];

                if (editResponse.data.data.all_labs) {
                  let getSelectedLabs = getEditLabs.map((item) => item.lab_id);
                  setSelectedLabs(getSelectedLabs);
                } else {
                  editResponse.data.data.labs.map((item) => {
                    getEditLabs.filter(
                      (list) =>
                        list.lab_id === item && matchingLabs.push(list.lab_id)
                    );
                  });
                  setSelectedLabs(matchingLabs);
                }

                let getEditTests = [];

                let matchingTests = [];

                if (selectedPackage) {
                  const testItems = editResponse.data.data.all_labs
                    ? {
                        all_labs: true,
                        labs: [],
                        package_type: editResponse.data.data.package_type,
                      }
                    : {
                        all_labs: false,
                        labs: editResponse.data.data.labs,
                        package_type: editResponse.data.data.package_type,
                      };

                  try {
                    const getTestsData = await axios.post(
                      Global.base_url + "/package/get/labs/test",
                      testItems,
                      config
                    );
                    // console.log(response);

                    if (getTestsData.status === 200) {
                      console.log(
                        "response data for tests for pacckage",
                        getTestsData
                      );
                      getEditLabs = getTestsData.data.tests;
                      setTests(getTestsData.data.tests);
                      setAllTests(getTestsData.data.tests);
                      // //   console.log("getTestFor", getTestFor);
                    }
                    // return setLoading(false);
                  } catch (error) {
                    if (error.getTestsData) {
                      if (error.getTestsData.status === 401) {
                        toast.error("Unable to get package tests");
                        return;
                      }
                      if (error.getTestsData.status === 500) {
                        toast.error("Internal server error");
                        return;
                      }
                    }
                  }

                  if (editResponse.data.data.all_labs) {
                    let getSelectedTests = getEditTests.map(
                      (item) => item.test_id
                    );
                    setSelectedTests(getSelectedTests);
                  } else {
                    editResponse.data.data.tests.map((item) => {
                      getEditLabs.filter(
                        (list) =>
                          list.test_id === item &&
                          matchingTests.push(list.test_id)
                      );
                    });
                    setSelectedTests(matchingTests);
                  }
                }

                const getCategoryData = (item) => {
                  let matchingCategory = [];
                  item.category.map((data) => {
                    let filteredCategory = getCategory.filter(
                      (list) =>
                        list.value === data && matchingCategory.push(list)
                    );
                  });

                  // console.log("sortedCategory", matchingCategory);

                  return matchingCategory;
                };

                let sortedCommonEditData = {
                  package_id: editResponse.data.package_id,
                  package_name: editResponse.data.data.package_name,
                  package_desc: editResponse.data.data.package_desc,
                  package_type: {
                    label: editResponse.data.data.package_type,
                    value: editResponse.data.data.package_type,
                  },
                  package_price: editResponse.data.data.package_price,
                  pack_offer_price: editResponse.data.data.pack_offer_price,
                  pack_offer_text: editResponse.data.data.pack_offer_text,
                  pack_recommend: editResponse.data.data.pack_recommend,
                  age: [
                    editResponse.data.data.age_min,
                    editResponse.data.data.age_max,
                  ],
                  all_cities: editResponse.data.data.all_cities,
                  cities: editResponse.data.data.all_cities
                    ? [{ label: "All Cities", value: true }]
                    : editResponse.data.data.cities.map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      }),
                  all_labs: editResponse.data.data.all_labs ? true : false,
                  all_tests: editResponse.data.data.all_tests ? true : false,
                  status: {
                    label: editResponse.data.data.status
                      ? "Active"
                      : "Inactive",
                    value: editResponse.data.data.status,
                  },
                };

                let sortedEditData = selectedPackage
                  ? {
                      ...sortedCommonEditData,
                    }
                  : {
                      ...sortedCommonEditData,
                      tests: editResponse.data.data.tests.map((item) => {
                        return {
                          test_name: item.test_name,
                          test_for: {
                            label: item.test_for,
                            value: item.test_for,
                          },
                          pre_info: item.pre_info,
                          category: getCategoryData(item),
                        };
                      }),
                    };
                setSelectedEditData(sortedEditData);
                // console.log("sortedEditData", sortedEditData);
              }
            } catch (error) {
              if (error.editResponse) {
                if (error.editResponse.status === 401) {
                  toast.error("Unable to get package edit data");
                  return;
                }
                if (error.editResponse.status === 500) {
                  toast.error("Internal server error");
                  return;
                }
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        if (error.packageData) {
          if (error.packageData.status === 401) {
            toast.error("Unable to get Packge Data");
            return;
          }
          if (error.packageData.status === 500) {
            toast.error("Internal server error");
            return;
          }
        }
      }
    };

    getPackageData();
  }, []);

  console.log("options", [categoryOptions, testForOptions, citiesOptions]);

  // common add package values
  const commonAddValues = {
    package_name: "",
    package_desc: "",
    package_type: null,
    package_price: "",
    pack_offer_price: "",
    pack_offer_text: "",
    pack_recommend: "",
    age: [3, 100],
    labs: [],
    cities: [],
    all_cities: false,
    all_labs: false,
    // test_inclusion: [
    //   {
    //     test_name: "",
    //     test_for: "",
    //   },
    // ],
    // test_id: [],
    status: null,
    pack_linked: selectedPackage,
  };

  const initialValues = editable
    ? selectedEditData && selectedEditData
    : //   : {
    selectedPackage
    ? {
        ...commonAddValues,
        // tests: [],
        all_tests: false,
      }
    : {
        ...commonAddValues,
        tests: [
          {
            test_name: "",
            test_for: null,
            category: [],
            pre_info: "",
          },
        ],
      };

  const validation = selectedPackage
    ? yup.object().shape({
        //   test_id: yup.array().length(1,"select atleast 1")"",
        package_name: yup.string().required("Name cannot be empty"),
        package_desc: yup.string().required("Description cannot be empty"),
        package_type: yup.object().required("Please select type").nullable(),
        package_price: yup
          .number()
          .required("Price cannot be empty")
          .min(1, "Price should not be 0"),
        pack_offer_price: yup
          .number()
          .required("Offer price cannot be empty")
          .min(1, "Price should not be 0"),
        pack_offer_text: yup.string().required("Offer text cannot be empty"),
        pack_recommend: yup.string().required("Recommend cannot be empty"),
        age: yup.array(),
        status: yup.object().required("Please select status").nullable(),
        // category: yup.array().min(1, "Please select category"),
        cities: yup.array().min(1, "Please select cities"),
      })
    : yup.object().shape({
        //   test_id: yup.array().length(1,"select atleast 1")"",
        package_name: yup.string().required("Name cannot be empty"),
        package_desc: yup.string().required("Description cannot be empty"),
        package_type: yup.object().required("Please select type").nullable(),
        package_price: yup
          .number()
          .required("Price cannot be empty")
          .min(1, "Price should not be 0"),
        pack_offer_price: yup
          .number()
          .required("Offer price cannot be empty")
          .min(1, "Price should not be 0"),
        pack_offer_text: yup.string().required("Offer text cannot be empty"),
        pack_recommend: yup.string().required("Recommend cannot be empty"),
        age: yup.array(),
        status: yup.object().required("Please select status").nullable(),

        cities: yup.array().min(1, "Please select cities"),
        tests: selectedLabs.length
          ? yup.array().of(
              yup.object().shape({
                test_name: yup.string().required("Test name required"),
                test_for: yup
                  .object()
                  .required("Please select test for")
                  .nullable(),
                category: yup.array().min(1, "Please select category"),
                pre_info: yup.string().required("Pre info cannot be empty"),
              })
            )
          : yup.array(),
      });

  const getLabs = async (city) => {
    console.log("formik values", city);

    const data = _.some(city, ["label", "All Cities"])
      ? {
          all_cities: true,
          cities: [],
        }
      : {
          all_cities: false,
          cities: city.map((item) => item.label),
        };
    // console.log("send data", data);
    // return;
    if (city.length) {
      try {
        const getLabData = await axios.post(
          Global.base_url + "/package/get/labs",
          data,
          config
        );
        // console.log(response);

        if (getLabData.status === 200) {
          console.log("response data for labs", getLabData);
          let newSelectedlabs = [];

          getLabData.data.labs.forEach(function (lab) {
            if (selectedLabs.includes(lab.lab_id)) {
              newSelectedlabs.push(lab.lab_id);
            }
            //   console.log("sdfghjkl;", [lab.lab_id == item, lab.lab_id, item]);
          });

          console.log("newSelectedlabs", newSelectedlabs);
          setSelectedLabs(newSelectedlabs);
          setLabs(getLabData.data.labs);
          setAllLabs(getLabData.data.labs);
          //   console.log("getTestFor", getTestFor);
        }
        // return setLoading(false);
      } catch (error) {
        if (error.getLabData) {
          if (error.getLabData.status === 401) {
            toast.error("Unable to get package labs");
            return;
          }
          if (error.getLabData.status === 500) {
            toast.error("Internal server error");
            return;
          }
        }
      }
    }
  };

  console.log("selectedLabs", selectedLabs);

  // console.log("checkedLabs", checkedLabs);
  const getTests = async (
    all_labs,
    labValue,
    labCheck,
    type,
    formik,
    allSet
  ) => {
    // console.log("test send valeus", [all_labs, labValue, labCheck, type]);
    var checkedLabs = [...selectedLabs];
    if (allSet) {
      if (all_labs) {
        // if (!labCheck) {
        checkedLabs = labs && labs.map((item) => item.lab_id);
        formik.setFieldValue("all_labs", true);
        // }
      } else {
        checkedLabs = [];
        formik.setFieldValue("all_labs", false);
      }
    } else {
      if (labCheck) {
        checkedLabs.push(Number(labValue));
      } else {
        checkedLabs = checkedLabs.filter((v) => Number(v) !== Number(labValue));

        formik.setFieldValue("all_labs", false);
      }
    }

    const data = all_labs
      ? {
          all_labs: true,
          labs: checkedLabs,
          package_type: type && type.label,
        }
      : {
          all_labs: false,
          labs: checkedLabs,
          package_type: type && type.label,
        };
    setSelectedLabs(checkedLabs);
    console.log("get test send data values", data);

    if (checkedLabs.length) {
      try {
        const getTestData = await axios.post(
          Global.base_url + "/package/get/labs/test",
          data,
          config
        );
        // console.log(response);

        if (getTestData.status === 200) {
          console.log("response data for labs", getTestData);
          setTests(getTestData.data.tests);
          setAllTests(getTestData.data.tests);
          // let newSelectedlabs = [];

          // getTestData.data.labs.forEach(function (lab) {
          //   if (selectedLabs.includes(lab.lab_id)) {
          //     newSelectedlabs.push(lab.lab_id);
          //   }
          //   //   console.log("sdfghjkl;", [lab.lab_id == item, lab.lab_id, item]);
          // });

          // console.log("newSelectedlabs", newSelectedlabs);
          // setSelectedLabs(newSelectedlabs);
          // setLabs(getTestData.data.labs);
          // setAllLabs(getTestData.data.labs);
          //   console.log("getTestFor", getTestFor);
        }
        // return setLoading(false);
      } catch (error) {
        if (error.getTestData) {
          if (error.getTestData.status === 401) {
            toast.error("Unable to get package tests");
            return;
          }
          if (error.getTestData.status === 500) {
            toast.error("Internal server error");
            return;
          }
        }
      }
    }
  };

  const calculateAmount = (all_check, formik, testCheck, testValue, allSet) => {
    //array with initial selectedtestItem values
    let checkedTest = [...selectedTestItems];

    if (allSet) {
      // checking all check value (boolean)
      if (all_check) {
        checkedTest =
          tests &&
          tests.map((item) => {
            return {
              offer_price: item.offer_price,
              test_id: item.test_id,
            };
          });
      } else {
        // if unchecked then setting it to empty array
        checkedTest = [];
      }
    } else {
      // individual check
      if (testCheck) {
        checkedTest.push({
          offer_price: testValue.offer_price,
          test_id: testValue.test_id,
        });
      } else {
        checkedTest = checkedTest.filter(
          (list) => list.test_id !== testValue.test_id
        );
      }
    }

    console.log("checkTest", checkedTest);

    setSelectedTestItems(checkedTest);

    let calculateTestAmont = checkedTest.reduce(
      (n, total) => n + total.offer_price,
      0
    );
    formik.setFieldValue("package_price", calculateTestAmont);

    console.log("calculateTestAmount", calculateTestAmont);
  };

  //search bar for labs table
  const searchItems = (event) => {
    let filteredLabs = allLabs.filter((list) => {
      return event !== null
        ? list.lab_name
            .toString()
            .toLowerCase()
            .includes(event.toLowerCase()) ||
            list.lab_id
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            list.city.toString().toLowerCase().includes(event.toLowerCase())
        : allLabs;
    });
    setLabs(filteredLabs);
  };

  //search bar for test table
  const searchTestItems = (event) => {
    let filteredTests = allTests.filter((list) => {
      return event !== null
        ? list.test_name
            .toString()
            .toLowerCase()
            .includes(event.toLowerCase()) ||
            list.test_id
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            list.offer_price
              .toString()
              .toLowerCase()
              .includes(event.toLowerCase())
        : allTests;
    });
    setTests(filteredTests);
  };

  // console.log("editable", editable);
  return (
    <div>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <>
          <div
            className="col-12 bg-white align-items-center pt-3 pb-3"
            style={{
              borderBottom: "1px solid #B3CDFF",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                history.push("/admin/packages");
              }}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <FiArrowLeft />
              </IconContext.Provider>
            </IconButton>
            <p
              style={{
                cursor: "pointer",
                fontSize: "25px",
                marginLeft: "5px",
              }}
              className="semiFont"
            >
              {editable ? `Package ID : ${data.package_id}` : "Back"}
            </p>
          </div>
          <div
            className="bg-white pt-3 px-2 px-xl-5 px-lg-3"
            style={{ paddingBottom: "30px" }}
          >
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={async (values, formik) => {
                setSubmitLoading(true);

                //   return;

                const url = editable
                  ? Global.base_url + "/package/update"
                  : Global.base_url + "/package/add";

                var data = {
                  
                  package_name: values.package_name,
                  package_desc: values.package_desc,
                  package_type: values.package_type.value.toUpperCase(),
                  pack_recommend: values.pack_recommend,
                  package_price: Number(values.package_price),
                  pack_offer_price: Number(values.pack_offer_price),
                  pack_offer_text: values.pack_offer_text,
                  age_min: values.age[0],
                  age_max: values.age[1],
                  all_cities: _.some(values.cities, ["label", "All Cities"])
                    ? 1
                    : 0,
                  cities: _.some(values.cities, ["label", "All Cities"])
                    ? null
                    : values.cities.map((item) => item.value).toString(),
                  all_labs: values.all_labs ? 1 : 0,
                  status: values.status.value,
                  labs: selectedLabs,

                  pack_linked: selectedPackage,
                };

                let packageSendData = selectedPackage
                  ? {
                      ...data,
                      tests: selectedTests,

                      all_tests: values.all_tests ? 1 : 0,
                    }
                  : {
                      ...data,
                      all_tests: null,
                      tests: values.tests.map((item) => {
                        return {
                          test_name: item.test_name,
                          test_for: item.test_for.label,
                          category: item.category.map((data) => data.value),
                          pre_info: item.pre_info,
                        };
                      }),
                    };

                if (editable) {
                  packageSendData = {
                    ...packageSendData,
                    package_id: location.state.data.package_id,
                  };
                }

                if (!selectedLabs.length) {
                  toast.error("Please select labs");
                  setSubmitLoading(false);
                } else if (!selectedTests.length && selectedPackage) {
                  toast.error("Please select tests");
                  setSubmitLoading(false);
                } else {
                  console.log("PackageSendData", packageSendData);

                  // return;

                  try {
                    const response = await axios.post(
                      url,
                      packageSendData,
                      config2
                    );
                    // console.log(response);
                    if (response.status === 200) {
                      setSubmitLoading(false);

                      editable
                        ? toast.success("Package Updated Successfully")
                        : toast.success("Package Added Successfully");

                      !editable && history.push("/admin/packages");
                    }
                  } catch (error) {
                    setSubmitLoading(false);
                    if (error.response && error.response.status === 401) {
                      // await closeModal();
                      editable
                        ? toast.error("Package Updation failed")
                        : toast.error("Package is not added");
                      return console.log(error);
                    }
                    if (error.response && error.response.status === 500) {
                      // await closeModal();
                      toast.error("Internal server error");
                      return console.log(error);
                    }
                  }
                }

                // console.log("submit data", data);
                // return;
              }}
            >
              {(formik) => {
                console.log("formik values", formik.errors);

                return (
                  <>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="hospital_name"
                              className="semiFont login__label"
                            >
                              Package Name {required}
                            </label>
                            <input
                              type="text"
                              className="form-control addproduct--input"
                              id="package_name"
                              value={formik.values.package_name}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "package_name",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9 ]/g,
                                    ""
                                  )
                                )
                              }
                              style={{
                                border: formik.errors.package_name
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              placeholder="Package name"
                            />
                            {formik.errors.package_name && (
                              <div className="err">
                                <p>{formik.errors.package_name}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-12 col-xl-6 col-lg-6"
                          // style={{
                          //   display: editProduct.index !== "" ? "block" : "none",
                          // }}
                        >
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="product_typeoftest"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Package Type {required}
                            </label>
                            <Select
                              value={formik.values.package_type}
                              onChange={(p) => {
                                // getTestData(p);
                                // formik.setFieldValue("test_id", []);
                                formik.setFieldValue("package_type", p);
                                // console.log("type ", p.value);
                              }}
                              id="inputDropdown"
                              isClearable={true}
                              placeholder="Select Product type"
                              options={testOptions}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "7px",
                                  borderRadius: "8px",
                                  border: formik.errors.package_type
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.package_type && (
                              <div className="err">
                                <p>{formik.errors.package_type}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="package_desc"
                              className="semiFont login__label"
                            >
                              Package Description{required}
                            </label>
                            <textarea
                              id="package_desc"
                              rows="4"
                              style={{
                                border: formik.errors.package_desc
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              value={formik.values.package_desc}
                              placeholder="Description"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "package_desc",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+.,/' ]/g,
                                    ""
                                  )
                                );
                              }}
                            ></textarea>
                            {formik.errors.package_desc && (
                              <div className="err">
                                <p>{formik.errors.package_desc}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="pack_recommend"
                              className="semiFont login__label"
                            >
                              Package Recommended {required}
                            </label>
                            <textarea
                              id="pack_recommend"
                              rows="4"
                              style={{
                                border: formik.errors.pack_recommend
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              value={formik.values.pack_recommend}
                              placeholder="Recommended For"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "pack_recommend",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+.,/' ]/g,
                                    ""
                                  )
                                );
                              }}
                            />
                            {formik.errors.pack_recommend && (
                              <div className="err">
                                <p>{formik.errors.pack_recommend}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="product-id"
                              className="semiFont login__label"
                            >
                              Age {required}
                            </label>
                            <div className="slider__container px-1 py-3 mt-5">
                              <Slider
                                value={
                                  formik.values && formik.values.age
                                    ? formik.values.age
                                    : [3, 100]
                                }
                                valueLabelDisplay="on"
                                onChange={(event, newValue) => {
                                  formik.setFieldValue("age", newValue);
                                  console.log("newValue", newValue);
                                }}
                                aria-labelledby="range-slider"
                                // getAriaValueText={valuetext}
                              />
                              <p className="login__label mediumFont">
                                <span>
                                  Min :{" "}
                                  {formik.values.age && formik.values.age[0]}
                                </span>
                                <span className="ml-3">
                                  Max :{" "}
                                  {formik.values.age && formik.values.age[1]}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="package_price"
                              className="semiFont login__label"
                            >
                              Price {required}
                            </label>
                            <input
                              type="number"
                              name="package_price"
                              style={{
                                border: formik.errors.package_price
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              placeholder="Price"
                              value={formik.values.package_price}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "package_price",
                                  e.currentTarget.value.replace(/[^0-9]/g, "")
                                );
                              }}
                            />
                            {formik.errors.package_price && (
                              <div className="err">
                                <p>{formik.errors.package_price}</p>
                              </div>
                            )}
                          </div>
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="pack_offer_price"
                              className="semiFont login__label"
                            >
                              Offer Price {required}
                            </label>
                            <input
                              type="number"
                              name="pack_offer_price"
                              style={{
                                border: formik.errors.pack_offer_price
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              value={formik.values.pack_offer_price}
                              placeholder="Offer Price"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "pack_offer_price",
                                  e.currentTarget.value.replace(/[^0-9]/g, "")
                                );
                              }}
                            />
                            {formik.errors.pack_offer_price && (
                              <div className="err">
                                <p>{formik.errors.pack_offer_price}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="pack_offer_text"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Offer Text {required}
                            </label>
                            <input
                              type="text"
                              name="pack_offer_text"
                              placeholder="Offer text"
                              style={{
                                border: formik.errors.pack_offer_text
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                              }}
                              value={formik.values.pack_offer_text}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "pack_offer_text",
                                  e.currentTarget.value.replace(
                                    /[^a-zA-Z0-9-_+./,' ]/g,
                                    ""
                                  )
                                );
                              }}
                            />
                            {formik.errors.pack_offer_text && (
                              <div className="err">
                                <p>{formik.errors.pack_offer_text}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6 ">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                            id="a123"
                          >
                            <label
                              htmlFor="status"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Status {required}
                            </label>
                            <Select
                              value={formik.values.status}
                              onChange={(p) => {
                                formik.setFieldValue("status", p);
                                // console.log("dowp", p.value);
                              }}
                              id="inputDropdown"
                              options={statusOptions}
                              className="admim__Addproduct--dropdown "
                              placeholder="Select Status"
                              isClearable={true}
                              // id="product-status"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.status
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                            />

                            {formik.errors.status && (
                              <div className="err">
                                <p>{formik.errors.status}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6  ">
                          {/* {!formik.values.all_cities ? ( */}
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="cities"
                              className="semiFont login__label"
                            >
                              Select City {required}
                            </label>
                            <Select
                              value={formik.values.cities}
                              onChange={(p) => {
                                formik.setFieldValue("cities", p);

                                p && getLabs(p);
                                formik.setFieldValue("all_labs", false);
                                // setSelectedLabs(
                                //     );

                                // getLabs(p);
                              }}
                              id="inputDropdown"
                              isMulti
                              placeholder="Select cities"
                              options={
                                formik.values.cities &&
                                formik.values.cities.length
                                  ? _.some(formik.values.cities, [
                                      "label",
                                      "All Cities",
                                    ])
                                    ? []
                                    : citiesOptions
                                  : [allCities, ...citiesOptions]
                              }
                              //   hideSelectedOptions={true}
                              closeMenuOnSelect={false}
                              menuPlacement={"auto"}
                              // menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.cities
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.cities && (
                              <div className="err">
                                <p>{formik.errors.cities}</p>
                              </div>
                            )}
                          </div>
                          {/* ) : null} */}
                        </div>
                      </div>
                      {formik.values.cities && formik.values.cities.length ? (
                        <div className="row p-3">
                          <div className="col-12 backDrop__container py-3">
                            <section className=" d-flex flex-column flex-sm-row justify-content-end justify-content-sm-between align-items-start align-items-sm-center ">
                              <div className="mb-2 mb-sm-0">
                                <p className="login__label semiFont">
                                  Select Labs {required}
                                </p>
                              </div>
                              <div
                                className="filter__container"
                                style={{ position: "relative" }}
                              >
                                <input
                                  type="text"
                                  className="filter__input"
                                  placeholder="Search labs"
                                  onChange={(e) => {
                                    searchItems(e.target.value);
                                  }}
                                />
                                <IconContext.Provider
                                  value={{
                                    size: "25px",
                                    color: "#333",
                                  }}
                                >
                                  <IoSearchOutline
                                    className="filter-search-icon"
                                    color="#818181"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContext.Provider>
                              </div>
                            </section>

                            <section
                              className="mt-2 admin-product-filter-section table-responsive fixHeader 
"
                            >
                              <table
                                className="table  text-center  admin-product_usertable mb-0"
                                style={{
                                  backgroundColor: "white",
                                }}
                                id="bill-table"
                              >
                                <thead>
                                  <tr
                                    id="admin-product-row"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#E0E0E0",
                                    }}
                                  >
                                    <th
                                      scope="col"
                                      className="admin-product-row_table-header"
                                    >
                                      <div
                                        style={{ position: "relative" }}
                                        className="d-flex align-items-center justify-content-center"
                                      >
                                        <input
                                          // className="form-check-input"
                                          type="checkbox"
                                          name="product_id"
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            // marginRight: "10px",
                                          }}
                                          disabled={!labs.length}
                                          // value={Number(data.lab_id)}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;

                                            console.log("chceking", [
                                              checked,
                                              value,
                                            ]);

                                            getTests(
                                              checked,
                                              "",
                                              false,
                                              formik.values.package_type,
                                              formik,
                                              true
                                            );
                                          }}
                                          checked={formik.values.all_labs}
                                        />
                                        {/* <label
                                      htmlFor="Select-all"
                                      className="form-check-label"
                                    >
                                      {" "}
                                      All
                                    </label> */}
                                      </div>
                                    </th>
                                    <th
                                      scope="col"
                                      className="admin-product-row_table-header"
                                    >
                                      Lab Id
                                    </th>
                                    <th
                                      scope="col"
                                      className="admin-product-row-table-header admin-product-row-price-col"
                                    >
                                      Lab Name
                                    </th>

                                    <th
                                      scope="col"
                                      className="admin-product-row-table-header   admin-product-row-status-col"
                                    >
                                      City
                                    </th>
                                    {/* <th
                                    scope="col"
                                    className="admin-product-row-table-header  admin-product-row-status-col"
                                  >
                                    Location
                                  </th> */}
                                  </tr>
                                </thead>
                                <tbody className="fixedBody">
                                  {labs.length > 0 ? (
                                    labs.map((lab, i) => {
                                      const borderBottom = `1px solid #B3CDFF`;
                                      return (
                                        <>
                                          <tr
                                            key={lab.lab_id + Math.random()}
                                            style={{
                                              //   borderLeft,
                                              borderBottom,
                                              backgroundColor: "white",
                                            }}
                                            className="admin-product-row-table-row-hover modal__table"
                                          >
                                            {" "}
                                            <td
                                              className="admin-product-row-table-row-data mediumFont"
                                              style={rowdataAlign}
                                            >
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <input
                                                  // className="form-check-input"
                                                  type="checkbox"
                                                  name="product_id"
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                  }}
                                                  value={Number(lab.lab_id)}
                                                  onChange={(e) => {
                                                    const { checked, value } =
                                                      e.target;

                                                    console.log("chceking", [
                                                      checked,
                                                      value,
                                                    ]);

                                                    getTests(
                                                      formik.values.all_labs,
                                                      value,
                                                      checked,
                                                      formik.values
                                                        .package_type,
                                                      formik,
                                                      false
                                                    );
                                                  }}
                                                  checked={
                                                    selectedLabs &&
                                                    selectedLabs.includes(
                                                      Number(lab.lab_id)
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td
                                              scope="row"
                                              id="admin-product-rd"
                                              className="admin-product-row-table-row-data mediumFont"
                                              style={rowdataAlign}
                                            >
                                              <p
                                                data-toggle="collapse"
                                                data-target={`#accordion` + i}
                                                className="clickable"
                                                id="button-open"
                                                // style={{ display: "none" }}
                                              >
                                                <IconContext.Provider
                                                  value={{
                                                    color: "#4059E2",
                                                    size: "25px",
                                                    style: {
                                                      marginBottom: "3px",
                                                    },
                                                  }}
                                                >
                                                  <IoMdAddCircle className="toggle-row-icon" />
                                                </IconContext.Provider>
                                              </p>{" "}
                                              LID{lab.lab_id}
                                            </td>
                                            <td
                                              className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                                              style={rowdataAlign}
                                            >
                                              {lab.lab_name}
                                            </td>
                                            <td
                                              className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                              style={rowdataAlign}
                                            >
                                              {lab.city}
                                            </td>
                                          </tr>
                                          <tr
                                            id={"accordion" + i}
                                            className="collapse  admin-product-hided-row"
                                            key={lab.lab_id + Math.random()}
                                          >
                                            <td
                                              colSpan="6"
                                              style={rowdataAlign}
                                            >
                                              <ul
                                                className="admin-product-row-table-hide-list text-left mediumFont"
                                                style={{ color: "#525252" }}
                                              >
                                                <li
                                                  key={
                                                    i +
                                                    lab.lab_id +
                                                    i +
                                                    Math.random()
                                                  }
                                                  style={{
                                                    // borderBottom: "1px solid #B3CDFF",
                                                    padding: "10px",
                                                  }}
                                                  className="admin-product-row-hided-col-price"
                                                >
                                                  <div className="row justify-content-start ml-3">
                                                    <div className="col-6  text-center">
                                                      Lab Name:
                                                    </div>
                                                    <div className="col-6  text-left">
                                                      {lab.lab_name}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li
                                                  key={
                                                    i +
                                                    lab.lab_id +
                                                    i +
                                                    Math.random()
                                                  }
                                                  style={{
                                                    // borderBottom: "1px solid #B3CDFF",
                                                    padding: "10px",
                                                  }}
                                                  // className="admin-product-row-hided-col-price"
                                                >
                                                  <div className="row justify-content-start ml-3">
                                                    <div className="col-6  text-center">
                                                      City:
                                                    </div>
                                                    <div className="col-6  text-left">
                                                      {lab.city}
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div
                                      className="center__items p-4"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <img src={emptyLabs} alt="empty-labs" />
                                      <p
                                        style={{
                                          color: "#D2D2D2",
                                          padding: "20px",
                                          fontSize: "20px",
                                          fontFamily: "mediumFont",
                                        }}
                                      >
                                        Labs not found
                                      </p>
                                    </div>
                                  )}
                                </tbody>
                              </table>
                            </section>
                          </div>
                        </div>
                      ) : null}

                      {selectedLabs.length && selectedPackage ? (
                        <div className="row p-3">
                          <div className="col-12 backDrop__container py-3">
                            <section className=" d-flex flex-column flex-sm-row justify-content-end justify-content-sm-between align-items-start align-items-sm-center ">
                              <div className="mb-2 mb-sm-0">
                                <p className="login__label semiFont">
                                  Select Tests {required}
                                </p>
                              </div>
                              <div
                                className="filter__container"
                                style={{ position: "relative" }}
                              >
                                <input
                                  type="text"
                                  className="filter__input"
                                  placeholder="Search tests"
                                  onChange={(e) => {
                                    searchTestItems(e.target.value);
                                  }}
                                />
                                <IconContext.Provider
                                  value={{
                                    size: "25px",
                                    color: "#333",
                                  }}
                                >
                                  <IoSearchOutline
                                    className="filter-search-icon"
                                    color="#818181"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContext.Provider>
                              </div>
                            </section>

                            <section
                              className="mt-2 admin-product-filter-section table-responsive fixHeader 
"
                            >
                              <table
                                className="table  text-center  admin-product_usertable mb-0"
                                style={{
                                  backgroundColor: "white",
                                }}
                                id="bill-table"
                              >
                                <thead>
                                  <tr
                                    id="admin-product-row"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#E0E0E0",
                                    }}
                                  >
                                    <th
                                      scope="col"
                                      className="admin-product-row_table-header"
                                    >
                                      <div
                                        style={{ position: "relative" }}
                                        className="d-flex align-items-center justify-content-center"
                                      >
                                        <input
                                          // className="form-check-input"
                                          type="checkbox"
                                          name="product_id"
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            // marginRight: "10px",
                                          }}
                                          disabled={!tests.length}
                                          // value={Number(data.lab_id)}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;

                                            console.log("chceking", [
                                              checked,
                                              value,
                                            ]);

                                            if (checked) {
                                              setSelectedTests(
                                                tests &&
                                                  tests.map(
                                                    (item) => item.test_id
                                                  )
                                              );
                                              formik.setFieldValue(
                                                "all_tests",
                                                true
                                              );
                                            } else {
                                              setSelectedTests([]);
                                              formik.setFieldValue(
                                                "all_tests",
                                                false
                                              );
                                            }
                                            calculateAmount(
                                              checked,
                                              formik,
                                              false,
                                              "",
                                              true //condition for exact amount calculation
                                            );
                                          }}
                                          checked={formik.values.all_tests}
                                        />
                                      </div>
                                    </th>
                                    <th
                                      scope="col"
                                      className="admin-product-row_table-header"
                                    >
                                      Test ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="admin-product-row-table-header admin-product-row-price-col"
                                    >
                                      Test Name
                                    </th>

                                    <th
                                      scope="col"
                                      className="admin-product-row-table-header   admin-product-row-status-col"
                                    >
                                      Price
                                    </th>
                                    {/* <th
                                    scope="col"
                                    className="admin-product-row-table-header  admin-product-row-status-col"
                                  >
                                    Location
                                  </th> */}
                                  </tr>
                                </thead>
                                <tbody className="fixedBody">
                                  {tests.length > 0 ? (
                                    tests.map((test, i) => {
                                      const borderBottom = `1px solid #B3CDFF`;
                                      return (
                                        <>
                                          <tr
                                            key={test.test_id + Math.random()}
                                            style={{
                                              //   borderLeft,
                                              borderBottom,
                                              backgroundColor: "white",
                                            }}
                                            className="admin-product-row-table-row-hover modal__table"
                                          >
                                            {" "}
                                            <td
                                              className="admin-product-row-table-row-data mediumFont"
                                              style={rowdataAlign}
                                            >
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <input
                                                  // className="form-check-input"
                                                  type="checkbox"
                                                  name="product_id"
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                  }}
                                                  value={Number(test.test_id)}
                                                  onChange={(e) => {
                                                    const { checked, value } =
                                                      e.target;

                                                    console.log("chceking", [
                                                      checked,
                                                      value,
                                                    ]);

                                                    if (checked) {
                                                      setSelectedTests([
                                                        ...selectedTests,
                                                        Number(value),
                                                      ]);
                                                    } else {
                                                      setSelectedTests(
                                                        selectedTests &&
                                                          selectedTests.filter(
                                                            (list) =>
                                                              Number(
                                                                list !==
                                                                  Number(value)
                                                              )
                                                          )
                                                      );
                                                      formik.setFieldValue(
                                                        "all_tests",
                                                        false
                                                      );
                                                    }

                                                    calculateAmount(
                                                      formik.values.all_tests,
                                                      formik,
                                                      checked,
                                                      test,
                                                      false //condition for exact amount calculation
                                                    );
                                                  }}
                                                  checked={
                                                    selectedTests &&
                                                    selectedTests.includes(
                                                      Number(test.test_id)
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td
                                              scope="row"
                                              id="admin-product-rd"
                                              className="admin-product-row-table-row-data mediumFont"
                                              style={rowdataAlign}
                                            >
                                              <p
                                                data-toggle="collapse"
                                                data-target={`#accordion` + i}
                                                className="clickable"
                                                id="button-open"
                                                // style={{ display: "none" }}
                                              >
                                                <IconContext.Provider
                                                  value={{
                                                    color: "#4059E2",
                                                    size: "25px",
                                                    style: {
                                                      marginBottom: "3px",
                                                    },
                                                  }}
                                                >
                                                  <IoMdAddCircle className="toggle-row-icon" />
                                                </IconContext.Provider>
                                              </p>{" "}
                                              TID{test.test_id}
                                            </td>
                                            <td
                                              className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                                              style={rowdataAlign}
                                            >
                                              {test.test_name}
                                            </td>
                                            <td
                                              className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                                              style={rowdataAlign}
                                            >
                                              &#8377;{test.offer_price}
                                            </td>
                                          </tr>
                                          <tr
                                            id={"accordion" + i}
                                            className="collapse  admin-product-hided-row"
                                            key={test.test_id + Math.random()}
                                          >
                                            <td
                                              colSpan="6"
                                              style={rowdataAlign}
                                            >
                                              <ul
                                                className="admin-product-row-table-hide-list text-left mediumFont"
                                                style={{ color: "#525252" }}
                                              >
                                                <li
                                                  key={
                                                    i +
                                                    test.test_id +
                                                    i +
                                                    Math.random()
                                                  }
                                                  style={{
                                                    // borderBottom: "1px solid #B3CDFF",
                                                    padding: "10px",
                                                  }}
                                                  className="admin-product-row-hided-col-price"
                                                >
                                                  <div className="row justify-content-start ml-3">
                                                    <div className="col-6  text-center">
                                                      Test Name:
                                                    </div>
                                                    <div className="col-6  text-left">
                                                      {test.test_name}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li
                                                  key={
                                                    i +
                                                    test.test_id +
                                                    i +
                                                    Math.random()
                                                  }
                                                  style={{
                                                    // borderBottom: "1px solid #B3CDFF",
                                                    padding: "10px",
                                                  }}
                                                  // className="admin-product-row-hided-col-price"
                                                >
                                                  <div className="row justify-content-start ml-3">
                                                    <div className="col-6  text-center">
                                                      Price:
                                                    </div>
                                                    <div className="col-6  text-left">
                                                      &#8377;{test.offer_price}
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div
                                      className="center__items p-4"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <img src={emptyTest} alt="empty-test" />
                                      <p
                                        style={{
                                          color: "#D2D2D2",
                                          padding: "20px",
                                          fontSize: "20px",
                                          fontFamily: "mediumFont",
                                        }}
                                      >
                                        Tests not found
                                      </p>
                                    </div>
                                  )}
                                </tbody>
                              </table>
                            </section>
                          </div>
                        </div>
                      ) : null}

                      {selectedLabs.length && !selectedPackage ? (
                        <div className="row">
                          <div className="col-12 py-3">
                            <FieldArray name="tests">
                              {(fieldarray) => {
                                const { push, remove, form } = fieldarray;
                                return (
                                  <div
                                    className="inputBox"
                                    style={{
                                      position: "relative",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div className="data__row d-flex align-items-center justify-content-between">
                                      <p
                                        htmlFor="test_inclusion"
                                        className="semiFont login__label"
                                      >
                                        Add test {required}
                                      </p>
                                      <Button
                                        variant="contained"
                                        className="primary__btn  my-2 my-sm-0"
                                        onClick={() =>
                                          push({
                                            test_name: "",
                                            test_for: null,
                                            category: [],
                                            pre_info: "",
                                          })
                                        }
                                        // disabled={!formik.dirty || uploader}
                                        style={{
                                          padding: "5px 25px",
                                          // width: "150px",
                                          color: "#fff",
                                        }}
                                      >
                                        <p>Add</p>
                                      </Button>
                                      {/* <button
                                      onClick={() =>
                                        push({ test_name: "", test_for: "" })
                                      }
                                    >
                                      Add
                                    </button> */}
                                    </div>

                                    <div
                                      className="testInclusion__container backDrop__container mt-3 p-3"
                                      style={{ border: "none" }}
                                    >
                                      {formik.values.tests &&
                                        formik.values.tests.map((item, i) => {
                                          return (
                                            <>
                                              {i > 0 && (
                                                <div className="d-flex justify-content-end">
                                                  <div className="align-self-center d-flex justify-content-center mt-3">
                                                    <Tooltip
                                                      title={"Remove"}
                                                      placement="top-center"
                                                    >
                                                      <IconButton
                                                        style={{
                                                          backgroundColor:
                                                            "#cc000017",
                                                        }}
                                                        onClick={() =>
                                                          remove(i)
                                                        }
                                                      >
                                                        <IconContext.Provider
                                                          value={{
                                                            color: "#cc0000",
                                                            size: "20px",
                                                          }}
                                                        >
                                                          <CgClose />
                                                        </IconContext.Provider>
                                                      </IconButton>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              )}
                                              <div
                                                className="row tests__inputCard"
                                                key={i}
                                              >
                                                <div className="col">
                                                  <div
                                                    className="inputBox"
                                                    style={{
                                                      position: "relative",
                                                      marginTop: 0,
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="test_name"
                                                      className="semiFont login__label"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      Test Name{required}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      name="test_name"
                                                      placeholder="Test Name"
                                                      style={{
                                                        border: Object.keys(
                                                          formik.errors
                                                        ).length
                                                          ? formik.errors.tests[
                                                              i
                                                            ] &&
                                                            formik.errors.tests[
                                                              i
                                                            ].test_name
                                                            ? "1px solid red"
                                                            : "1px solid #c3cad9"
                                                          : "1px solid #c3cad9",
                                                        marginTop: "5px",
                                                        width: "auto",
                                                      }}
                                                      value={
                                                        formik.values.tests[i]
                                                          .test_name
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldValue(
                                                          `tests[${i}].test_name`,
                                                          e.currentTarget.value.replace(
                                                            /[^a-zA-Z0-9-_+./,' ]/g,
                                                            ""
                                                          )
                                                        );
                                                      }}
                                                    />
                                                    {Object.keys(formik.errors)
                                                      .length
                                                      ? formik.errors.tests[
                                                          i
                                                        ] && (
                                                          <div className="err">
                                                            <p>
                                                              {
                                                                formik.errors
                                                                  .tests[i]
                                                                  .test_name
                                                              }
                                                            </p>
                                                          </div>
                                                        )
                                                      : null}
                                                  </div>
                                                </div>
                                                <div className="col">
                                                  <div
                                                    className="inputBox"
                                                    style={{
                                                      position: "relative",
                                                      marginTop: 0,
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="test_for"
                                                      className="semiFont login__label"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      Test For {required}
                                                    </label>
                                                    <Select
                                                      value={
                                                        formik.values.tests[i]
                                                          .test_for
                                                      }
                                                      onChange={(p) => {
                                                        formik.setFieldValue(
                                                          `tests[${i}].test_for`,
                                                          p
                                                        );
                                                        // console.log("type ", p.value);
                                                      }}
                                                      id="inputDropdown"
                                                      isClearable={true}
                                                      placeholder="Select Test for"
                                                      options={testForOptions}
                                                      styles={{
                                                        control: (
                                                          base,
                                                          state
                                                        ) => ({
                                                          ...base,
                                                          marginTop: "5px",
                                                          padding: "10px 10px",
                                                          border: Object.keys(
                                                            formik.errors
                                                          ).length
                                                            ? formik.errors
                                                                .tests[i] &&
                                                              formik.errors
                                                                .tests[i]
                                                                .test_for
                                                              ? "1px solid red"
                                                              : "1px solid #c3cad9"
                                                            : "1px solid #c3cad9",
                                                          "&:active": {
                                                            outline: "none",
                                                            boxShadow: "none",
                                                          },
                                                          "&:hover": {
                                                            outline: "none",
                                                            boxShadow: "none",
                                                          },
                                                        }),
                                                        option: (
                                                          base,
                                                          state
                                                        ) => ({
                                                          ...base,
                                                          backgroundColor:
                                                            state.isFocused
                                                              ? "#eee"
                                                              : "#fff",
                                                          padding: "8px",
                                                          display: "flex",
                                                          fontFamily:
                                                            "semiFont",
                                                          alignItems: "center",
                                                          fontSize: "16px",
                                                          zIndex: 9999,
                                                          color: state.isFocused
                                                            ? "#183b56"
                                                            : "#183b56",
                                                          "&:hover": {
                                                            backgroundColor:
                                                              state.isFocused
                                                                ? "#eee"
                                                                : "#fff",
                                                          },
                                                        }),
                                                      }}
                                                      className="admin__Addproduct--dropdown "
                                                    />
                                                    {Object.keys(formik.errors)
                                                      .length
                                                      ? formik.errors.tests[
                                                          i
                                                        ] && (
                                                          <div className="err">
                                                            <p>
                                                              {
                                                                formik.errors
                                                                  .tests[i]
                                                                  .test_for
                                                              }
                                                            </p>
                                                          </div>
                                                        )
                                                      : null}
                                                  </div>
                                                </div>

                                                <div className="col">
                                                  <div
                                                    className="inputBox"
                                                    style={{
                                                      position: "relative",
                                                      marginTop: 0,
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="test_for"
                                                      className="semiFont login__label"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      category {required}
                                                    </label>
                                                    <Select
                                                      value={
                                                        formik.values.tests[i]
                                                          .category
                                                      }
                                                      onChange={(p) => {
                                                        formik.setFieldValue(
                                                          `tests[${i}].category`,
                                                          p
                                                        );
                                                        // console.log("type ", p.value);
                                                      }}
                                                      isMulti
                                                      id="inputDropdown"
                                                      isClearable={true}
                                                      placeholder="Select Category"
                                                      options={categoryOptions}
                                                      styles={{
                                                        control: (
                                                          base,
                                                          state
                                                        ) => ({
                                                          ...base,
                                                          marginTop: "5px",
                                                          padding: "10px 10px",
                                                          border: Object.keys(
                                                            formik.errors
                                                          ).length
                                                            ? formik.errors
                                                                .tests[i] &&
                                                              formik.errors
                                                                .tests[i]
                                                                .category
                                                              ? "1px solid red"
                                                              : "1px solid #c3cad9"
                                                            : "1px solid #c3cad9",
                                                          "&:active": {
                                                            outline: "none",
                                                            boxShadow: "none",
                                                          },
                                                          "&:hover": {
                                                            outline: "none",
                                                            boxShadow: "none",
                                                          },
                                                        }),
                                                        option: (
                                                          base,
                                                          state
                                                        ) => ({
                                                          ...base,
                                                          backgroundColor:
                                                            state.isFocused
                                                              ? "#eee"
                                                              : "#fff",
                                                          padding: "8px",
                                                          display: "flex",
                                                          fontFamily:
                                                            "semiFont",
                                                          alignItems: "center",
                                                          fontSize: "16px",
                                                          zIndex: 9999,
                                                          color: state.isFocused
                                                            ? "#183b56"
                                                            : "#183b56",
                                                          "&:hover": {
                                                            backgroundColor:
                                                              state.isFocused
                                                                ? "#eee"
                                                                : "#fff",
                                                          },
                                                        }),
                                                      }}
                                                      className="admin__Addproduct--dropdown "
                                                    />
                                                    {Object.keys(formik.errors)
                                                      .length
                                                      ? formik.errors.tests[
                                                          i
                                                        ] && (
                                                          <div className="err">
                                                            <p>
                                                              {
                                                                formik.errors
                                                                  .tests[i]
                                                                  .category
                                                              }
                                                            </p>
                                                          </div>
                                                        )
                                                      : null}
                                                  </div>
                                                </div>
                                                <div className="col">
                                                  <div
                                                    className="inputBox"
                                                    style={{
                                                      position: "relative",
                                                      marginTop: 0,
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="pre_info"
                                                      className="semiFont login__label"
                                                    >
                                                      Pre test info {required}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      name="test_name"
                                                      placeholder="Pre test info"
                                                      style={{
                                                        border: Object.keys(
                                                          formik.errors
                                                        ).length
                                                          ? formik.errors.tests[
                                                              i
                                                            ] &&
                                                            formik.errors.tests[
                                                              i
                                                            ].pre_info
                                                            ? "1px solid red"
                                                            : "1px solid #c3cad9"
                                                          : "1px solid #c3cad9",
                                                        marginTop: "5px",
                                                        width: "auto",
                                                      }}
                                                      value={
                                                        formik.values.tests[i]
                                                          .pre_info
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldValue(
                                                          `tests[${i}].pre_info`,
                                                          e.currentTarget.value.replace(
                                                            /[^a-zA-Z0-9-_+./,' ]/g,
                                                            ""
                                                          )
                                                        );
                                                      }}
                                                    />
                                                    {Object.keys(formik.errors)
                                                      .length
                                                      ? formik.errors.tests[
                                                          i
                                                        ] && (
                                                          <div className="err">
                                                            <p>
                                                              {
                                                                formik.errors
                                                                  .tests[i]
                                                                  .pre_info
                                                              }
                                                            </p>
                                                          </div>
                                                        )
                                                      : null}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* <hr /> */}
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      ) : null}

                      <div className="button__container mt-4 d-flex align-items-center justify-content-end">
                        <Button
                          variant="contained"
                          className="primary__btn"
                          id="admin-reportview-download-btn"
                          style={{
                            padding: "10px 25px",
                            width: "150px",
                            color: "#fff",
                          }}
                          disabled={submitLoading}
                          onClick={formik.handleSubmit}
                        >
                          {submitLoading ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

export default PackageView;
