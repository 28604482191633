import React, { useContext, useState } from "react";
import "../assets/css/login.css";
import LoginSideContent from "./LoginSideContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { IconContext } from "react-icons";
import LoginLogo from "../assets/images/healthmo.png";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import Global from "../global";
import axios from "axios";
// import { GlobalContext } from "../context/Context";

const LoginPage = () => {
  const history = useHistory();
  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secureText, setSecureText] = useState(true);
  const [blankEmail, setBlankEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [blankPassword, setBlankPassword] = useState(false);
  const [validLength, setValidLength] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  // const { test, token, updateEmail } = useContext(GlobalContext);

  // console.log("email", test);

  const handleRemember = () => {
    setRemember(!remember);
  };

  // email regex
  const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  // login handler
  const handleLogin = async (e) => {
    e.preventDefault();

    if (email.length === 0) {
      setBlankEmail(true);
      setInvalid(false);
      // setValidUser(true);
    } else {
      setBlankEmail(false);
    }

    if (emailReg.test(email) === false) {
      setValidEmail(true);
      setInvalid(false);
    } else {
      setValidEmail(false);
    }

    if (password.length === 0) {
      setBlankPassword(true);
      setInvalid(false);
      // setValidLength(false);
      // setValidLength(false);
    } else {
      setBlankPassword(false);
    }

    if (password.length >= 8 && password.length <= 15) {
      setValidLength(false);
      // setBlankPassword(false);
    } else {
      setValidLength(true);
      setInvalid(false);
      // setBlankPassword(false);
    }

    if (
      emailReg.test(email) === true &&
      email.length > 0 &&
      password.length >= 8
    ) {
      // props.history.replace("UserDashboard");
      setBlankEmail(false);
      setBlankPassword(false);
      setValidEmail(false);
      setValidLength(false);
      setInvalid(false);
      setLoading(true);
      try {
        const response = await axios.post(
          Global.base_url + "/login",
          {
            email: email,
            password: password,
          },
          Global.headers
        );
        // console.log(response);
        if (response.status === 200) {
          console.log("login reponse", response);
          // props.login();

          // updateEmail({
          //   test: "hellow world 2",
          //   token: response.data.token,
          // });

          localStorage.setItem("token", response.data.token);
          // setting the active sidebar item when redirect to home
          localStorage.setItem("active-sidebar", 0);
          localStorage.setItem("admin_name", response.data.admin_name);
          history.push("/admin");
          console.log("login response", response);
        }
      } catch (error) {
        // console.log("error", error);
        if (error.response && error.response.status === 401) {
          return setLoading(false), setInvalid(true);
        }

        setLoading(false);
      }
    }
  };
  return (
    <section className="login__container">
      <div className="login__section">
        <div className="login__elements">
          <img src={LoginLogo} alt="Ampath logo" className="login__logo" />
          <h2
            className="hkBoldFont login__header"
            style={{ marginTop: "10px" }}
          >
            Welcome back.
          </h2>
          <form onSubmit={handleLogin}>
            <div className="inputBox ">
              <label htmlFor="email" className="semiFont login__label">
                Email address
              </label>
              <input
                type="text"
                name="email"
                placeholder="Enter Email"
                value={email}
                onChange={(name) => setEmail(name.target.value)}
                style={{
                  borderColor: blankEmail || validEmail ? "#cc0000" : "#c3cad9",
                }}
              />
              <p
                className="err"
                style={{
                  display: validEmail ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Email must be valid
              </p>
              <p
                className="err"
                style={{
                  display: blankEmail ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Email can't be blank
              </p>
            </div>
            <div className="inputBox ">
              <label htmlFor="password" className="semiFont login__label">
                Password
              </label>
              <input
                name="password"
                placeholder="Enter Password"
                type={secureText ? "password" : "text"}
                value={password}
                onChange={(name) => setPassword(name.target.value)}
                style={{
                  borderColor:
                    blankPassword || validLength ? "#cc0000" : "#c3cad9",
                }}
              />

              <p
                className="err"
                style={{
                  display: blankPassword ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Password can't be blank
              </p>
              <p
                className="err"
                style={{
                  display: validLength ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Password must have atleast 8 char
              </p>

              <div
                className="password__icon cursor"
                onClick={() =>
                  // Shows password when clicking eye icon
                  secureText ? setSecureText(false) : setSecureText(true)
                }
              >
                <IconContext.Provider
                  value={{
                    color: "#959EAD",
                    size: "20px",
                  }}
                >
                  {secureText ? <VscEye /> : <VscEyeClosed />}
                </IconContext.Provider>
              </div>
            </div>

            <div
              style={{
                // color: "#03A33B",
                marginTop: "5px",
                fontFamily: "semiFont",
                display: invalid ? "block" : "none",
              }}
            >
              <p style={{ color: "#cc0000", fontFamily: "mediumFont" }}>
                Invalid email and password
              </p>
              {/* {props.location.state.isComplete !== undefined
              ? "complted"
              : "faliled"} */}
            </div>

            {/* <div className="login__options" style={{ marginTop: "15px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={remember}
                    onChange={handleRemember}
                    name="Keep me sign in"
                  />
                }
                label="Keep me sign in"
              />
              
            </div> */}

            <Button
              variant="contained"
              color="secondary"
              className="login__btn"
              type="submit"
              disabled={loading}
              // style={{ justifyContent: "center !important" }}
            >
              {loading && !validEmail && !blankPassword ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <p>Sign in</p>
              )}
              {/* Sign in */}
            </Button>
          </form>
        </div>
      </div>
      <LoginSideContent />
    </section>
  );
};

export default LoginPage;
