import { useState, useEffect } from "react";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import Global from "../global";
import axios from "axios";
import Loader from "react-loader-spinner";
import Category from "./Category";
import TestType from "./TestType";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& .MuiTabs-indicator": {
      backgroundColor: "#1565d8",
      height: 3,
      top: "0px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#1565d8",
      backgroundColor: "#fff",
    },
  },
}));

const Classifications = () => {
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const [classAccess, setClassAccess] = useState({});
  const [category, setCategory] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [testType, setTestType] = useState([]);
  const [allTestType, setAllTestType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState("");
  const [categorypage, setCategoryPage] = useState(0);
  const [typepage, setTypePage] = useState(0);
  // const
  const [categoryrowsPerPage, setCategoryRowsPerPage] = useState(5);
  const [typerowsPerPage, setTypeRowsPerPage] = useState(5);

  const rowdataAlign = { verticalAlign: "middle" };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const getCategory = async () => {
      try {
        const checkClass = await axios.post(
          Global.base_url + "/classify/check/privileges",
          null,
          config
        );
        // console.log(response);

        if (checkClass.status === 200) {
          console.log("checkClass", checkClass);
          setClassAccess(checkClass.data);
          // console.log("view access");

          if (
            _.some(checkClass.data.privileges, [
              "privilege_name",
              "VIEW CATEGORY AND TYPES",
            ])
          ) {
            try {
              const getCategory = await axios.post(
                Global.base_url + "/classify/category",
                null,
                config
              );
              // console.log(getCategory);

              if (getCategory.status === 200) {
                console.log("test getCategory: ", getCategory);
                let datatest = getCategory.data.data;
                let startIndex =
                  (categorypage + 1) * categoryrowsPerPage -
                  categoryrowsPerPage;

                const endIndex = startIndex + categoryrowsPerPage;
                const data = datatest.slice(startIndex, endIndex);

                setAllCategory(getCategory.data.data);
                setCategory(data);
              }
            } catch (error) {
              if (error.getCategory) {
                if (error.getCategory.status === 401) {
                  return toast.error("Unable to get category");
                }
                if (error.getCategory.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
            try {
              const getType = await axios.post(
                Global.base_url + "/classify/types",
                null,
                config
              );
              // console.log(getCategory);

              if (getType.status === 200) {
                console.log("test getType: ", getType);
                let testData = getType.data.data;
                let startIndex =
                  (typepage + 1) * typerowsPerPage - typerowsPerPage;

                const endIndex = startIndex + typerowsPerPage;
                const data = testData.slice(startIndex, endIndex);

                setAllTestType(getType.data.data);

                setTestType(data);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error("Unable to get test type");
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }
          return setLoading(false);
        }
        // }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get classification access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
      // provilege check api
    };

    getCategory();
  }, [refresh]);

  return (
    <div>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <section className="tabs__wrapper mt-2">
          <div
            className={classes.root}
            classes={{ indicator: classes.indicator }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
            >
              <Tab label="Category" disableRipple={true} {...a11yProps(0)} />
              <Tab label="Test Type" disableRipple={true} {...a11yProps(1)} />
            </Tabs>
          </div>
          <div className="tabItems__container">
            <TabPanel value={selectedTab} index={0}>
              <Category
                getList={category}
                getAllList={allCategory}
                setAllList={setAllCategory}
                setList={setCategory}
                getPage={categorypage}
                setPage={setCategoryPage}
                getRow={categoryrowsPerPage}
                setRow={setCategoryRowsPerPage}
                refresh={setRefresh}
                classAccess={classAccess}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <TestType
                getList={testType}
                getAllList={allTestType}
                setAllList={setAllTestType}
                setList={setTestType}
                getPage={typepage}
                setPage={setTypePage}
                getRow={typerowsPerPage}
                setRow={setTypeRowsPerPage}
                refresh={setRefresh}
                classAccess={classAccess}
              />
            </TabPanel>
          </div>
        </section>
      )}
    </div>
  );
};

export default Classifications;
